<div>

  <div class="content-body">

    <div class="row">
      <div class="col-lg-5" style="padding-top: 2%;padding-left: 5%">
        <button  (click)="back('dashboard/user')" class="btn btn-primary">
          Back
        </button>
      </div>
      <div class="col-lg-7" style="text-align: left;padding-top: 2%;">
        <div class="card-title " style="padding-bottom: 25px;">
          Update User
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <!-- Add Order -->

      <!-- row -->
      <div class="row">
        <div class="col-xl-12 col-lg-12">
          <div class="card">
            <div class="card-body">
              <div class="basic-form">
                <form
                  (ngSubmit)="onSubmit()"
                  #addTherapistForm="ngForm"
                  novalidate
                >
                  <div class="form-row">
                    <div class="form-group col-md-4"  *ngIf = "hide">
                      <label>User Name</label>
                      <input
                        class="form-control"
                        type="text"
                        name="username"
                        id="username"
                        required
                        #username="ngModel"
                        [(ngModel)]="userDetails.UserName"
                      />
                      <div
                        *ngIf="
                          username.invalid &&
                          (username.dirty || username.touched)
                        "
                        class="alert"
                      >
                        <div *ngIf="username.errors?.['required']">
                          Username is required.
                        </div>
                      </div>
                    </div>

                    <div class="form-group col-md-4">
                      <label>First Name</label>
                      <input
                        class="form-control"
                        type="text"
                        name="firstname"
                        id="firstname"
                        required
                        #firstname="ngModel"
                        [(ngModel)]="userDetails.FirstName"
                      />
                      <div
                        *ngIf="
                          firstname.invalid &&
                          (firstname.dirty || firstname.touched)
                        "
                        class="alert"
                      >
                        <div *ngIf="firstname.errors?.['required']">
                          firstname is required.
                        </div>
                      </div>
                    </div>

                    <div class="form-group col-md-4">
                      <label>Last Name</label>
                      <input
                        class="form-control"
                        type="text"
                        name="lastname"
                        id="lastname"
                        required
                        #lastname="ngModel"
                        [(ngModel)]="userDetails.LastName"
                      />
                      <div
                        *ngIf="
                          lastname.invalid &&
                          (lastname.dirty || lastname.touched)
                        "
                        class="alert"
                      >
                        <div *ngIf="lastname.errors?.['required']">
                          Last name is required.
                        </div>
                      </div>
                    </div>

                    <div class="form-group col-md-4">
                      <label>Email</label>
                      <input
                        class="form-control"
                        type="email"
                        name="email"
                        id="email"
                        required
                        [pattern]="emailPattern"
                        #email="ngModel"
                        [(ngModel)]="userDetails.Email"
                      />
                      <div
                        *ngIf="email.invalid && (email.dirty || email.touched)"
                        class="alert"
                      >
                        <div *ngIf="email.errors?.['required']">
                          Email is required.
                        </div>

                        <div *ngIf="email.errors?.['pattern']">
                          Invalid Email.
                        </div>
                      </div>
                    </div>

                    <div class="form-group col-md-4">
                      <label>Phone</label>
                      <input
                        class="form-control"
                        type="text"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                        name="phone"
                        id="phone"
                        required
                        pattern="phonePattern"
                        #phone="ngModel"
                        [(ngModel)]="userDetails.Phone"
                      />

                      <div
                        *ngIf="phone.invalid && (phone.dirty || phone.touched)"
                        class="alert"
                      >
                        <div *ngIf="phone.errors?.['required']">
                          phone is required.
                        </div>
                        <div *ngIf="phone.errors?.['pattern']">
                          Invalid phone number.
                        </div>
                      </div>
                      
                    </div>
                    <div *ngIf="userDetails.Region == 'United States of America'">
                      <div class="form-group col-md-4">
                      
                        <div   >
                          <label>State</label>
                          <input
                            class="form-control"
                            type="text"
                            name="State"
                            id="State"
                            required
                            #lastname="ngModel"
                            [(ngModel)]="userDetails.State"
                          />
                          
                        </div>

                    </div>
                    </div>
                    

                    
                    
                    <div class="form-group col-md-4">
                      <label>Region</label>
                      <select [(ngModel)]="userDetails.Region" name="dropdownOption" id="dropdownOption" class="form-control"  >
                        <option *ngFor="let country of countries" [value]="country.code" > {{ country.name }} </option>
                      </select>              
                    </div>

                                              
                  </div>

                  <div style="text-align: center;">
                    <button type="submit" class="btn btn-primary">
                      Submit <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ngx-spinner color="#fff" type="ball-scale-multiple"></ngx-spinner>
</div>
