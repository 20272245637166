import { DatePipe } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { data } from 'jquery';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { HttpService } from 'src/app/shared/service/http.service';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import axios from 'axios';
import { CalendlyAccesTokenService } from 'src/app/shared/service/calendly-acces-token.service';


@Component({
  selector: 'app-therapist-admin-session-view',
  templateUrl: './therapist-admin-session-view.component.html',
  styleUrls: ['./therapist-admin-session-view.component.css'],
  providers: [DatePipe]
})
export class TherapistAdminSessionViewComponent implements OnInit {

  today: any = new Date();
  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: DataTables.Settings = {};
  @ViewChild(DataTableDirective, { static: true }) dtElement: DataTableDirective;
  dtInstance: Promise<DataTables.Api>;
  datatableCount: number = 0;
  sessions: any = [] ;
  sessionType: any = 'Sessions';
  upcoming:any = [];
  completed:any = [];
  canceled:any = [];
  free:any = [];
  therapistId: any;
  thrapistTotalRevenue: any ;
  role: any = sessionStorage.getItem('role');
  disbale: boolean = false
  cancelData: {} ;

  constructor(
    private http: HttpService,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private datePipe: DatePipe,
    private calendlyAccessToken: CalendlyAccesTokenService
  ) {
    this.today = this.datePipe.transform(new Date(), 'MM/dd/yyyy h:mm:ss a');
   }

   ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
    };

    this.datatableCount++;

    this.getAllSessions();
    this.getTherapistRevenue();

    //this.getAllMessages();
  }

  getTherapistRevenue() {
    let currentUser: any = sessionStorage.getItem('currentUser');
    let token: any = JSON.parse(currentUser).token;

    if(this.role == 'Admin') {
      this.therapistId = sessionStorage.getItem('therapistId');
    } else {
      this.therapistId = JSON.parse(currentUser).userId;
    }

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    });
    
    let options = { headers: headers };
    
    this.http
      .get('therapist/getTherapistSessionRevenue/' + this.therapistId, options)
      .subscribe((res: any) => {
        //console.log(res);
        this.thrapistTotalRevenue = res.currency +' '+ res.total;
      });
  }

  getAllSessions(): void {
    this.spinner.show();
    let currentUser: any = sessionStorage.getItem('currentUser');
    let token: any = JSON.parse(currentUser).token;
    let therapistName = sessionStorage.getItem('fullName')
    // console.log(token);

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    });
    let options = { headers: headers };
    this.http.get(`admin/getSessionsByTherapist?Therapist=${therapistName}`, options).subscribe((res: any) => {
      this.sessions = res;
      console.log("sessions->", this.sessions)
      // console.log(this.sessions , "ajatus");
      
      this.dtTrigger.next('')
      this.spinner.hide();

      this.upcoming = [];
        this.completed = [];
        this.canceled = [];
        this.free = [];

        for (let i = 0;i< this.sessions.length;i++) {
  
        let dateDifference = (new Date(this.sessions[i].start_time).getTime() - new Date(this.today).getTime()) / 1000;
        let freeSessions = (new Date(this.sessions[i].end_time).getTime() - new Date(this.sessions[i].start_time).getTime()) / 1000 / 60;

        
          if(dateDifference < 0 && freeSessions == 15 && this.sessions[i].Status == "Active") {
            this.free.push(this.sessions[i]);
          }
          else if(this.sessions[i].Status == "Active" && dateDifference > 0) {
            this.upcoming.push(this.sessions[i])
          }else if (this.sessions[i].Status == "Active" && dateDifference < 0){
            this.completed.push(this.sessions[i])
          } else {
            this.canceled.push(this.sessions[i]);
          }
        }

        //console.log(this.canceled);

    });

    let user_Id: any = JSON.parse(currentUser).userId;
  }

  upComingDataLoad() {

    this.sessions = [];
    if (this.datatableCount > 0) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.clear();
        dtInstance.destroy();
      });
      this.sessions = this.upcoming;
    }
    this.dtTrigger.next('');
    //console.log(this.upcoming);
    this.sessionType = 'UpComing Sessions'
    this.disbale = true;

  }

  completedDataLoad() {
    this.sessions = [];
    if (this.datatableCount > 0) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.clear();
        dtInstance.destroy();
      });
      this.sessions = this.completed;
    }
    this.dtTrigger.next('');
    this.sessionType = 'Completed Sessions';
    this.disbale = true;;
  }

  canceledDataLoad() {
    this.sessions = [];
    if (this.datatableCount > 0) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.clear();
        dtInstance.destroy();
      });
      this.sessions = this.canceled;
    }
    this.dtTrigger.next('');
    this.sessionType = 'Canceled Sessions';
    this.disbale = true;
  }

  freeDataLoad() {
    this.sessions = [];
    if (this.datatableCount > 0) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.clear();
        dtInstance.destroy();
      });
      this.sessions = this.free;
    }
    this.dtTrigger.next('');
    this.sessionType = 'Free Sessions'
  }

  back() {
    this.router.navigate(['dashboard/therapist']);
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }


  sessionCancel(uuId: any,userType: any,id: any,startTime: any){
        this.today = moment(new Date())
        
        let sTime =moment(startTime)
        let duration = moment.duration(sTime.diff(this.today));
        let hours =Math.round( duration.asHours());
          console.log(hours)
        let sessionBookedHour = hours;
    
        this.cancelData = {
          "uuid": uuId,
          "userType" : userType,
          "_id" : id,
          "userMail" : ''
        }
    
        console.log(this.cancelData);
    
        Swal.fire({ 
          
          title: 'Are you sure?',
          text: sessionBookedHour <72 ? "Cancellation fee of $ XX amount will be charged":"Are you sure you want to cancel",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.spinner.show()
            this.http.post('session/cancelSession', this.cancelData).subscribe(
              (res: any) => {
                this.spinner.hide();
               window.location.reload();
              },
              (error: any) => {
                this.spinner.hide();
                console.log(error.error?.message);
                Swal.fire('Error!', error.error?.message, 'error');
              }
            );
          }
        })
      }
    
}
