<div>
  <div class="content-body">
    <div class="row" style="padding-top: 2%; padding-left: 1%;">
      <div class="col-lg-5">
        <button (click)="back()" class="btn btn-primary">
          Back
        </button>
      </div>
      <div class="col-lg-7" style="text-align: left;">
        <div class="card-title " style="padding-bottom: 3%;">
          Therapist Details
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <!-- Add Order -->

      <!-- row -->
      <div class="row">
        <div class="col-xl-4 col-lg-6 col-sm-6">
          <div class="card overflow-hidden">
            <div class="card-body">

              <div class="text-center">
                <div class="profile-photo">
                  <div *ngIf="therapistImage; else elseBlock">
                    <img
                      [src]="baseUrl + therapistImage"
                      style="border-radius: 50%;width: 70px;height: 70px;"
                      class="img-fluid rounded-circle"
                      alt="user"
                    />
                  </div>
                  <ng-template #elseBlock>
                    <img
                      src="../../assets/images/avatar/avatar.png"
                      width="100"
                      class="img-fluid rounded-circle"
                      alt="user"
                    />
                  </ng-template>
                </div>
                <p class="mt-4 mb-1" style="font-size: 1.8vw;">{{ userName }}</p>
                <a class="text-muted">{{ role }}</a>
                <div>
                  <a class="text-muted">Therapist Origin : {{ region }}</a>
                </div>
                
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-8">
          <div class="card">
            <div class="card-body">
              <div class="basic-list-group">
                <ul class="list-group list-group-flush">
                  <li class="list-group-item">
                    <strong>Email :</strong> {{ Email }}
                  </li>
                  <li class="list-group-item">
                    <strong>Phone :</strong> {{ Phone }}
                  </li>
                  <li *ngIf="specificToCountry" class="list-group-item" style="display: inline;">
                    <strong>Available Country :</strong> <a *ngFor ="let item of avaliabilityRegion" style="color: black;">{{ item }} </a> 
                  </li>
                  <li *ngIf="!specificToCountry"  class="list-group-item" style="display: inline;">
                    <strong>Available State : </strong> <a *ngFor ="let item of avaliabilityRegion" style="color: black;">{{ item }} </a> 
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ngx-spinner color="#fff" type="ball-scale-multiple"></ngx-spinner> 
</div>
