<div>
  <div class="nav-header">
    <a routerLink="/dashboard/home" class="brand-logo">
      <img class="logo-abbr" src="../../assets/images/logo.png" alt="" />
    </a>

    <div class="nav-control">
      <div class="hamburger">
        <span class="line"></span><span class="line"></span
        ><span class="line"></span>
      </div>
    </div>
  </div>

  <div class="content-body">
    <div class="container-fluid">
      <!-- Add Order -->

      <!-- row -->

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body" style="overflow-x: scroll;">

              <!-- <div class="col-12" style="text-align: right;">
                <button type="button"  (click)="addUser('dashboard/add-user')" class="btn btn-primary" > Add User </button>
              </div> -->
              <!-- [dtOptions]="dtOptions" 
              [dtTrigger]="dtTrigger"  -->
              <div class="search-div">
                <div class="form-group">
                  <span>Search</span>
                  <div class="d-flex">
                    <input
                      type="text"
                      class="form-control"
                      #searchQuery
                    />
                    <button type="button" class="btn btn-primary btn-sm mx-2"><span class="fa fa-search"></span></button>
                  </div>
                  
                </div>
              </div>
              <table datatable 
                class="row-border dataTable hover" 
                style="width: 100%;">
                <thead>
                  <tr>
                    <th>Sl No.</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email</th>
                    <th>User Type</th>
                    <th>Country</th>
                    <th>Device</th>
                    <th>Status</th>
                    <th>Last data received</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of userDetails; let i = index">
                    <td>{{ i + 1 }}</td>
                    <td>{{ item.FirstName }}</td>
                    <td>{{ item.LastName }}</td>
                    <td>{{ item.Email }}</td>
                    <td>{{ (item.CorporateCode == "" || item.CorporateCode == " ")?"Individual":"Corporate"}}</td>
                    <td>{{ item.RegionCode }}</td>
                    <td class="d-flex justify-content-center align-items-center">
                      <div *ngIf="item.DeviceType; else noDevice">
                        <div *ngIf="item.DeviceType=='android'"><img class="device-type-logo" src="../../../assets/images/android.png"></div>
                        <div *ngIf="item.DeviceType=='ios'"><img class="device-type-logo" src="../../../assets/images/apple.png"></div>
                      </div>
                      <ng-template #noDevice>
                        nil
                      </ng-template>
                    </td>
                    <!-- <td style="text-align: center;">Free</td> -->
                    <td>
                      <a
                        type="button"
                        *ngIf="item.isActive == false"
                        (click)="activeInactive(item.isActive,item._id)"
                        class="sessionmodallink"
                        >Inactive</a
                      >

                      <a
                        type="button"
                        *ngIf="item.isActive == true"
                        (click)="activeInactive(item.isActive,item._id)"
                        class="sessionmodallink"
                        >Active</a
                      >

                    </td>
                    <td>{{ dateConversion(item.lastReceivedData) }}</td>
                    <td>
                      <a
                        (click)="viewUser('dashboard/admin-user-view',item._id)"
                        [routerLink]="['/dashboard/admin-user-view', item._id]"
                        class="btn btn-primary shadow btn-xs sharp mr-1"><i class="fa fa-eye"></i>
                      </a>
                      <a
                          (click)="viewUser('dashboard/update-user',item._id)"
                          [routerLink]="['/dashboard/update-user', item._id]"
                          class="btn btn-primary shadow btn-xs sharp mr-1"
                          ><i class="fa fa-pencil"></i>
                      </a>
                      <a
                        (click)="deleteUser(item._id)"
                        class="btn btn-primary shadow btn-xs sharp mr-1">
                        <i class="fa fa-trash"></i>
                      </a>

                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="pagination-div">
                <button class="prev" (click)="changePage(currentPage - 1)" [disabled]="currentPage === 1">Prev</button>
                <div *ngIf="currentPage <= visiblePages.length+2">
                  <span class="pageno" *ngFor="let page of visiblePages" (click)="changePage(page)" [class.active]="page === currentPage">{{ page }}</span>
                </div>
                <div *ngIf="currentPage > visiblePages.length+2 ">
                  <span class="pageno" *ngFor="let page of [1,2]" (click)="changePage(page)" [class.active]="page === currentPage">{{ page }}</span>
                </div>
                <span *ngIf="currentPage > visiblePages.length + 2">...</span>
                <span *ngIf="currentPage > visiblePages.length + 2 && currentPage < totalPages-2" class="pageno" [class.active]="currentPage">{{ currentPage }}</span>
                <span *ngIf="totalPages > visiblePages.length + 2 && currentPage <= totalPages-2">...</span>
                <span class="pageno" *ngFor="let page of [totalPages - 1, totalPages]"  (click)="changePage(page)" [class.active]="page === currentPage">{{ page }}</span>
                <button class="next" (click)="changePage(currentPage + 1)" [disabled]="currentPage === totalPages">Next</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ngx-spinner color="#fff" type="ball-scale-multiple"></ngx-spinner>
</div>
