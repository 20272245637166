import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { DataTablesModule } from 'angular-datatables';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgChartsModule } from 'ng2-charts';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { IgxDatePickerModule, IgxDateRangePickerModule, IgxInputGroupModule } from "igniteui-angular";
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { Daterangepicker } from 'ng2-daterangepicker';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { MasterComponent } from './master/master.component';
import { MasterRoutingModule } from './master/master-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { CalendlyErrorPageComponent } from './calendly-error-page/calendly-error-page.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { ForgotPasswordOtpComponent } from './forgot-password-otp/forgot-password-otp.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatChipsModule } from '@angular/material/chips';
import { CorporateLoginComponent } from './corporate-login/corporate-login.component';
import { CorporatePasswordChangeComponent } from './corporate-password-change/corporate-password-change.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MasterComponent,
    ForgotPasswordComponent,
    CalendlyErrorPageComponent,
    ForgotPasswordOtpComponent,
    CorporateLoginComponent,
    CorporatePasswordChangeComponent
  ],
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatChipsModule,
    CommonModule,
    Ng2GoogleChartsModule,
    NgChartsModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    NgxSpinnerModule,
    MasterRoutingModule,
    IgxDatePickerModule,
    Daterangepicker,
    AutocompleteLibModule,
    AngularEditorModule,
    DataTablesModule.forRoot(),
    BrowserAnimationsModule,
    SweetAlert2Module.forRoot(),
    ReactiveFormsModule,
    MatDatepickerModule,
    IgxDateRangePickerModule,
	  IgxInputGroupModule,
    MatNativeDateModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgSelectModule,
    DataTablesModule,
   
  ],
  providers: [],
  bootstrap: [AppComponent],
  exports: [NgxSpinnerModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
}
