import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AddTherapistComponent } from "./add-therapist/add-therapist.component";
import { AddUserComponent } from "./add-user/add-user.component";
import { AdminFileUploadComponent } from "./admin-file-upload/admin-file-upload.component";
import { AdminPlansViewComponent } from "./admin-plans-view/admin-plans-view.component";
import { AdminProfileComponent } from "./admin-profile/admin-profile.component";
import { AdminSubscriptionComponent } from "./admin-subscription/admin-subscription.component";
import { AdminTherapistViewComponent } from "./admin-therapist-view/admin-therapist-view.component";
import { AdminUserViewComponent } from "./admin-user-view/admin-user-view.component";
import { DashbaordComponent } from "./dashbaord/dashbaord.component";
import { MasterComponent } from "./master.component";
import { ShowRevenueComponent } from "./show-revenue/show-revenue.component";
import { StripePaymentStatusComponent } from "../stripe-payment-status/stripe-payment-status.component";
import { TherapistDashboardComponent } from "./therapist-dashboard/therapist-dashboard.component";
import { TherapistProfileComponent } from "./therapist-profile/therapist-profile.component";
import { TherapistUserComponent } from "./therapist-user/therapist-user.component";
import { TherapistComponent } from "./therapist/therapist.component";
import { TherpistAddNoteComponent } from "./therpist-add-note/therpist-add-note.component";
import { TherpistUserSessionDetailsComponent } from "./therpist-user-session-details/therpist-user-session-details.component";
import { UpdateAdminProfileComponent } from "./update-admin-profile/update-admin-profile.component";
import { UpdateTherapistProfileComponent } from "./update-therapist-profile/update-therapist-profile.component";
import { UpdateTherapistComponent } from "./update-therapist/update-therapist.component";
import { UpdateUserComponent } from "./update-user/update-user.component";
import { UserDeciblesComponent } from "./user-decibles/user-decibles.component";
import { UserComponent } from "./user/user.component";
import { AuthGuard } from "../shared/service/auth-guard.service";
import { FindAllmessageComponent } from "./find-allmessage/find-allmessage.component";
import { NewUserDetailComponent } from "./new-user-detail/new-user-detail.component";
import { TherapistRevenueComponent } from "./therapist-revenue/therapist-revenue.component";
import { TherapistAdminSessionViewComponent } from "./therapist-admin-session-view/therapist-admin-session-view.component";
import { SessionPlansComponent } from "./session-plans/session-plans.component";
import { AddSessionPlanComponent } from "./add-session-plan/add-session-plan.component";
import { CorporatesComponent } from "./corporates/corporates.component";
import { AdminCorporateViewComponent } from "./admin-corporate-view/admin-corporate-view.component";
import { AddCorporatesComponent } from "./add-corporates/add-corporates.component";
import { UpdateCorporateComponent } from "./update-corporate/update-corporate.component";
import { CorporateAdminDashboardComponent } from "./corporate-admin-dashboard/corporate-admin-dashboard.component";
import { CorporateUsersComponent } from "./corporate-users/corporate-users.component";



const masterRoutes: Routes = [

  {
    path: 'dashboard', component: MasterComponent,canActivate: [AuthGuard], children: [
      {
        path: '', component: DashbaordComponent,canActivate: [AuthGuard]
      },
      { path: 'home', component: DashbaordComponent,canActivate: [AuthGuard] },
      { path: 'therapist',  component: TherapistComponent,canActivate: [AuthGuard]},
      { path: 'user', component: UserComponent,canActivate: [AuthGuard]},
      { path: 'corporates', component: CorporatesComponent,canActivate: [AuthGuard]},
      { path: 'corporates/add', component: AddCorporatesComponent,canActivate: [AuthGuard]},
      { path: 'corporates/:id', component: AdminCorporateViewComponent,canActivate: [AuthGuard]},
      //{  path: 'therapist-dashboard', component: TherapistDashboardComponent,canActivate: [AuthGuard] },
      { path: 'add-therapist', component: AddTherapistComponent,canActivate: [AuthGuard] },
      // { path: 'add-user', component: AddUserComponent,canActivate: [AuthGuard]},
      { path: 'update-user/:id', component: UpdateUserComponent,canActivate: [AuthGuard] },
      { path: 'update-therapist/:id', component: UpdateTherapistComponent ,canActivate: [AuthGuard]},
      { path: 'update-corporate/:id', component: UpdateCorporateComponent ,canActivate: [AuthGuard]},
      { path: 'therapist-profile', component: TherapistProfileComponent,canActivate: [AuthGuard] },
      { path: 'admin-therapist-view/:id', component: AdminTherapistViewComponent,canActivate: [AuthGuard]},
      { path: 'admin-user-view/:id', component: AdminUserViewComponent,canActivate: [AuthGuard]},
      { path: 'admin-profile', component: AdminProfileComponent ,canActivate: [AuthGuard]},
      { path: 'update-therapist-profile', component: UpdateTherapistProfileComponent,canActivate: [AuthGuard]},
      { path: 'create-subscription', component: AdminSubscriptionComponent,canActivate: [AuthGuard] },
      { path: 'update-admin-profile', component: UpdateAdminProfileComponent,canActivate: [AuthGuard] },
      { path: 'view-plans',  component: AdminPlansViewComponent,canActivate: [AuthGuard] },
      { path: 'view-session-plans',  component: SessionPlansComponent,canActivate: [AuthGuard] },
      { path: 'add-session-plan',  component: AddSessionPlanComponent,canActivate: [AuthGuard] },
      // { path: 'upload-file', component: AdminFileUploadComponent,canActivate: [AuthGuard]},
      { path: 'show-revenue', component: ShowRevenueComponent,canActivate: [AuthGuard]},
      { path: 'user-decibles', component: UserDeciblesComponent,canActivate: [AuthGuard] },
      { path: 'therapist-user', component: TherapistUserComponent,canActivate: [AuthGuard] },
      { path: 'therapist-user-session-details', component: TherpistUserSessionDetailsComponent,canActivate: [AuthGuard] },
      { path: 'therapist-user-add-note', component: TherpistAddNoteComponent,canActivate: [AuthGuard] },
      { path: 'find-all-message', component: FindAllmessageComponent,canActivate: [AuthGuard] },
      { path: 'show-new-user', component: NewUserDetailComponent,canActivate: [AuthGuard] },
      { path: 'therapist-revenue', component: TherapistRevenueComponent,canActivate: [AuthGuard] },
      { path: 'therapist-session-view', component: TherapistAdminSessionViewComponent,canActivate: [AuthGuard] },
      { path: 'corporate-dashboard', component: CorporateAdminDashboardComponent, canActivate: [AuthGuard] },
      { path: 'corporate-users', component: CorporateUsersComponent, canActivate: [AuthGuard] }
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(masterRoutes),
  ],
  exports: [RouterModule],
})
export class MasterRoutingModule {}


