import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../environments/environment';
import axios from 'axios';
import { HttpHeaders } from '@angular/common/http';
import { HttpService } from '../shared/service/http.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-stripe-payment-status',
  templateUrl: './stripe-payment-status.component.html',
  styleUrls: ['./stripe-payment-status.component.css']
})
export class StripePaymentStatusComponent implements OnInit {

  assignedTo: any;
  startTime: any;
  eventDate: any;
  eventTime: any;
  inviteeName: any;
  inviteeMail: any
  success: any;
  checkoutSessionId: any;
  eventTypeName: any;
  endTime: any;
  inviteeUuid: any;
  eventTypeUuid: any;
  savedSessionId: any;
  paymentFor: any;
  sessionData: any = {}
  subscriptionData: any = {}
  subscriptionMessage: any
  isResponseSuccess: boolean = false;
  responseMessage: String = 'Please wait..'

  constructor(private route: ActivatedRoute, private http: HttpService, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.paymentFor = params['paymentFor'];
      this.success = params['success'];
      if(this.paymentFor == 'session') {
        this.sessionData.assignedTo= params['assignedTo'];
        this.sessionData.eventTypeUuid= params['eventTypeUuid'];
        this.sessionData.eventTypeName= params['eventTypeName'];
        this.sessionData.startTime= params['startTime'];
        this.sessionData.endTime= params['endTime'];
        this.sessionData.inviteeUuid= params['inviteeUuid'];
        this.sessionData.inviteeName= params['inviteeName'];
        this.sessionData.inviteeMail= params['inviteeMail'];
        this.sessionData.success= params['success'];
        this.sessionData.savedSessionId = params['savedSessionId'];
        this.sessionData.checkoutSessionId= params['session_id'];
      } else {
        this.subscriptionData.success = params['success'];
        this.subscriptionData.checkoutSessionId= params['session_id'];
        this.subscriptionData.userMail= params['userEmail'];
        this.subscriptionData.productId= params['productId'];
        this.subscriptionData.userId= params['userId'];
        this.subscriptionData.stripeCustomerId= params['stripeCustomerId'];
      }
    });
    
    if(this.success) {
      if(this.paymentFor == 'session') {
      // Replacing space with +
      var replacedStartTime = this.sessionData.startTime.replace(' ', '+');
      var replacedEndTime = this.sessionData.endTime.replace(' ', '+');

      // Getting date & time to show
      let dateTime = replacedStartTime.split("+")[0];
      this.eventDate = dateTime.split("T")[0]
      this.eventTime = dateTime.split("T")[1];

      //  Converting to ISO
      var isoStartTime = new Date(replacedStartTime).toISOString();
      this.sessionData.startTime = isoStartTime
      var isoEndTime = new Date(replacedEndTime).toISOString();
      this.sessionData.endTime = isoEndTime

      // Saving the sesssion data
      this.saveSession()
      } else {
        this.subscriptionMessage = "You have been successfully subscribed!";
        this.saveSubscription()
      }
    } else {
      //console.log(this.sessionData.success);
      alert("Payment Failed!")
    }
    //console.log(this.sessionData);
    
  }

  saveSession(): void {
    this.spinner.show();

    this.http
      .post('session/savePaidSession', this.sessionData)
      .subscribe((res: any) => {
        this.spinner.hide()
        this.isResponseSuccess = true
      }, (err) => {
        this.spinner.hide()
        this.isResponseSuccess = false
        this.responseMessage = 'Sorry! Unable to save session...';
        //console.log(err);
      });
  }

  saveSubscription(): void {
    this.spinner.show();

    this.http
      .post('payment/saveSubscription', this.subscriptionData)
      .subscribe((res: any) => {
        this.spinner.hide()
        this.isResponseSuccess = true
      }, (err) => {
        this.spinner.hide()
        this.isResponseSuccess = false
        this.responseMessage = 'Sorry! Unable to save subscription...';
        //console.log(err);
      });
  }

}
