<div>
    <div class="nav-header">
      <a routerLink="/dashboard/home" class="brand-logo">
        <img class="logo-abbr" src="../../assets/images/logo.png" alt="" />
      </a>
  
      <div class="nav-control">
        <div class="hamburger">
          <span class="line"></span><span class="line"></span
          ><span class="line"></span>
        </div>
      </div>
    </div>
  
    <div class="content-body">
      <div class="container-fluid">
        <!-- Add Order -->
  
        <!-- row -->
  
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
  
                <!-- <div class="col-12" style="text-align: right;">
                  <button type="button"  (click)="addUser('dashboard/add-user')" class="btn btn-primary" > Add User </button>
                </div> -->
  
                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border dataTable hover" style="width: 100%;">
                  <thead>
                    <tr>
                      <th>Sl No.</th>
                      <th>Email</th>
                      <th>Subject</th>
                      <th>Message</th>
                      <th>Sent On</th>
                      
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of messageDetails; let i = index">
                      <td>{{ i + 1 }}</td>
                      <td>
                        <a type="button" style="color: #000000;" href="mailto:{{item.Email}}?Subject={{ item.Subject }}" target="_blank">{{item.Email}}</a>
                      </td>
                      <td>{{ item.Subject }}</td>
                      <td>{{ item.Message }}</td>
                      <td>{{ item.createdAt | date: "dd/MM/yyyy hh:mm a"}}</td>
                      
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ngx-spinner color="#fff" type="ball-scale-multiple"></ngx-spinner>
</div>