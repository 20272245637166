import { HttpService } from '../../shared/service/http.service';
import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import Swal from 'sweetalert2';
import { environment } from '../../environments/environment';
import { Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-session-plans',
  templateUrl: './session-plans.component.html',
  styleUrls: ['./session-plans.component.css']
})
export class SessionPlansComponent implements OnInit {
  
  sessionPlanDetails: any[];
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  userDetails: any = {};
  userImage: any;
  baseUrl: any = environment.imageUrl;
  constructor(
    private http: HttpService, 
    private router: Router,
    private spinner: NgxSpinnerService
  ) { }

  
  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
    };
    this.spinner.show();
    let currentUser: any = sessionStorage.getItem('currentUser');
    let token: any = JSON.parse(currentUser).token;
    //console.log(token);

    let userId: any = JSON.parse(currentUser).userId;

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    });
    let options = { headers: headers };
    this.http.get('therapist/GetTherapistPlanInStripe', options).subscribe((res: any) => {
      console.log(res);
      this.sessionPlanDetails = res.sessionPrices.reverse();
      this.dtTrigger.next('')
      this.spinner.hide()
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  goToAddSessionPlan(){
    this.router.navigate(['dashboard/add-session-plan']);
    sessionStorage.setItem('path','dashboard/add-session-plan');
  }

}
