<div>
  

  <div class="content-body">

    <div class="row">
      <div class="col-lg-5" style="padding-top: 2%;padding-left: 5%">
        <button  (click)="back('dashboard/user')" class="btn btn-primary">
          Back
        </button>
      </div>
      <div class="col-lg-7" style="text-align: left;padding-top: 2%;">
        <div class="card-title " style="padding-bottom: 25px;">
          User Details 
          <!-- [<span *ngIf="deviceType == 'ios'">
            IOS
          </span>
          <span *ngIf="deviceType == 'android'">
            Android
          </span>
          <span *ngIf="deviceType == undefined">
            nil
          </span>] -->
        </div>
      </div>
    </div>

    
    <div class="container-fluid">
      <!-- Add Order -->
      <div class="card">
        <div class="card-header">
          <div class="card-title text text-center">
            <h4 class="p-0 m-0">{{ userName }}</h4>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-3  p-3">
                <div *ngIf="therapistImage; else elseBlock">
                  <div class="p-3 d-flex flex-column justify-content-center">
                    <img
                      [src]="baseUrl + therapistImage"
                      style="border-radius:50%;height: 40px;width: 40px;"
                      class="img-fluid rounded-circle"
                      alt="user"
                    />
                  </div>
                  
                </div>
                <ng-template #elseBlock>
                  <div class="p-3 d-flex justify-content-center">
                    <img
                      src="../../assets/images/avatar/avatar.png"
                      width="100"
                      class="img-fluid rounded-circle"
                      alt="user"
                    />
                  </div>
                  
                </ng-template>
                
              
            </div>
            <div class="col-md-9 p-3">
              <div class="row">
                <div class="col-md-6">
                  <div class="basic-list-group">
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item">
                        <strong>Email :</strong> {{ email }}
                      </li>
                      <li class="list-group-item">
                        <strong>Device Type :</strong> 
                        <span *ngIf="deviceType == 'ios'">
                          IOS
                        </span>
                        <span *ngIf="deviceType == 'android'">
                          Android
                        </span>
                        <span *ngIf="deviceType == undefined">
                          nil
                        </span>
                      </li>
                      <li class="list-group-item">
                        <strong>User's Location : </strong>
                        <a *ngIf="!specificToCountry" style="color: black;">{{stateName}} , </a>
                        {{ region }}
                      </li>
                      <li class="list-group-item">
                        <strong>User created on : </strong>
                        {{createdAt}}
                      </li>
                      <!-- <li class="list-group-item">
                        <strong>Address :</strong> 2129 Ridgecrest Rd SE,APT-10
                        Grand Rapids MI -49546,USA
                      </li> -->
                    </ul>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="basic-list-group">
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item">
                        <strong>Subscription Type :</strong><span> {{subscriptionType}}</span>
                      </li>
                      <li class="list-group-item d-flex">
                        <strong>Valid upto :</strong>&nbsp;<span> {{validUpto}}</span>
                      </li>
                      <li class="list-group-item">
                        <strong>User Type : </strong>
                        {{userType}}
                      </li>
                      <li class="list-group-item">
                        <strong>Last data received : </strong>
                        {{lastReceived}}
                      </li>
                      <!-- <li class="list-group-item">
                        <strong>Address :</strong> 2129 Ridgecrest Rd SE,APT-10
                        Grand Rapids MI -49546,USA
                      </li> -->
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="row p-3 d-flex justify-content-center border-top">
            <div class="col-md-3 text text-center"><span>Avg Volume</span><h6>56 db</h6></div>
            <div class="col-md-3 text text-center"><span>Avg Volume</span><h6>56 db</h6></div>
            <div class="col-md-3 text text-center"><span>Avg Volume</span><h6>56 db</h6></div>
            <div class="col-md-3 text text-center"><span>Avg Volume</span><h6>56 db</h6></div>
          </div> -->
        </div>
      </div>
    </div>
  </div>

<ngx-spinner color="#fff" type="ball-scale-multiple"></ngx-spinner>
</div>
