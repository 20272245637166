<div>
    <div class="content-body" >

        <div class="row">
          <div class="col-lg-12" style="text-align: center;padding-top: 2%;">
            <div class="card-title ">
              Revenue
            </div>
          </div>
        </div>

        <div *ngIf="role == 'Therapist'" style="text-align: right; font-size: large;margin-top: 2%;margin-right: 2%;margin-bottom: 2%;"><b>Total Revenue : {{ thrapistTotalRevenue }}</b> </div>
        
        <div class="container-fluid"  >
          <!-- Add Order -->
          <!-- row -->

          <div class="row">


            <div class="form-group col-md-4">
                <label class="nav-text" style="font-family: Titillium Web ,sans-serif;">Select Filters</label>
                <select 
                  [(ngModel)]="selectedFilters" name="dropdownOption" id="dropdownOption" class="form-control"  style="font-family: Titillium Web ,sans-serif;">
                  <option *ngFor="let filter of dateFlters" [value]="filter.value" style="font-family: Titillium Web ,sans-serif;" >
                    {{ filter.name }} 
                  </option>
                </select>
                <div class="row" style="height: 20%;" *ngIf="selectedFilters == 'custom Date' ">
                  
                  <igx-date-range-picker>
                    <igx-date-range-start>
                        <igx-picker-toggle igxPrefix>
                            <igx-icon>calendar_today</igx-icon>
                        </igx-picker-toggle>
                        <label igxLabel>Start Date</label>
                        <input igxInput igxDateTimeEditor [(ngModel)]="startDate" type="text" required>
                    </igx-date-range-start>
                    <igx-date-range-end>
                        <label igxLabel>End Date</label>
                        <input igxInput igxDateTimeEditor [(ngModel)]="endDate" type="text" required>
                    </igx-date-range-end>
                </igx-date-range-picker>
                
                 
                </div>
              </div>

            <br />
            <div class="form-group col-md-3" style="margin-top: 3%">
              <button type="button" (click)="search()" class="btn btn-primary btn-sm mr-1" >
                Search
              </button>

              <button
                type="button"
                (click)="reset()"
                class="btn btn-primary btn-sm mr-1"
              >
                reset
              </button>
            </div>
            
          </div>


          <div class="row" style="padding-top: 3%;">
            <div class="col-xl-12 col-lg-12">
              <div class="card">
                <div class="card-body" style="padding-top: 10px;">
                    
                    <canvas baseChart [data]="salesData" [type]="'line'" [options]="chartOptions"  > </canvas>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    
      <ngx-spinner color="#fff" type="ball-scale-multiple"></ngx-spinner>
</div>