<div>
  <div class="content-body">
    <div class="container-fluid">
      <!-- Add Order -->

      <!-- row -->
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="d-flex mb-3">
                <h5 class="therapist-item mr-2" [class]="therapistTypeClass=='slp'?'active':''" (click)="therapistType('slp')">SLP</h5>
                <h5 class="therapist-item ml-2"  [class]="therapistTypeClass!='slp'?'active':''" (click)="therapistType('coach')">COACH</h5>
              </div>
              <div class="row">
                <div class="form-group col-md-3">
                  <label>Select Region</label>
                  <select
                    [(ngModel)]="selectedRegion" name="dropdownOption" id="dropdownOption" class="form-control" >
                    <option *ngFor="let country of countries" [value]="country.code"  >
                      {{ country.code }} {{ country.name }}
                    </option>
                  </select>
                </div>
                <br />
                <div class="form-group col-md-4" style="margin-top: 3%">
                  <button type="button" (click)="search()" class="btn btn-primary btn-sm mr-1" >
                    Search
                  </button>

                  <button
                    type="button"
                    (click)="reset()"
                    class="btn btn-primary btn-sm mr-1"
                  >
                    reset
                  </button>
                </div>
                <div class="col-md-4" style="margin-top: 3%">
                  <button
                    type="button" style="float: right"
                    (click)="addTherapist('dashboard/add-therapist')"
                    class="btn btn-primary btn-sm mr-1"
                    >
                    Add Therapist
                  </button>
                </div>
              </div>

              <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border dataTable hover" style="width: 100%; text-align: center;">
                <thead>
                  <tr>
                    <th>Sl No.</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email</th>
                    <!-- <th>Phone</th> -->
                    <th>Status</th>
                    <th>Details</th>
                    <th>Edit</th>
                    <th>Action</th>
                    <th>Sessions</th>
                    <th>Remove</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of therapistDetails; let i = index">
                    <td style="font-size: 12.5px;">{{ i + 1 }}</td>
                    <td style="font-size: 12.5px;">{{ item.FirstName }}</td>
                    <td style="font-size: 12.5px;">{{ item.LastName }}</td>
                    <td style="font-size: 12.5px;">{{ item.Email }}</td>
                    <!-- <td>{{ item.Phone }}</td> -->
                    <td style="font-size: 12.5px;">{{ item.Status }}</td>
                    <td>
                      <a
                        (click)="goToViewTherapist('dashboard/admin-therapist-view',item._id)"
                        [routerLink]="['/dashboard/admin-therapist-view', item._id]"
                        class="sessionmodallink"
                        ><i class="fa fa-eye" aria-hidden="true"></i></a
                      >
                    </td>

                    <td>
                      <div class="d-flex">
                        <a
                          (click)="goToViewTherapist('/dashboard/update-therapist',item._id)"
                          [routerLink]="['/dashboard/update-therapist', item._id]"
                          class="btn btn-primary shadow btn-xs sharp mr-1"
                          ><i class="fa fa-pencil"></i
                        ></a>
                        
                      </div>
                    </td>
                    <td>
                      <a
                        type="button"
                        *ngIf="item.isActive == false"
                        class="sessionmodallink"
                        >
                        <i class="fa fa-play-circle-o" aria-hidden="true" (click)="activeInactive(item.isActive,item._id)"></i>
                      </a>
                      <a
                        type="button"
                        *ngIf="item.isActive == true"
                         class="sessionmodallink"
                        > 
                        <i class="fa fa-pause" aria-hidden="true"  (click)="activeInactive(item.isActive,item._id)" ></i>
                      </a>
                    </td>
                    <td>
                      <a class="sessionmodallink"
                      (click)="allSessions(item.FullName,item._id)"
                      type="button"
                      ><i class="fa fa-eye" aria-hidden="true"></i></a
                      >
                    </td>
                    <td>
                      <div class="d-flex">
                        <a
                          type="button"
                          (click)="deleteTherapist(item._id)"
                          class="btn btn-primary shadow btn-xs sharp mr-1"
                          ><i class="fa fa-trash"></i
                        ></a>
                        
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-spinner color="#fff" type="ball-scale-multiple"></ngx-spinner> 
