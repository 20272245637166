import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import axios from 'axios';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { userData } from '../../shared/modules/user-data-strore';
import { HttpService } from '../../shared/service/http.service';

@Component({
  selector: 'app-therapist-user',
  templateUrl: './therapist-user.component.html',
  styleUrls: ['./therapist-user.component.css']
})
export class TherapistUserComponent implements OnInit {
//   constructor(
//     private http: HttpService,
//     private router: Router,
//     private route: ActivatedRoute,
//     private spinner: NgxSpinnerService
//   ) {}
//   userDetails: any = userData;
//   users: any;
//   therapistId: any = '';
//   title = 'datatables';
//   dtTrigger: Subject<any> = new Subject<any>();
//   dtOptions: DataTables.Settings = {};

//   ngOnInit(): void {
//     this.dtOptions = {
//       pagingType: 'full_numbers',
//       pageLength: 10,
//       processing: true,
//     };

//     this.users = this.userDetails;
//     this.dtTrigger.next('');
//   }

//   ngOnDestroy(): void {
//     this.dtTrigger.unsubscribe();
//   }

//   public goToProfile(path: any) {
//     this.router.navigate([path]);
//     sessionStorage.setItem('path',path);
//   }

//   public showSessions(){
//     this.router.navigate(['dashboard/therapist-user-session-details']);
//     sessionStorage.setItem('path','dashboard/therapist-user-session-details');
//   }

//   public goToUser(path: any) {
//     this.router.navigate([path]);
//     sessionStorage.setItem('path', path);
//   }
// }
constructor(
  private http: HttpService, 
  private router: Router,
  private spinner: NgxSpinnerService) {}

listOfUser: any = [];
dtOptions: DataTables.Settings = {};
dtTrigger: Subject<any> = new Subject<any>();
userImage: any;
// baseUrl: any = environment.imageUrl;

ngOnInit(): void {
  this.dtOptions = {
    pagingType: 'full_numbers',
    pageLength: 10,
    processing: true,
  };
  this.getAllTherapistUser();
  //this.getAllMessages();
}

ngOnDestroy(): void {
  this.dtTrigger.unsubscribe();
}

getAllTherapistUser(): void {
  this.spinner.show();
  let currentUser: any = sessionStorage.getItem('currentUser');
  let token: any = JSON.parse(currentUser).token;
  let therapistNAme = sessionStorage.getItem('fullName');
  // console.log(token);

  let headers = new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + token,
  });
  let options = { headers: headers };
  this.http.get(`therapist/findSessionsOfUsers/${therapistNAme}`, options).subscribe((res: any) => {
    this.listOfUser = res;
    this.dtTrigger.next('')
    this.spinner.hide();

  });

  let user_Id: any = JSON.parse(currentUser).userId;
}

public showSessions(mail: any){
  //console.log(mail);
  sessionStorage.setItem('setUserItem',mail);
  this.router.navigate(['dashboard/therapist-user-session-details']);
  sessionStorage.setItem('path','dashboard/therapist-user-session-details');
}

}