<!-- <!DOCTYPE html>
<html lang="en">
  <body>
    <div>

      <div class="content-body" id="content" #content>

        <div class="container-fluid">


          <diV >
            <div class="row">
              <div class="form-group col-md-5">
                <label class="nav-text" style="font-family: Titillium Web ,sans-serif;">Select Filters</label>
                <select
                  [(ngModel)]="selectedFilters" name="dropdownOption" id="dropdownOption" class="form-control"  style="font-family: Titillium Web ,sans-serif;">
                  <option *ngFor="let filter of dateFlters" [value]="filter.value" style="font-family: Titillium Web ,sans-serif;" >
                    {{ filter.name }}
                  </option>
                </select>
                <div class="row" style="height: 20%;" *ngIf="selectedFilters == 'customDate' ">

                  <igx-date-range-picker>
                    <igx-date-range-start>
                        <igx-picker-toggle igxPrefix>
                            <igx-icon>calendar_today</igx-icon>
                        </igx-picker-toggle>
                        <label igxLabel>Start Date</label>
                        <input igxInput igxDateTimeEditor [(ngModel)]="startDate" type="text" required>
                    </igx-date-range-start>
                    <igx-date-range-end>
                        <label igxLabel>End Date</label>
                        <input igxInput igxDateTimeEditor [(ngModel)]="endDate" type="text" required>
                    </igx-date-range-end>
                </igx-date-range-picker>


                </div>
              </div>

              <br />
              <div class="form-group col-md-4" style="margin-top: 4%" id="btnSeacrh" #btnSeacrh>
                <button type="button" class="btn btn-primary btn-sm mr-1" style="font-family: Titillium Web ,sans-serif;" (click)="searchByFilters()" > Search  </button>
                <button type="button" class="btn btn-primary btn-sm mr-1" style="font-family: Titillium Web ,sans-serif;" (click)="reset()" > reset </button>
              </div>

              <div class="col-md-3" style="margin-top: 3%;text-align: right;" id="btn" #btn>
                <a style="color: #FF0000;" type="button" data-toggle="modal" data-target="#editmodal" (click)="openPDF()">
                 <i aria-hidden="true" class="fa fa-file-pdf-o"  aria-hidden="true" style="font-size: 25px" ></i>
                </a>

                <!-- <a style="color: #1D6F42;padding-left: 10%;" type="button" data-toggle="modal" data-target="#editmodal" (click)="exportExcel()">
                  <i aria-hidden="true" class="fa fa-file-excel-o"  aria-hidden="true" style="font-size: 25px"></i>
                 </a> -->
              <!-- </div>
            </div>

            <div>
              <div class="row">
                <div class="col-6">
                  <div class="widget-stat card">
                    <div class="card-body" style="text-align: center;">
                      <b style="font-size: bolder;padding-bottom: 3%;text-align: center;font-size: 14px;color: black;">Active / Inactive </b>
                      <div style="margin-left: auto;  margin-right: auto; text-align: center;">
                        <google-chart [data]="pieChartActive"> </google-chart>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="widget-stat card">
                    <div class="card-body p-8"style="text-align: center;" >
                      <div style="height: 30%;"> -->
                        <!-- <canvas baseChart [data]="salesData" [type]="'line'" [options]="chartOptions"  > </canvas> -->
                        <!-- <b style="font-size: bolder;padding-bottom: 3%;text-align: center;font-size: 14px;color: black;">Total Revenue </b>
                        <h2 style="text-align: center;">$ {{ totalRevenue | number : '1.2-2' }}</h2>
                        <div style="text-align: right;padding-top: 1.4%;font-size: 10px;" (click)="showRevenue()">
                          <a type="button">show more...</a>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>


              <div class="row">
                <div class="col-6">
                  <div class="widget-stat card">
                    <div class="card-body" style="text-align: center;">
                      <div style="height: 30%;">
                        <b style="font-size: bolder;padding-bottom: 3%;text-align: center;font-size: 14px;color: black;">New Users</b>
                        <canvas baseChart [data]="salesDataNewUser" [type]="'line'" [options]="chartOptionsNewUser"  > </canvas>
                      </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <div class="widget-stat card">
                <div class="card-body">
                  <div style="height: 30%;">
                    <canvas baseChart [data]="salesDataNewUser" [type]="'line'" [options]="chartOptionsNewUser"  > </canvas>
                    <div style="text-align: right;font-size: 10px;" (click)="newUserDetail()">
                      <a type="button">show more...</a>
                    </div>
                  </div>

                </div>

                <div class="col-6">
                  <div class="widget-stat card">
                    <div class="card-body" style="text-align: center;">
                      <b style="font-size: bolder;padding-bottom: 3%;text-align: center;font-size: 14px;color: black;">Decibles</b>
                      <div style="margin-left: auto;  margin-right: auto;">
                        <google-chart [data]="pieChart"> </google-chart>

                        <div style="text-align: right;font-size: 10px;" (click)="userDecibles()">
                          <a type="button">show more...</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </diV>
        </div>
      </div>

    </div>
<ngx-spinner color="#fff" type="ball-scale-multiple"></ngx-spinner>
</body>
</html> --> -->

<!DOCTYPE html>
<html lang="en">
  <body>
    <div>



      <div class="content-body" id="content" #content>

        <div class="container-fluid">
          <!-- Add Order -->
          <!-- <div class="modal fade" id="addOrderModalside">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Add Event</h5>
                  <button type="button" class="close" data-dismiss="modal">
                    <span>&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <form>
                    <div class="form-group">
                      <label class="text-black font-w500">Event Name</label>
                      <input type="text" class="form-control" />
                    </div>
                    <div class="form-group">
                      <label class="text-black font-w500">Event Date</label>
                      <input type="date" class="form-control" />
                    </div>
                    <div class="form-group">
                      <label class="text-black font-w500">Description</label>
                      <input type="text" class="form-control" />
                    </div>
                    <div class="form-group">
                      <button type="button" class="btn btn-primary">
                        Create
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div> -->

          <diV >
            <div class="row">
              <div class="form-group col-md-5">
                <label class="nav-text" style="font-family: Titillium Web ,sans-serif;">Select Filters</label>
                <select
                  [(ngModel)]="selectedFilters" name="dropdownOption" id="dropdownOption" class="form-control"  style="font-family: Titillium Web ,sans-serif;">
                  <option *ngFor="let filter of dateFlters" [value]="filter.value" style="font-family: Titillium Web ,sans-serif;" >
                    {{ filter.name }}
                  </option>
                </select>
                <div class="row" style="height: 20%;" *ngIf="selectedFilters == 'customDate' ">

                  <igx-date-range-picker>
                    <igx-date-range-start>
                        <igx-picker-toggle igxPrefix>
                            <igx-icon>calendar_today</igx-icon>
                        </igx-picker-toggle>
                        <label igxLabel>Start Date</label>
                        <input igxInput igxDateTimeEditor [(ngModel)]="startDate" type="text" required>
                    </igx-date-range-start>
                    <igx-date-range-end>
                        <label igxLabel>End Date</label>
                        <input igxInput igxDateTimeEditor [(ngModel)]="endDate" type="text" required>
                    </igx-date-range-end>
                </igx-date-range-picker>


                </div>
              </div>

              <br />
              <div class="form-group col-md-4" style="margin-top: 4%" id="btnSeacrh" #btnSeacrh>
                <button type="button" class="btn btn-primary btn-sm mr-1" style="font-family: Titillium Web ,sans-serif;" (click)="searchByFilters()" > Search  </button>
                <button type="button" class="btn btn-primary btn-sm mr-1" style="font-family: Titillium Web ,sans-serif;" (click)="reset()" > reset </button>
              </div>

              <div class="col-md-3" style="margin-top: 3%;text-align: right;" id="btn" #btn>
                <a style="color: #FF0000;" type="button" data-toggle="modal" data-target="#editmodal" (click)="openPDF()">
                 <i aria-hidden="true" class="fa fa-file-pdf-o"  aria-hidden="true" style="font-size: 25px" ></i>
                </a>

                <!-- <a style="color: #1D6F42;padding-left: 10%;" type="button" data-toggle="modal" data-target="#editmodal" (click)="exportExcel()">
                  <i aria-hidden="true" class="fa fa-file-excel-o"  aria-hidden="true" style="font-size: 25px"></i>
                 </a> -->
              </div>
            </div>

            <div>
              <div class="row">
                <div class="col-6">
                  <div class="widget-stat card">
                    <div class="card-body" style="text-align: center;">
                      <b style="font-size: bolder;padding-bottom: 3%;text-align: center;font-size: 14px;color: black;">Active / Inactive </b>
                      <div style="margin-left: auto;  margin-right: auto; text-align: center;">
                        <google-chart [data]="pieChartActive"> </google-chart>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="widget-stat card">
                    <div class="card-body p-8"style="text-align: center;" >
                      <div style="height: 30%;">
                        <!-- <canvas baseChart [data]="salesData" [type]="'line'" [options]="chartOptions"  > </canvas> -->
                        <b style="font-size: bolder;padding-bottom: 3%;text-align: center;font-size: 14px;color: black;">Total Revenue </b>
                        <h2 style="text-align: center;">$ {{ totalRevenue | number : '1.2-2' }}</h2>
                        <div style="text-align: right;padding-top: 1.4%;font-size: 10px;" (click)="showRevenue()">
                          <a type="button">show more...</a>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>


                <div class="row">
                  <div class="col-3"></div>
                  <div class="col-6"  >
                    <div class="widget-stat card">
                      <div class="card-body ">
                        <div style="height: 30%;" style="text-align: center;">
                          <b style="font-size: bolder;padding-bottom: 3%;text-align: center;font-size: 14px;color: black;">New Users</b>
                          <canvas baseChart [data]="dataNewUser" [type]="'line'" [options]="chartOptionsNewUser"  > </canvas>
                          <div style="text-align: right;font-size: 10px;" (click)="newUserDetail()">
                            <a type="button">show more...</a>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div class="col-3"></div>

                <div *ngIf="decibles" class="col-6">
                  <div class="widget-stat card">
                    <div class="card-body" style="text-align: center;">
                      <b style="font-size: bolder;padding-bottom: 3%;text-align: center;font-size: 14px;color: black;">Decibles</b>
                      <div style="margin-left: auto;  margin-right: auto;">
                        <google-chart [data]="pieChart"> </google-chart>

                        <!-- <div style="text-align: right;font-size: 10px;" (click)="userDecibles()">
                          <a type="button">show more...</a>
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </diV>
        </div>
      </div>

    </div>
<ngx-spinner color="#fff" type="ball-scale-multiple"></ngx-spinner>
</body>
</html>

