import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  roleAs: any = '';
  // login(userDetails: any) {
  //   if ((this.userDetails.Email, this.userDetails.Password)) {
  //     this.loading = true;
  //     this.http.post('user/login', this.userDetails).subscribe(
  //       (res: any) => {
  //         console.log(res);
  //         if (res.token) {
  //           this.loading = false;
  //           localStorage.setItem('currentUser', JSON.stringify(res));
  //           if (res.isAdmin) {
  //             this.router.navigate(['/dashboard']);
  //           } else {
  //             this.router.navigate(['/therapist-dashboard']);
  //           }
  //         }
  //       },
  //       (error: any) => {
  //         this.loading = false;
  //         console.log(error);
  //         Swal.fire('Error!', 'Login Failed', 'error');
  //       }
  //     );
  //   } else {
  //     Swal.fire('Error', 'Enter Email and Password', 'error');
  //   }
  // }
  // logout(): void {
  //   Swal.fire({
  //     title: 'Are you sure?',
  //     icon: 'warning',
  //     // showDenyButton: true,
  //     showCancelButton: true,
  //     confirmButtonText: 'Yes',
  //   }).then((result) => {
  //     /* Read more about isConfirmed, isDenied below */
  //     if (result.isConfirmed) {
  //       console.log('logout');
  //       localStorage.removeItem('currentUser');
  //       this.router.navigate(['/']);
  //     }
  //   });
  // }
  public get loggedIn(): boolean {
    return sessionStorage.getItem('currentUser') !== null;
  }

  getRole() {
    let currentUser: any = sessionStorage.getItem('currentUser');
    let role: any = JSON.parse(currentUser).role;
    this.roleAs = role;
    return this.roleAs;
  }

  public get isAdmin(): boolean {
    let currentUser: any = {};
    currentUser = sessionStorage.getItem('currentUser');
    return JSON.parse(currentUser).isAdmin === true;
  }
}
