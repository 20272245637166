<div>

  <div class="content-body">
    <div class="container-fluid">
      <!-- Add Order -->

      <!-- row -->
      <div class="row">
        <div class="col-xl-12 col-lg-12">
          <div class="card">
            <div class="card-body">

              <div class="basic-form">
                <form
                  (ngSubmit)="onSubmit()"
                  #addTherapistForm="ngForm"
                  novalidate
                >

                <div class="row">
                  <div class="col-lg-5">
                    <button  (click)="goBack()" class="btn btn-primary">
                      Back
                    </button>
                  </div>
                  <div class="col-lg-7" style="text-align: left;">
                    <div class="card-title " style="padding-bottom: 25px;">
                      Update Profile
                    </div>
                  </div>
                </div>
                 
                  <br />
                  <div class="form-row">
                    <div class="col-xl-3 col-lg-3"></div>
                    <!-- <div class="form-group col-md-4">
                      <label>User Name</label>
                      <input
                        class="form-control"
                        type="text"
                        name="username"
                        id="username"
                        required
                        #username="ngModel"
                        [(ngModel)]="therapistDetails.UserName"
                      />
                      <div
                        *ngIf="
                          username.invalid &&
                          (username.dirty || username.touched)
                        "
                        class="alert"
                      >
                        <div *ngIf="username.errors?.['required']">
                          Username is required.
                        </div>
                      </div>
                    </div>

                    <div class="form-group col-md-4">
                      <label>First Name</label>
                      <input
                        class="form-control"
                        type="text"
                        name="firstname"
                        id="firstname"
                        required
                        #firstname="ngModel"
                        [(ngModel)]="therapistDetails.FirstName"
                      />
                      <div
                        *ngIf="
                          firstname.invalid &&
                          (firstname.dirty || firstname.touched)
                        "
                        class="alert"
                      >
                        <div *ngIf="firstname.errors?.['required']">
                          firstname is required.
                        </div>
                      </div>
                    </div>

                    <div class="form-group col-md-4">
                      <label>Last Name</label>
                      <input
                        class="form-control"
                        type="text"
                        name="lastname"
                        id="lastname"
                        required
                        #lastname="ngModel"
                        [(ngModel)]="therapistDetails.LastName"
                      />
                      <div
                        *ngIf="
                          lastname.invalid &&
                          (lastname.dirty || lastname.touched)
                        "
                        class="alert"
                      >
                        <div *ngIf="lastname.errors?.['required']">
                          Last name is required.
                        </div>
                      </div>
                    </div>

                    <div class="form-group col-md-4">
                      <label>Email</label>
                      <input
                        class="form-control"
                        type="email"
                        name="email"
                        id="email"
                        required
                        [pattern]="emailPattern"
                        #email="ngModel"
                        [(ngModel)]="therapistDetails.Email"
                      />
                      <div
                        *ngIf="email.invalid && (email.dirty || email.touched)"
                        class="alert"
                      >
                        <div *ngIf="email.errors?.['required']">
                          Email is required.
                        </div>
                        <div *ngIf="email.errors?.['pattern']">
                          Invalid Email.
                        </div>
                      </div>
                    </div> -->

                    <div class="form-group col-md-6 text-center">
                      <div class="profile-photo">
                        <div *ngIf="userImage; else elseBlock">
                          <img
                            [src]="baseUrl + userImage"
                            style="border-radius: 50%;height: 100px;width: 100px;"
                            alt="user"
                          />
                        </div>
                        <ng-template #elseBlock>
                          <img
                            src="../../assets/images/avatar/avatar.png"
                            style="border-radius: 50%;height: 100px;width: 100px;"
                            alt="user"
                          />
                        </ng-template>
                      </div>

                      <br />
                      <br />

                      <input
                        type="file"
                        class="file-input"
                        (change)="onFileSelected($event)"
                        style="padding: 5px;"
                        #fileUpload
                      />


                     <button type="button" class="btn btn-primary" (click)="submit()" [disabled]="!fileName" >
                      Upload
                    </button>

                      <br />                      
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="col-xl-3 col-lg-3"></div>
                    

                    <div class="form-group col-md-6">
                      <label>Phone</label>

                      <div class="row">
                        <div class="col-lg-9">
                          <input
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                        class="form-control" type="number"
                        placeholder="Enter Phone Number"
                        name="phone"
                        id="phone"
                        required
                        #phone="ngModel"
                        min="0"
                        onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57"
                        [(ngModel)]="adminDetails.Phone"
                        
                      />

                      <div *ngIf="phone.invalid && (phone.dirty || phone.touched)" class="alert" >
                        <div *ngIf="phone.errors?.['required']">
                          phone is required.
                        </div>
                        <div *ngIf="phone.errors?.['pattern']">
                          Invalid phone number.
                        </div>
                      </div>
                        </div>
                        <div class="col-lg-3" style="padding-left: 5px;">
                          <button type="submit" class="btn btn-primary">
                            Submit
                            <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                          </button>
                        </div>
                      </div>

                      

                      
                    </div>
                  </div>
                  <!-- <hr /> -->
                 
                  <div class="form-row">
                    <div class="col-xl-3 col-lg-3"></div>

                    <div class="form-group col-md-6 text-center">
                      
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ngx-spinner color="#fff" type="ball-scale-multiple"></ngx-spinner>
</div>

