<div>

    <div class="content-body" style="padding: 2%;">
        <div class="row">
            <div class="col-lg-5">
              <button (click)="back()" class="btn btn-primary">
                Back
              </button>
            </div>
            <div class="col-lg-7" style="text-align: left;">
              <div class="card-title " style="padding-bottom: 25px;">
                Session Details
              </div>
            </div>
          </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <div class="card">

                <div class="card-body">
                    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border dataTable hover" style="width: 100%;">
                      <thead style="text-align: center;">
                        <tr>
                          <th style="color: black">Session No</th>
                          <th style="color: black">Session Date</th>
                          <th style="color: black">Status</th>
                          <th style="color: black">Note</th>
                          <!-- <th style="color: black">Add Note</th> -->
                      </tr>
                      </thead>
                      <tbody style="text-align: center;">
                        <tr *ngFor="let item of userSessionsData; let i = index">
                          <td>{{ i + 1 }}</td>
                          <td>{{ item.start_time | date: "dd/MM/yyyy hh:mm a"}}</td>
                          <td>{{ item.Status }}</td>
                          <!-- <td>{{ item.count }}</td> -->
                          <td style="color: #F4614D;font-size: bold;" (click)="showNote(item._id)">
                            <i class="fa fa-eye" aria-hidden="true"></i>
                          </td>
                         <!-- <td style="color: #F4614D;" (click)="addNote(item._id)"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></td> -->
                        </tr>
                      </tbody>
                    </table>
                  </div>
              </div>
            </div>
          </div>
    
          <!-- <div id="calendar" class="app-fullcalendar"></div> -->
        </div>
      </div>
    
      <ngx-spinner color="#fff" type="ball-scale-multiple"></ngx-spinner>  
    
    </div>