import { HttpService } from '../../shared/service/http.service';
import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { countries } from 'src/app/shared/modules/country-data-store';

@Component({
  selector: 'app-admin-user-view',
  templateUrl: './admin-user-view.component.html',
  styleUrls: ['./admin-user-view.component.css'],
})
export class AdminUserViewComponent implements OnInit {
  constructor(
    private http: HttpService,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
  ) {}
  userDetails: any;
  userId: any = '';
  role: any;
  deviceType: any;
  userImage: any;
  baseUrl: any = environment.imageUrl;
  therapistImage: any;
  userName: any;
  email: any;
  phone: any;
  region: any;
  specificToCountry: any;
  stateName: any;
  createdAt: any;
  validUpto:any = "nil";
  subscriptionType:any  = "Free";
  userType:any = ""
  lastReceived: any = ""

  ngOnInit(): void {
    let currentUser: any = sessionStorage.getItem('currentUser');
    let token: any = JSON.parse(currentUser).token;
    this.spinner.show();
    this.route.params.subscribe((params: any) => {
     // console.log(params);
      this.userId = params.id;
    });
    //console.log(token);

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    });
    let options = { headers: headers };
    this.http
      .get('user/getUserById/' + this.userId, options)
      .subscribe((res: any) => {
        console.log("user response",res);
        this.userDetails = res;
        this.userName = this.userDetails.FirstName+" "+this.userDetails.LastName;
        this.role = this.userDetails.Role;
        this.email = this.userDetails.Email;
        this.phone = this.userDetails.Phone == 0 ? "nil":this.userDetails.Phone;
        let index = countries.findIndex(x => x.code === this.userDetails.Region);
        // this.region = countries[index].name;
        this.region = this.userDetails.RegionCode
        this.specificToCountry = this.userDetails.SpecificToCountry;
        this.stateName = this.userDetails.State;
        //console.log(this.userDetails);
        this.spinner.hide();
        this.userType = this.userDetails?.CorporateCode.trim() == ""?"Individual":"Corporate"
        this.therapistImage = this.userDetails?.profileImageUrl;
        var dArr = this.userDetails?.createdAt.split("T")[0].split("-")
        this.createdAt = dArr[2]+"-"+dArr[1]+"-"+dArr[0]
        this.deviceType = this.userDetails?.DeviceType
        
        if(this.userDetails?.lastReceivedData == undefined){
          this.lastReceived = "no data received yet"
        }else{
          var lrd_arr = this.userDetails?.lastReceivedData.split("T")[0].split("-")
          var lrt = this.userDetails?.lastReceivedData.split("T")[1].split(".")[0]
          this.lastReceived = lrd_arr[2]+"-"+lrd_arr[1]+"-"+lrd_arr[0]+"\n"+lrt
          
          console.log(this.lastReceived)
        }

      });

    this.http.get('payment/getPaymentStatus/'+this.userId, options).subscribe((res:any)=>{
      console.log(res);
      if(res){
        if(res.message.details.SubscriptionType != "Unlimited"){
          this.subscriptionType = res.message.details.subscriptionType != "Annual"?res.message.details.subscriptionType+"ly":"Annual"
          let dArr = res.message.Days.split("T")[0].split("-")
          this.validUpto = dArr[2]+"-"+dArr[1]+"-"+dArr[0]
        }else{
          this.subscriptionType = "Corporate"
          this.validUpto = "nil"
        }
      }
    })

    let userId: any = JSON.parse(currentUser).userId;
  }

  public back(path: any) {
    if(sessionStorage.getItem("role") == "CorporateAdmin"){
      path = "dashboard/corporate-users"
      this.router.navigate([path]);
    }else{
      this.router.navigate([path]);
    }
    
    sessionStorage.setItem('path',path);
  }
}
