<div class="authincation h-100 login-bg-sec">
    <div class="container h-100">
      <div class="row justify-content-center h-100 align-items-center">
        <div class="col-md-6">
          <div class="authincation-content">
            <div class="row no-gutters row-log">
              <div class="col-xl-12">
                <div class="auth-form">
                  <div class="text-center mb-3">
                    <a href="index.html"
                      ><img
                        src="../../assets/images/logo.png"
                        alt=""
                        class="img-fluid"
                    /></a>
                  </div>
                  <form (ngSubmit)="onSubmit()" #registerForm="ngForm" novalidate>
                    <div class="container form-group">
                      <label class="mb-1"><strong>Email </strong></label>
                      <input
                        class="form-control"
                        type="email"
                        placeholder="Enter Email"
                        name="email"
                        id="email"
                        required
                        #email="ngModel"
                        [(ngModel)]="userDetails.Email"
                      />
                      <div
                        *ngIf="email.invalid && (email.dirty || email.touched)"
                        class="alert"
                      >
                        <div *ngIf="email.errors?.['required']">
                          Email is required.
                        </div>
                      </div>
                    </div>
                    <div class="container form-group">
                        <label class="mb-1"><strong>Verification Code </strong></label>
                        <input
                          class="form-control"
                          type="text"
                          placeholder="Enter code"
                          name="code"
                          id="code"
                          required
                          #code="ngModel"
                          [(ngModel)]="userDetails.code"
                        />
                        <div
                          *ngIf="code.invalid && (code.dirty || code.touched)"
                          class="alert"
                        >
                          <div *ngIf="code.errors?.['required']">
                            Code is required.
                          </div>
                        </div>
                      </div>
                      <div class="container form-group">
                        <label class="mb-1"><strong>Password </strong></label>
                        <input
                          class="form-control"
                          type="password"
                          placeholder="Enter password"
                          name="password"
                          id="password"
                          required
                          #password="ngModel"
                          [(ngModel)]="userDetails.password"
                        />
                        <div
                          *ngIf="password.invalid && (password.dirty || password.touched)"
                          class="alert"
                        >
                          <div *ngIf="password.errors?.['required']">
                            password is required.
                          </div>
                        </div>
                      </div>
  
                    <div class="text-center">
                      <button
                        type="submit"
                        class="btn bg-white text-primary btn-block"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  