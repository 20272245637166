import { Injectable } from '@angular/core';
import { DashbaordComponent } from 'src/app/master/dashbaord/dashbaord.component';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class CalendlyAccesTokenService {

  constructor( private http: HttpService) { }

  callCalendlyToken(){
    return this.http.get('calendly/refreshAccessToken').subscribe((res: any) => {
      //console.log(res.calendlyAccessToken);
      sessionStorage.setItem('accessToken',res.calendlyAccessToken);
    });
  }
  
}
