<div>
  <div class="content-body">
    <div class="container-fluid">
      <!-- Add Order -->

      <!-- row -->
      <div class="row">

        <div class="row">
          <div class="col-lg-5" style="padding-top: 2%;padding-left: 5%">
            <button  (click)="back()" class="btn btn-primary">
              Back
            </button>
          </div>
          <div class="col-lg-7" style="text-align: left;padding-top: 2%;">
            <div class="card-title " style="padding-bottom: 25px;">
              Add Subscription
            </div>
          </div>
        </div>

        <div class="col-xl-12 col-lg-12">
          <div class="card">
            <div class="card-body">
              
              <br />
              <br />
              <div class="basic-form">
                <form
                  (ngSubmit)="onSubmit()"
                  #addTherapistForm="ngForm"
                  novalidate
                >
                  <div class="form-row">
                    <div class="form-group col-md-4">
                      <label>Plan Name</label>
                      <input
                        class="form-control"
                        type="text"
                        placeholder="Enter Plan Name"
                        name="username"
                        id="username"
                        required
                        #username="ngModel"
                        [(ngModel)]="userDetails.Name"
                      />
                      <div
                        *ngIf="
                          username.invalid &&
                          (username.dirty || username.touched)
                        "
                        class="alert"
                      >
                        <div *ngIf="username.errors?.['required']">
                          Plan Name is required.
                        </div>
                      </div>
                    </div>

                    <div class="form-group col-md-4">
                      <label>Price</label>
                      <input
                        class="form-control"
                        type="text"
                        placeholder="Enter Price"
                        name="price"
                        id="price"
                        required
                        #price="ngModel"
                        [(ngModel)]="userDetails.Price"
                      />
                      <div
                        *ngIf="price.invalid && (price.dirty || price.touched)"
                        class="alert"
                      >
                        <div *ngIf="price.errors?.['required']">
                          price is required.
                        </div>
                      </div>
                    </div>

                    <div class="form-group col-md-4">
                      <label>Currency symbol</label>
                      <input
                        class="form-control"
                        type="text"
                        placeholder="Enter currency symbol"
                        name="CurrencySymbol"
                        id="CurrencySymbol"
                        required
                        #CurrencySymbol="ngModel"
                        [(ngModel)]="userDetails.CurrencySymbol"
                      />
                      <div
                        *ngIf="CurrencySymbol.invalid && (CurrencySymbol.dirty || CurrencySymbol.touched)"
                        class="alert"
                      >
                        <div *ngIf="CurrencySymbol.errors?.['required']">
                          Currency symbol is required.
                        </div>
                      </div>
                    </div>

                    <div class="form-group col-md-4">
                      <label>Type</label>
                      <select
                        [(ngModel)]="userDetails.Type"
                        name="dropdownOptionType"
                        id="dropdownOptionType"
                        class="form-control"
                      >
                        <option
                          *ngFor="let sub of subTypes"
                          [value]="sub.value"
                        >
                          {{ sub.name }}
                        </option>
                      </select>
                    </div>

                    <div class="form-group col-md-4">
                      <label>Promotional (15 mint session)</label>
                      <select
                        [(ngModel)]="userDetails.Promotional"
                        name="dropdownOptionPromotional"
                        id="dropdownOptionPromotional"
                        class="form-control"
                      >
                        <option
                          *ngFor="let pro of promotionalTypes"
                          [value]="pro.value"
                        >
                          {{ pro.name }}
                        </option>
                      </select>
                    </div>

                    <div class="form-group col-md-4">
                      <label>Stripe product ID</label>
                      <input
                        class="form-control"
                        type="text"
                        placeholder="Enter Stripe product ID"
                        name="productID"
                        id="productID"
                        required
                        #productID="ngModel"
                        [(ngModel)]="userDetails.ProductID"
                      />
                      <div
                        *ngIf="
                        productID.invalid &&
                          (productID.dirty || productID.touched)
                        "
                        class="alert"
                      >
                        <div *ngIf="productID.errors?.['required']">
                          Product ID is required.
                        </div>
                      </div>
                    </div>

                    <div class="form-group col-md-4">
                      <label>Description</label>
                      <textarea
                        class="form-control"
                        type="text"
                        placeholder="Enter Description"
                        name="description"
                        id="description"
                        required
                        #description="ngModel"
                        [(ngModel)]="userDetails.Description"
                      ></textarea>
                      <div
                        *ngIf="
                          description.invalid &&
                          (description.dirty || description.touched)
                        "
                        class="alert"
                      >
                        <div *ngIf="description.errors?.['required']">
                          Description is required.
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-md-4">
                      <label>Region</label>
                      <select
                        [(ngModel)]="userDetails.Region"
                        name="dropdownOption"
                        id="dropdownOption"
                        class="form-control"
                      >
                        <option
                          *ngFor="let country of countries"
                          [value]="country.code"
                        >
                          {{ country.code }} {{ country.name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <button type="submit" class="btn btn-primary" style="text-align: center;">
                    Submit <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ngx-spinner color="#fff" type="ball-scale-multiple"></ngx-spinner> 
</div>
