<div>
    <div class="content-body" >

        <div class="row">
          <div class="col-lg-5" style="padding-top: 2%;padding-left: 5%">
            <button  class="btn btn-primary" (click)="back()">
              Back
            </button>
          </div>
          <div class="col-lg-7" style="text-align: left;padding-top: 2%;">
            <div class="card-title ">
              New Users
            </div>
          </div>
        </div>
        
        <div class="container-fluid" id="newuser" #newuser>
          <!-- Add Order -->
          <!-- row -->

          <div class="row">
            <div class="form-group col-md-3">
              <label>Select Region</label>
              <select
                [(ngModel)]="selectedRegion" name="dropdownOption" id="dropdownOption" class="form-control" >
                <option *ngFor="let country of countries" [value]="country.code"  >
                  {{ country.code }} {{ country.name }}
                </option>
              </select>
            </div>

            <div class="form-group col-md-3">
              <label class="nav-text" style="font-family: Titillium Web ,sans-serif;">Select Filters</label>
              <select 
                [(ngModel)]="selectedFilters" name="dropdownOption" id="dropdownOption" class="form-control"  style="font-family: Titillium Web ,sans-serif;">
                <option *ngFor="let filter of dateFlters" [value]="filter.value" style="font-family: Titillium Web ,sans-serif;" >
                  {{ filter.name }} 
                </option>
              </select>
              <div class="row" style="height: 20%;" *ngIf="selectedFilters == 'customDate' ">
                
                <igx-date-range-picker>
                  <igx-date-range-start>
                      <igx-picker-toggle igxPrefix>
                          <igx-icon>calendar_today</igx-icon>
                      </igx-picker-toggle>
                      <label igxLabel>Start Date</label>
                      <input igxInput igxDateTimeEditor [(ngModel)]="startDate" type="text" required>
                  </igx-date-range-start>
                  <igx-date-range-end>
                      <label igxLabel>End Date</label>
                      <input igxInput igxDateTimeEditor [(ngModel)]="endDate" type="text" required>
                  </igx-date-range-end>
              </igx-date-range-picker>
              
               
              </div>
            </div>
            <br />

          <div class="form-group col-md-4" style="margin-top: 4%" id="btnSeacrh" #btnSeacrh>
            <button type="button" class="btn btn-primary btn-sm mr-1" style="font-family: Titillium Web ,sans-serif;" (click)="searchByFilters()" > Search  </button>
            <button type="button" class="btn btn-primary btn-sm mr-1" style="font-family: Titillium Web ,sans-serif;" (click)="reset()" > reset </button>
          </div>
            <!-- <div class="col-md-5">
              <div style="padding-top: 6%;">
                <igx-date-range-picker>
                  <igx-date-range-start>
                      <igx-picker-toggle igxPrefix>
                          <igx-icon>calendar_today</igx-icon>
                      </igx-picker-toggle>
                      <label igxLabel>Start Date</label>
                      <input igxInput igxDateTimeEditor [(ngModel)]="startDate" type="text" required>
                  </igx-date-range-start>
                  <igx-date-range-end>
                      <label igxLabel>End Date</label>
                      <input igxInput igxDateTimeEditor [(ngModel)]="endDate" type="text" required>
                  </igx-date-range-end>
              </igx-date-range-picker>
               </div>
            </div>

            <br /> -->
            <!-- <div class="form-group col-md-3" style="margin-top: 3%">
              <button type="button" (click)="search()" class="btn btn-primary btn-sm mr-1" >
                Search
              </button>

              <button
                type="button"
                (click)="reset()"
                class="btn btn-primary btn-sm mr-1"
              >
                reset
              </button>
            </div> -->

            <div class="form-group col-md-2" style="margin-top: 3%;text-align: right;" id="pdfbtn" #pdfbtn>
              <a style="color: #FF0000;" type="button" data-toggle="modal" data-target="#editmodal" (click)="openPDF()">
                <i aria-hidden="true" class="fa fa-file-pdf-o"  aria-hidden="true" style="font-size: 25px" ></i>
               </a> 
               <a style="color: #1D6F42;padding-left: 4%;" type="button" data-toggle="modal" data-target="#editmodal" (click)="exportExcel()">
                 <i aria-hidden="true" class="fa fa-file-excel-o"  aria-hidden="true" style="font-size: 25px"></i>
                </a>

            </div>
          </div>


          <div class="row" style="padding-top: 3%;">
            <div class="col-xl-12 col-lg-12">
              <div class="card">
                <div class="card-body" style="padding-top: 10px;">
                    
                    <canvas baseChart [data]="dataNewUser" [type]="'line'" [options]="chartOptions"  > </canvas>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    
      <ngx-spinner color="#fff" type="ball-scale-multiple"></ngx-spinner>
</div>
