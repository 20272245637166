<div>

  <div class="content-body" style="padding-bottom: 5%;">
    <div class="container-fluid">
      <!-- Add Order -->

      <!-- row -->
      <div class="row">
        <div class="col-xl-12 col-lg-12">
          <div class="row">
            <div class="col-lg-5" style="padding-bottom: 10px;">
              <button  (click)="back()" class="btn btn-primary">
                Back
              </button>
            </div>
            <div class="col-lg-7" style="padding-bottom: 10px;">
              <a class="dashboard_bar" style="color: black;"></a>
            </div>
          </div>
          
          <div class="card">
            <div class="card-body">
              <div class="basic-form">
                <form
                  (ngSubmit)="onSubmit()"
                  #addTherapistForm="ngForm"
                  novalidate
                >
                  <br />
                  <div class="form-row">
                    <div class="col-xl-3 col-lg-3"></div>

                    <div class="form-group col-md-6 text-center">
                      <div class="profile-photo">
                        <img
                          [src]="baseUrl + userImage"
                          width="100"
                          class="img-fluid rounded-circle"
                          alt="user"
                        />
                      </div>

                      <br />
                      <br />
                      <input
                        type="file"
                        class="file-input"
                        (change)="onFileSelected($event)"
                        #fileUpload
                      />

                      <div class="file-upload">
                        {{ fileName || "No file choosen yet." }}

                        <button
                          type="button"
                          class="btn btn-primary"
                          color="primary"
                          (click)="fileUpload.click()"
                        >
                          <i class="fa fa-paperclip" aria-hidden="true"></i>
                          Select Image
                        </button>
                        <a style="padding-left: 8px;">
                          <button type="button" class="btn btn-primary" (click)="submit()" [disabled]="!fileName">
                            Upload
                          </button>
                        </a>
                        
                      </div>
                      <br />    
                    </div>

                  </div>


                  <div class="form-row">
                    <div class="col-xl-3 col-lg-3"></div>
                    

                    <!-- <div class="form-group col-md-6">
                      <label>Phone</label>

                      <div class="row">
                        <div class="col-lg-9">
                          <input
                        class="form-control" type="number"
                        placeholder="Enter Phone Number"
                        name="phone"
                        id="phone"
                        required
                        #phone="ngModel"
                        [(ngModel)]="therapistDetails.Phone"
                        
                      />

                      <div *ngIf="phone.invalid && (phone.dirty || phone.touched)" class="alert" >
                        <div *ngIf="phone.errors?.['required']">
                          phone is required.
                        </div>
                        <div *ngIf="phone.errors?.['pattern']">
                          Invalid phone number.
                        </div>
                      </div>
                        </div>
                        <div class="col-lg-3" style="padding-left: 5px;">
                          <button type="submit" class="btn btn-primary">
                            Submit
                          </button>
                        </div>
                      </div>

                    </div> -->
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ngx-spinner color="#fff" type="ball-scale-multiple"></ngx-spinner>
</div>
