import { HttpService } from '../../shared/service/http.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import Swal from 'sweetalert2';
import axios from 'axios';
import { NgxSpinnerService } from 'ngx-spinner';
import { ChartData, ChartOptions, Color } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { Date_Filters } from '../../shared/models/model';
import { GoogleChartComponent, GoogleChartInterface, GoogleChartType } from 'ng2-google-charts';
import { DateRange, TreeGridMatchingRecordsOnlyFilteringStrategy } from 'igniteui-angular';
import { CalendlyAccesTokenService } from 'src/app/shared/service/calendly-acces-token.service';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { inherit } from 'hammerjs';
import { monthNames, weeks } from 'src/app/shared/modules/user-data-strore';
import { style } from '@angular/animations';

@Component({
  selector: 'app-dashbaord',
  templateUrl: './dashbaord.component.html',
  styleUrls: ['./dashbaord.component.css'],
})
export class DashbaordComponent implements OnInit {
  decibles:false
  public startDate: Date;
  public endDate: Date;
  allData: any = []
  exportList: any[] = [];
  @ViewChild('content') content: ElementRef;
  totalRevenue: any = 0 ;
  usdValue: any ;

  dateFlters: Date_Filters[] = [
    { name: 'Today', value: 'today' },
    { name: 'Yesterday', value: 'yesterday' },
    { name: 'This week', value: 'this week' },
    { name: 'Last week', value: 'last week' },
    { name: 'This Month', value: 'this month' },
    { name: 'Last Month', value: 'last month' },
    { name: 'This Year', value: 'this year' },
    { name: 'Custom Date', value: 'customDate' },
  ];

  selectedFilters: any;
  public weeks: any = weeks;
  public months: any = monthNames;
  public daterange: any = {};
  //activeUserNumber: any = 1;
  //inactiveUserNumber: any = 1;

  public options: any = {
    locale: { format: 'YYYY-MM-DD' },
    alwaysShowCalendars: false,
  };
  cchart: any;
  activeUserNumber: any = 0;
  inActiveUserNumber: any = 0;
  currentUser: any = sessionStorage.getItem('currentUser');
  token: any = JSON.parse(this.currentUser).token;


  //line chart for new user

  chartOptionsNewUser: ChartOptions = {
    responsive: true,
    plugins: {
      title: {
        display: true
      },
    },
  };

  dataNewUser: ChartData<'line'> = {
    labels: ['', '', '', '', ''],
    datasets: [
      { label: 'New User Added',
       data: [0, 0, 0, 0, 0],
       borderColor: '#ff6d4d',
       backgroundColor: ["#ff6d4d"],
       pointBackgroundColor: ["#ff6d4d"],
        tension: 1
       }
    ],
  };
  newUserArray: any = [];
  newUserFromApiData: any = [];

  //end
  @ViewChild( BaseChartDirective ) chart: BaseChartDirective;



  constructor(
    private http: HttpService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private calendlyAccessToken: CalendlyAccesTokenService,
    ) {}

  ngOnInit(): void {
    this.getEvents();
    this.loadData();
    this.newUserData()
    this.getDollarValue()
    //this.getTotalRevenue()

    if(sessionStorage.getItem('isLoggedIn') == "true" && sessionStorage.getItem('role') == "Admin") {
      this.router.navigate(['/dashboard/home']);
    } else if (sessionStorage.getItem('isLoggedIn') == "true" && sessionStorage.getItem('role') == "Therapist") {
      this.router.navigate(['dashboard/therapist-session-view']);
    }
    // window.location.reload();
  }

  public getTotalRevenue() {
    this.spinner.show();
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.token,
    });
    let options = { headers: headers };

    this.http
      .get('admin/getSessionRevenue', options)
      .subscribe((res: any) => {
        this.totalRevenue = res.totalRevenue[1].totalRevenue + (res.totalRevenue[0].totalRevenue * this.usdValue);
        //this.getDollarValue(res.totalRevenue[0].totalRevenue)
        this.spinner.hide();
      });
  }

  public getDollarValue() {
    this.spinner.show();

    this.http
      .getCurrencyValue('latest/INR')
      .subscribe((res: any) => {
        console.log(res);
        // this.totalRevenue += res.result;
        this.usdValue = res.conversion_rates.USD;
        this.spinner.hide();

        this.getTotalRevenue();
      });
  }

  public newUserData() {
    this.spinner.show();
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.token,
    });
    let options = { headers: headers };

    this.http
      .get('admin/getNewUserByMonth', options)
      .subscribe((res: any) => {

        this.newUserFromApiData = res;
        let users = []

        for (let i = 0;i< res.length;i++) {
          this.newUserArray.push(res[i].Total);
          if(this.selectedFilters == 'This Month' || this.selectedFilters == 'Last Month') {
            users.push(this.weeks[res[i]._id.month - 1].monthName+'-'+res[i]._id.year);
          } else {
            users.push(this.months[res[i]._id.month - 1].monthName+'-'+res[i]._id.year);
          }

        }
        console.log(this.newUserArray);

        let userArray = this.newUserArray;


        this.dataNewUser = {
          labels: users,
          datasets: [
            { label: 'New User Added',
             data: userArray,
             borderColor: '#ff6d4d',
             backgroundColor: ["#ff6d4d"],
             pointBackgroundColor: ["#ff6d4d"],
              tension: 1
             }
          ],
        };
        this.chart.ngOnChanges({});

        this.spinner.hide();
      });

  }

  public openPDF(): void {
    this.spinner.show();
    let DATA: any = document.getElementById('content');
    var pdf: any = document.getElementById("btn");
    var btn: any = document.getElementById("btnSeacrh");
    pdf.style.display = "none";
    btn.style.display = "none";
    html2canvas(DATA).then((canvas) => {
      let fileWidth = 208;
      let fileHeight = (canvas.height * fileWidth) / canvas.width ;
      const FILEURI = canvas.toDataURL('image/png');
      let PDF = new jsPDF('p', 'mm', 'a4');
      let position = 0;
      PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight);
      this.spinner.hide();
      PDF.save('Voece_export.pdf');
    });
    pdf.style.display = "block";
    btn.style.display = "block";
  }

  loadData() {
    this.spinner.show();
    // let currentUser: any = sessionStorage.getItem('currentUser');
    // let token: any = JSON.parse(currentUser).token;

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.token,
    });
    let options = { headers: headers };
    this.http
      .get('admin/getUsersTotalStatus', options)
      .subscribe((res: any) => {

        this.allData = res
        this.activeUserNumber = this.allData.userCount.active;
        this.pieChartActive.dataTable[1][1] = this.allData.userCount.active;
        this.inActiveUserNumber = this.allData.userCount.inactive;
        this.pieChartActive.dataTable[2][1] = this.allData.userCount.inactive;
        this.pieChartActive.component?.draw();

        this.spinner.hide();
      });

    this.exportList = [
      { id: 1, Company: 'Alfreds Futterkiste', Contact: 'Maria Anders', Country: 'Germany' },
      { id: 2, Company: 'Centro comercial Moctezuma', Contact: 'Francisco Chang', Country: 'Mexico' },
      { id: 3, Company: 'Ernst Handel', Contact: 'Roland Mendel', Country: 'Austria' },
      { id: 4, Company: 'Island Trading', Contact: 'Helen Bennett', Country: 'UK' },
      { id: 5, Company: 'Laughing Bacchus Winecellars', Contact: 'Yoshi Tannamuri', Country: 'Canada' },
      { id: 6, Company: 'Magazzini Alimentari Riuniti', Contact: 'Giovanni Rovelli', Country: 'Italy' },
    ]
  }

  ngOnChanges() {

  }

  // exportExcel() {
  //   this.spinner.show();
  //   const worksheet = XLSX.utils.json_to_sheet(this.allData.users);
  //   const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
  //   const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  //   this.spinner.hide();
  //   this.saveAsExcelFile(excelBuffer, "Voece");
  // }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  sessionDetails: any = {};

  //line chart

  chartOptions: ChartOptions = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'Revenue',
      },
    },
  };

  salesData: ChartData<'line'> = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May'],
    datasets: [
      { label: 'Revenue Generated',
       data: [1000, 1200, 1050, 2000, 500],
       borderColor: '#FFC527',
       backgroundColor: ["#ffc527"],
       pointBackgroundColor: ["#ffc527"],
       tension: 0.5 }
    ],
  };

  //end

  //pie chart for Active user

  pieChartActive: GoogleChartInterface = {
    chartType: GoogleChartType.PieChart,
    dataTable: [
      ['Activity', 'User'],
      ['Active', this.activeUserNumber],
      ['Inactive', this.inActiveUserNumber],
    ],
    options: {
      allowHtml: true,
      allowCollapse: true,
      update: true,
      colors: ["#ff6d4d", "#ffc527"],
      legend: { position: 'top', alignment: 'center' }
     },
  };
  //end

    //pie chart for Decible user

    pieChart: GoogleChartInterface = {
      chartType: GoogleChartType.PieChart,
      dataTable: [
        ['Student', 'Marks'],
        ['12-6am', 80],
        ['6am-12', 90],
        ['12-6pm', 88],
        ['6pm-12', 95],
      ],
      options: {
        allowHtml: true,
        allowCollapse: true,
        colors: ["#ff6d4d", "#ffc527", "#ff6d4d", "#ffc527"],
        legend: { position: 'top', alignment: 'center' }
       },
    };
    //end

  listScheduledEvents(): void {
    let access_token: any = '';

    access_token = sessionStorage.getItem('accessToken');
    //console.log(access_token);
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + access_token,
    };
  }
  // getAccessToken(): void {
  //   const code = 'AZr3N-It5kyxQx5ObMNUngXqydiDuhWh9KByv-fcgL0';

  //   const headers = {
  //     'Content-Type': 'application/x-www-form-urlencoded',
  //   };

  //   axios
  //     .post(
  //       `https://auth.calendly.com/oauth/token?grant_type=authorization_code&client_id=p8yQgis3aF8Z_M3zvDwRdTim8Ad3ms3sfFJ0T4XLncY&client_secret=xl5doUfUAbvlkZDA7a1cgH4IniR8YRiurenlo0SzT94&code=${code}&redirect_uri=https://dev-voece-admin.ajatus.in/` +
  //         'data',
  //       {
  //         headers: headers,
  //       }
  //     )
  //     .then((data) => {

  //       localStorage.setItem('accessToken', data.data.access_token);

  //       const strs = data.data.organization.split('/');
  //       const id = strs.at(-1);
  //       //console.log('org id', id);
  //       localStorage.setItem('orgId', id);
  //     })
  //     .catch((err) => {
  //       //console.log(err);
  //     });
  // }

  // createSession(): void {
  //   let access_token: any = '';
  //   access_token = localStorage.getItem('accessToken');
  //  // console.log(access_token);

  //   const headers: any = {
  //     'Content-Type': 'application/x-www-form-urlencoded',
  //     Authorization: 'Bearer ' + access_token,
  //   };

  //   this.http
  //     .get(`https://calendly.com/api/v1/users/me/event_types`, {
  //       headers: headers,
  //     })
  //     .subscribe(
  //       (data: any) => {
  //         this.createSessionLink(data);
  //       },
  //       (error: any) => {
  //         //console.log(error);
  //       }
  //     );
  // }

  // createSessionLink(data: any): any {
  //   let access_token: any = '';

  //   access_token = localStorage.getItem('accessToken');
  //   //console.log(access_token);

  //   const headers: any = {
  //     'Content-Type': 'application/json',
  //     Authorization: 'Bearer ' + access_token,
  //   };

  //   //console.log(data);

  //   let object: any = {
  //     max_event_count: 1,
  //     owner: `https://api.calendly.com/event_types/${data.data.data[0].id}`,
  //     owner_type: 'EventType',
  //   };

  //   this.http
  //     .post(`https://api.calendly.com/scheduling_links`, object, {
  //       headers: headers,
  //     })
  //     .subscribe(
  //       (data: any) => {
  //         //console.log(data);
  //         Swal.fire('success', 'Session created successfully', 'success');
  //       },
  //       (error: any) => {
  //         //console.log(error);
  //         Swal.fire('Error!', 'Status Updation failed', 'error');
  //       }
  //     );
  // }

  getEvents(): void {
    this.spinner.show();
    let access_token: any = '';
    access_token = sessionStorage.getItem('accessToken');
    //console.log(access_token);
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + access_token,
    };

    axios
      .get(
        `https://api.calendly.com/scheduled_events?organization=https://api.calendly.com/organizations/8d8b3c54-9831-463e-b28e-02ec1d3b168f&count=20`,
        {
          headers: headers,
        }
      )
      .then((data: any) => {


        this.sessionDetails = data.data;
        this.spinner.hide();

      })
      .catch((err) => {
        //console.log(err);
        this.spinner.hide();
        sessionStorage.setItem('acesstokenApiCall','admin-dashboard');
        this.calendlyAccessToken.callCalendlyToken();

        setTimeout(() => {
          this.getEvents();
       }, 3000);

      });
  }

  public searchByFilters(){
    //console.log(this.selectedFilters);

    if (this.selectedFilters == 'customDate') {
      //console.log(this.convert(this.startDate));
      //console.log(this.convert(this.endDate));
    }

  }
  public reset(){
    this.selectedFilters = undefined;
  }

  public exportInPdf() {
    //console.log('Export in Pdf');
  };

  public exportInExcel() {
    //console.log('Export in excel');
  }

  // public userDecibles() {
  //   this.router.navigate(['dashboard/user-decibles']);
  //   sessionStorage.setItem('path','dashboard/user-decibles');
  // }

  public showRevenue() {
    this.router.navigate(['dashboard/show-revenue']);
    sessionStorage.setItem('path','dashboard/show-revenue');
  }

  public convert(str: any) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [day,mnth,date.getFullYear()].join("/");
  }

  public indianRupeeFormat(val: number) {
    return Number(val).toLocaleString('en-US');
  }
  public newUserDetail() {
    this.router.navigate(['dashboard/show-new-user']);
    sessionStorage.setItem('path','dashboard/show-new-user');
  }
}
