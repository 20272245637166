<div>
    <!-- <div class="nav-header">
      <a routerLink="/dashboard/home" class="brand-logo">
        <img class="logo-abbr" src="../../assets/images/logo.png" alt="" />
      </a>
  
      <div class="nav-control">
        <div class="hamburger">
          <span class="line"></span><span class="line"></span
          ><span class="line"></span>
        </div>
      </div>
    </div> -->
  
    <div class="content-body">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                    <div class="col" style="margin-top: 3%">
                        <button
                          type="button" style="float: right"
                          (click)="addCorporates('dashboard/corporates/add')"
                          class="btn btn-primary btn-sm mr-1"
                          >
                          Add Corporate
                        </button>
                    </div>
                </div>
                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border dataTable hover" style="width: 100%; text-align: center;">
                  <thead>
                    <tr>
                      <th>Sl No.</th>
                      <th>Company Name</th>
                      <th>Website</th>
                      <th>Country</th>
                      <!-- <th>Phone</th> -->
                      <th>Corporate Code</th>
                      <!-- <th>Details</th> -->
                      <th>Edit</th>
                      <!-- <th>Action</th> -->
                      <!-- <th>Sessions</th> -->
                      <th>Remove</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let data of corporatedata.data; let i = index">
                      <td style="font-size: 12.5px;">{{i+1}}</td>
                      <td style="font-size: 12.5px;">{{data.companyName}}</td>
                      <td style="font-size: 12.5px;">{{data.website}}</td>
                      <td style="font-size: 12.5px;">{{data.country}}</td>
                      <td style="font-size: 12.5px;">{{data.CorporateCode}}</td>
                      <!-- <td>{{ item.Phone }}</td> -->
                      <!-- <td>
                        <a
                          (click)="goToViewTherapist('dashboard/admin-therapist-view',item._id)"
                          [routerLink]="['/dashboard/admin-therapist-view', item._id]"
                          class="sessionmodallink"
                          ><i class="fa fa-eye" aria-hidden="true"></i></a
                        >
                      </td> -->
  
                      <td>
                        <div class="d-flex">
                          <a style="margin-left: 16px;"
                            (click)="goToViewCorporate('/dashboard/update-corporate/',data._id)"
                            [routerLink]="['/dashboard/update-corporate/', data._id]"
                            class="btn btn-primary shadow btn-xs sharp mr-1"
                            ><i class="fa fa-pencil"></i
                          ></a>
                          
                        </div>
                      </td>
                      <td>
                        <a
                          type="button"
                          *ngIf="data.isActive == false"
                          class="sessionmodallink"
                          >
                          <i class="fa fa-play-circle-o" aria-hidden="true" (click)="activeInactive(data._id, data.isActive)"></i>
                        </a>
                        <a
                          type="button"
                          *ngIf="data.isActive == true"
                           class="sessionmodallink"
                          > 
                          <i class="fa fa-pause" aria-hidden="true"  (click)="activeInactive(data._id, data.isActive)" ></i>
                        </a>
                      </td>
                      <!-- <td>
                        <a class="sessionmodallink"
                        (click)="allSessions(item.FullName,item._id)"
                        type="button"
                        ><i class="fa fa-eye" aria-hidden="true"></i></a
                        >
                      </td> -->
                      <!-- <td>
                        <div class="d-flex">
                          <a style="margin-left: 36px;"
                            type="button"
                            (click)="deleteCorporate(data._id)"
                            class="btn btn-primary shadow btn-xs sharp mr-1"
                            ><i class="fa fa-trash"></i
                          ></a>
                          
                        </div> -->
                      <!-- </td> -->
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ngx-spinner color="#fff" type="ball-scale-multiple"></ngx-spinner>
  </div>