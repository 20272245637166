import { HttpService } from './../shared/service/http.service';
import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { CommonService } from '../shared/service/common.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
})
export class ForgotPasswordComponent implements OnInit {
  userDetails: any = {};
  public loading: boolean = false;

  constructor(private http: HttpService, private router: Router, private commServ: CommonService) {}

  ngOnInit(): void {

  }

  onSubmit(): void {
   // console.log(this.userDetails);
    this.loading = true;
    this.http.post('user/email-send', this.userDetails).subscribe(
      (res: any) => {
        //console.log(res);
        if (res) {
          this.loading = false;
          Swal.fire({  
            title: 'Success!',  
            text: 'A verification code has been sent to the registered email address.',  
            icon: 'success',  
            confirmButtonText: 'OK',  
          }).then((result) => {  
            this.commServ.forgotPassEmail.next(this.userDetails.Email)
            this.router.navigate(['/forgot-password-otp']);  
          })
        }
      },
      (error: any) => {
        this.loading = false;
        //console.log(error);
        Swal.fire('Error!', error?.statusText, 'error');
      }
    );
    // } else {
    //   Swal.fire('Error', 'Enter Valid Email', 'error');
    // }
  }
}
