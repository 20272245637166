import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  private endPoint: string = environment.apiEndPoint;

  constructor(private http: HttpClient) {}

  get(url: string, options?: {}) {
    return this.http.get(this.endPoint + url, options);
  }

  post(url: string, body: {}, options?: {}) {
    //console.log(this.endPoint + url);
    return this.http.post(this.endPoint + url, body, options);
  }

  put(url: string, body: {}, options?: {}) {
    //console.log(this.endPoint + url);
    return this.http.post(this.endPoint + url, body, options);
  }

  delete(url: string) {
    return this.http.delete(this.endPoint + url);
  }

  // getCurrencyValue(url: String,options?: {}) {
  //   return this.http.get('https://api.apilayer.com/fixer/'+url, options)
  // }

  getCurrencyValue(url: String) {
    return this.http.get('https://v6.exchangerate-api.com/v6/b0d18b38439f79d58c2ff4d9/'+url)
  }

}
