<div>
    <div class="nav-header">
      <a routerLink="/dashboard/home" class="brand-logo">
        <img class="logo-abbr" src="../../assets/images/logo.png" alt="" />
      </a>
  
      <div class="nav-control">
        <div class="hamburger">
          <span class="line"></span><span class="line"></span
          ><span class="line"></span>
        </div>
      </div>
    </div>
  
    <div class="content-body">
      <div class="container-fluid">
        <!-- Add Order -->
  
        <!-- row -->
  
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body" style="overflow-x: scroll;">
  
                <!-- <div class="col-12" style="text-align: right;">
                  <button type="button"  (click)="addUser('dashboard/add-user')" class="btn btn-primary" > Add User </button>
                </div> -->
          
                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border dataTable hover" style="width: 100%;">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Full Name</th>
                      <th>Email</th>
                      <th>Avg Volume</th>
                      <th>Avg Pitch</th>
                      <th>Avg Intensity</th>
                      <th>Avg Sp. Rate</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of userDetails; let i = index">
                      <td>{{ i + 1 }}</td>
                      <td>{{ item.Name }}</td>
                      <td>{{ item.Email }}</td>
                      <td>{{ item.Volume.toFixed(2) }}</td>
                      <td>{{ item.pitch.toFixed(2) }}</td>
                      <td>{{ item.intensity.toFixed(2) }}</td>
                      <td>{{ item.speechRate.toFixed(2) }}</td>
                      <!-- <td style="text-align: center;">Free</td> -->
                      <td>
                        <a
                          type="button"
                          *ngIf="item.Status == false"
                          (click)="activeInactive(item.isActive,item._id)"
                          class="sessionmodallink"
                          >Inactive</a
                        >
  
                        <a
                          type="button"
                          *ngIf="item.Status == true"
                          (click)="activeInactive(item.isActive,item._id)"
                          class="sessionmodallink"
                          >Active</a
                        >
  
                      </td>
                      <td>
                        <a
                          (click)="viewUser('dashboard/admin-user-view',item.userLoginId)"
                          [routerLink]="['/dashboard/admin-user-view', item.userLoginId]"
                          class="btn btn-primary shadow btn-xs sharp mr-1"><i class="fa fa-eye"></i>
                        </a>
                        <a
                            (click)="viewUser('dashboard/update-user',item._id)"
                            [routerLink]="['/dashboard/update-user', item._id]"
                            class="btn btn-primary shadow btn-xs sharp mr-1"
                            ><i class="fa fa-pencil"></i>
                        </a>
                        <a
                          (click)="deleteUser(item._id)"
                          class="btn btn-primary shadow btn-xs sharp mr-1">
                          <i class="fa fa-trash"></i>
                        </a>
  
                      </td>
                    </tr>
                  </tbody>
                </table>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ngx-spinner color="#fff" type="ball-scale-multiple"></ngx-spinner>
  </div>
  