import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { CalendlyAccesTokenService } from './shared/service/calendly-acces-token.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  
  constructor(
    private router: Router,
    private callCalendly: CalendlyAccesTokenService
    ) {}
  
  title = 'VOECE';
  titleName: any = sessionStorage.getItem('dashboardName');
  pathName: any = ''
  image: any;
  private activatedRoute: ActivatedRoute;
  calendlyCode: any;
  userRole: any;
  
  ngOnInit(): void {

    this.userRole = sessionStorage.getItem('role');
    this.image = sessionStorage.getItem('profile_image');

    // if(sessionStorage.getItem('currentUser') == null) {
    //   console.log(sessionStorage.getItem('currentUser'));
    //   this.router.navigate(['']);
    // }

    //this.callCalendly.callCalendlyToken();

  }

  //for logout
  // logout(): void {
  //   Swal.fire({
  //     title: 'Are you sure?',
  //     customClass: 'swal-height',
  //     //icon: 'warning',
  //     // showDenyButton: true,
  //     showCancelButton: true,
  //     confirmButtonText: 'Yes',
  //   }).then((result) => {
  //     /* Read more about isConfirmed, isDenied below */
  //     if (result.isConfirmed) {
  //       window.location.reload();
  //       //console.log('logout');
  //       window.sessionStorage.clear();
  //       sessionStorage.clear();
  //       this.router.navigate(['']);
  //     }
  //   });
  // }
}
