import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CurrencyCode } from 'src/app/shared/models/model';
import { currencyCodes } from 'src/app/shared/modules/country-by-currency-code';
import { countries } from 'src/app/shared/modules/country-data-store';
import { HttpService } from 'src/app/shared/service/http.service';
import Swal from 'sweetalert2';

interface SelectedCountry {
  code:string;
  name:string;
}
@Component({
  selector: 'app-add-session-plan',
  templateUrl: './add-session-plan.component.html',
  styleUrls: ['./add-session-plan.component.css']
})
export class AddSessionPlanComponent implements OnInit {
  public sessionDetails: any = {};
  public countries: any = countries;
  public isoCodes: CurrencyCode[] = currencyCodes;
  public selectedCountry: SelectedCountry= {
    code: '',
    name: ''
  }
  public isoCode: any = ""
  public isValid: Boolean = true
  constructor(
    private http: HttpService, 
    private router: Router,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
  }

  selectCountry(){
    console.log(this.selectedCountry);
    if(this.selectedCountry == null || this.selectedCountry.name == ""){
      this.isoCode = "";
      this.isValid = false;
      return
    }
    var country_currency = this.isoCodes.find((res)=>{
      // console.log(res.country.trim().toLowerCase() +" - "+ this.selectedCountry.name.trim().toLowerCase());
      
      return res.country.trim().toLowerCase() == this.selectedCountry.name.trim().toLowerCase()
    })
    this.isValid = true
    this.sessionDetails.Country_code = this.selectedCountry.code
    this.sessionDetails.iso_code = country_currency?.currency_code
    console.log(country_currency);
    this.isoCode = country_currency?.currency_code
  }

  onSubmit(){
    if(this.isValid){
      console.log(this.sessionDetails);
      this.spinner.show()
      let currentUser: any = sessionStorage.getItem('currentUser');
      let token: any = JSON.parse(currentUser).token;
      //console.log(token);

      let headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      });
      let options = { headers: headers };
      this.http.post('therapist/AddTherapistPlanInStripe', this.sessionDetails, options).subscribe((res)=>{
        console.log(res);
        
        Swal.fire('Success', 'Plan Created Successfully', 'success');
        this.spinner.hide();
        this.router.navigate(['/dashboard/view-session-plans']);
        sessionStorage.setItem('path','/dashboard/view-session-plans');
      },
      (error: any) => {
        this.spinner.hide();
       // console.log(error);
        Swal.fire('error', error?.statusText, 'error');
      })
    }else{
      alert("Fill all the details")
    }
  }
  public back() {
    this.router.navigate(['dashboard/view-session-plans']);
    sessionStorage.setItem('path','dashboard/view-session-plans');
  }

}
