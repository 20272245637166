import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { NgModule, Component } from '@angular/core';
import { AuthGuard } from './shared/service/auth-guard.service';

import { LoginComponent } from './login/login.component';
import { Routes, RouterModule } from '@angular/router';
import { StripePaymentStatusComponent } from './stripe-payment-status/stripe-payment-status.component';
import { CalendlyErrorPageComponent } from './calendly-error-page/calendly-error-page.component';
import { ForgotPasswordOtpComponent } from './forgot-password-otp/forgot-password-otp.component';
import { CorporateLoginComponent } from './corporate-login/corporate-login.component';
import { CorporatePasswordChangeComponent } from './corporate-password-change/corporate-password-change.component';


const routes: Routes = [
  { path: '', component: LoginComponent},
  { path: 'login',redirectTo: ''},
  { path: 'corporate-login', component: CorporateLoginComponent},
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'forgot-password-otp', component: ForgotPasswordOtpComponent },
  { path: 'stripe-payment-status', component: StripePaymentStatusComponent},
  { path: 'calendly-error-page', component: CalendlyErrorPageComponent},
  { path: 'change-password-request', component: CorporatePasswordChangeComponent},
  { path: 'dashboard', loadChildren: () => import(`./master/master.module`).then(m => m.MasterModule),canActivate: [AuthGuard]  },
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
