import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { HttpService } from 'src/app/shared/service/http.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-find-allmessage',
  templateUrl: './find-allmessage.component.html',
  styleUrls: ['./find-allmessage.component.css']
})
export class FindAllmessageComponent implements OnInit {
  constructor(
    private http: HttpService, 
    private router: Router,
    private spinner: NgxSpinnerService) {}

  messageDetails: any = [];
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  userImage: any;
  // baseUrl: any = environment.imageUrl;

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
    };
    this.getAllMessages();
    //this.getAllMessages();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  getAllMessages(): void {
    this.spinner.show();
    let currentUser: any = sessionStorage.getItem('currentUser');
    let token: any = JSON.parse(currentUser).token;
    // console.log(token);

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    });
    let options = { headers: headers };
    this.http.get('message/findAllMessages', options).subscribe((res: any) => {
      this.messageDetails = res;
      this.dtTrigger.next('')
      this.spinner.hide();

    });

    let user_Id: any = JSON.parse(currentUser).userId;
  }

}