import { HttpService } from '../../shared/service/http.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import Swal from 'sweetalert2';
import { environment } from '../../environments/environment';
import axios from 'axios';
import { countries } from '../../shared/modules/country-data-store';
import { Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataTableDirective } from 'angular-datatables';
import { CalendlyAccesTokenService } from 'src/app/shared/service/calendly-acces-token.service';

@Component({
  selector: 'app-therapist',
  templateUrl: './therapist.component.html',
  styleUrls: ['./therapist.component.css'],
})
export class TherapistComponent implements OnInit {

  constructor(
     private http: HttpService,
     private router: Router,
     private SpinnerService: NgxSpinnerService,
     private callendlyAccessTokenCall: CalendlyAccesTokenService) {}

  therapistDetails: any = [];
  userDetails: any;
  sessionDetails: any;
  public countries: any = countries;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  userImage: any;
  baseUrl: any = environment.imageUrl;
  selectedRegion: any;
  therapistTypeClass:any = "slp";
  @ViewChild(DataTableDirective, { static: true }) dtElement: DataTableDirective;
    dtInstance: Promise<DataTables.Api>;
  datatableCount: number = 0;
  apiBody = {};

  ngOnInit(): void {

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
    };
    
    this.getTherapists();
    //this.getUsers();

    this.datatableCount++;
   
  }

  therapistType(tType:string){
    console.log("clicked");
    this.therapistTypeClass = tType
    this.therapistDetails = []
    if (this.datatableCount > 0) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.clear();
        dtInstance.destroy();
      });
    }
    if(tType == "slp"){
      this.getTherapists()
    }else{
      this.getCoach()
    }
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  getCoach():void {
    this.SpinnerService.show(); 
    let currentUser: any = sessionStorage.getItem('currentUser');
    let token: any = JSON.parse(currentUser).token;
    // // console.log(token);

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    });
    let options = { headers: headers };
    this.http
      .get('therapist/getAllCoachAndPrice', options)
      .subscribe((res: any) => {
        this.therapistDetails = res.reverse();
        console.log(res);
        this.dtTrigger.next('')
        this.datatableCount++
        this.getEvents(res);
        this.listScheduledEvents(res);
        this.SpinnerService.hide();
      });

    let userId: any = JSON.parse(currentUser).userId;

    // this.http.get('user/getUserById/' + userId).subscribe((res: any) => {
    //   // // console.log(res);
    //   this.userImage = res.profileImageUrl;
    // });
    this.SpinnerService.hide();
  }

  getTherapists(): void {
    this.SpinnerService.show(); 
    let currentUser: any = sessionStorage.getItem('currentUser');
    let token: any = JSON.parse(currentUser).token;
    // // console.log(token);

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    });
    let options = { headers: headers };
    this.http
      .get('therapist/getAllTherapists', options)
      .subscribe((res: any) => {
        // // console.log(res);
        this.therapistDetails = res.reverse();
        //console.log(res);
        this.dtTrigger.next('')
        this.datatableCount++
        this.getEvents(res);
        this.listScheduledEvents(res);
        this.SpinnerService.hide();
      });

    let userId: any = JSON.parse(currentUser).userId;

    // this.http.get('user/getUserById/' + userId).subscribe((res: any) => {
    //   // // console.log(res);
    //   this.userImage = res.profileImageUrl;
    // });
    this.SpinnerService.hide();
  }

  getUsers(): void {
    this.SpinnerService.show(); 
    let currentUser: any = sessionStorage.getItem('currentUser');
    let token: any = JSON.parse(currentUser).token;
    // // console.log(token);

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    });
    let options = { headers: headers };
    this.http.get('user/getAllUsers', options).subscribe((res: any) => {
      console.log(res);
      this.userDetails = res.reverse();
     
      //this.getEvents(res);
      //this.listScheduledEventsUser(res);
    });

    let userId: any = JSON.parse(currentUser).userId;

    // this.http.get('user/getUserById/' + userId).subscribe((res: any) => {
    //   // // console.log(res);
    //   this.userImage = res.profileImageUrl;
    // });

    this.SpinnerService.show(); 
  }

  search(): void {
    if (this.datatableCount > 0) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.clear();
        dtInstance.destroy();
      });
    }

    let currentUser: any = sessionStorage.getItem('currentUser');
    let token: any = JSON.parse(currentUser).token;
   
    this.SpinnerService.show();
    
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    });
    let options = { headers: headers };
    this.http
      .post(
        'therapist/getTherapistByRegion',
        {
          region: this.selectedRegion,
        },
        options
      )
      .subscribe((res: any) => {
         //console.log(res);
        // // console.log(res);
        this.therapistDetails = res.reverse();
        this.dtTrigger.next('')
        this.SpinnerService.hide();
      });
  }

  reset(): void {
    // this.getTherapists();
    // this.selectedRegion = '';
    if (this.datatableCount > 0) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.clear();
        dtInstance.destroy();
      });
    }
    this.getTherapists();
    this.selectedRegion = '';
   
  }

  onCountrySelected(e: any): void {
  }

  getEvents(therapist: any): void {
    //console.log(therapist);
    let access_token: any = '';

    access_token = sessionStorage.getItem('accessToken');
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + access_token,
    };

    axios
      .get(
        `https://api.calendly.com/event_types?active=true&organization=https%3A%2F%2Fapi.calendly.com%2Forganizations%2F8d8b3c54-9831-463e-b28e-02ec1d3b168f&count=100`,
        {
          headers: headers,
        }
      )
      .then((data: any) => {
        
        let rescheduling_url: any = '';

        this.sessionDetails = data.data;
        
        data.data.collection.map((item: any) => {
        //console.log(this.sessionDetails);
        
          therapist.map((ther_item: any) => {
            if (item.profile.name === ther_item.FullName) {
              let url: any = '';
              let freeUrl: any = ''
              let objectToUpdate = {}
              let result = item.scheduling_url.includes("free");
              if(result) {
                freeUrl = item.scheduling_url
                objectToUpdate = {
                  // SchedulingUrl: url,
                  FreeSchedulingUrl: freeUrl,
                  Status: 'Accepted',
                }
              } else {
                url = item.scheduling_url
                objectToUpdate = {
                  SchedulingUrl: url,
                  Status: 'Accepted',
                }
              }
              
              this.http
                .post(`therapist/updateSchedulingLink/${ther_item._id}`, objectToUpdate )
                .subscribe(
                  (data: any) => {
                  },
                  (error: any) => {
                  }
                );
            }
          });
        });
      })
      .catch((err: any) => {
        //console.log(err.response.data.message)
        if (err.response.data.message == 'The access token is invalid') {
        
        this.callendlyAccessTokenCall.callCalendlyToken();
        if (this.datatableCount > 0) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.clear();
            dtInstance.destroy();
          });
        }
          setTimeout(() => {
            this.getTherapists();
         }, 3000);
        
       }
      });
  }

  listScheduledEvents(therapist: any): void {
    let access_token: any = '';

    access_token = sessionStorage.getItem('accessToken');
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + access_token,
    };

    axios
      .get(
        `https://api.calendly.com/scheduled_events?organization=https%3A%2F%2Fapi.calendly.com%2Forganizations%2F8d8b3c54-9831-463e-b28e-02ec1d3b168f&count=20`,
        {
          headers: headers,
        }
      )
      .then((data: any) => {
        //this.get_and_update_session(data, therapist);

        let url: any = '';
        let rescheduling_url: any = '';
        let cancel_url: any = '';
        let status: any = '';
        let timezone: any = '';

        this.sessionDetails = data.data;

        data.data.collection.map((item: any) => {
          let event_uri: any = item.uri.split('/');
          let uri: any = event_uri[4];

          axios
            .get(
              `https://api.calendly.com/scheduled_events/${uri}/invitees?count=50`,
              {
                headers: headers,
              }
            )
            .then((res: any) => {
              // // console.log(res.data.collection[0]);

              therapist.map((ther_item: any) => {
                if (res.data.collection[0].email === ther_item.Email) {
                  url = res.data.collection[0].email;
                  // // console.log('therapist_id', ther_item._id);
                  rescheduling_url = res.data.collection[0].reschedule_url;
                  cancel_url = res.data.collection[0].cancel_url;
                  status = res.data.collection[0].status;
                  timezone = res.data.collection[0].timezone;

                  this.http
                    .get(`session/getSessionByTherapist/${ther_item._id}`)
                    .subscribe(
                      (data: any) => {
                        // // console.log(data);

                        if (data.length > 0) {
                          this.http
                            .post(`session/update/${data[0]._id}`, {
                              TherapistID: ther_item._id,
                              TherapistName: ther_item.TherapistName,
                              start_time: item.start_time,
                              end_time: item.end_time,
                              Status: item.status,
                            })
                            .subscribe(
                              (data: any) => {
                                // // console.log(data);
                              },
                              (error: any) => {
                                // // console.log(error);
                              }
                            );
                        } else {
                          this.http
                            .post(`session/create`, {
                              start_time: item.start_time,
                              end_time: item.end_time,
                              TherapistID: ther_item._id,
                              TherapistName: ther_item.UserName,
                              Status: 'active',
                            })
                            .subscribe(
                              (data: any) => {
                                // // console.log(data);
                              },
                              (error: any) => {
                                // // console.log(error);
                              }
                            );
                        }
                      },
                      (error: any) => {
                        // // console.log(error);
                      }
                    );

                  // // console.log(url, rescheduling_url);

                  this.http
                    .post(`user/updateSchedulingLink/${ther_item._id}`, {
                      ReSchedulingUrl: rescheduling_url,
                      CancelUrl: cancel_url,
                      Status: status,
                      TimeZone: timezone,
                    })
                    .subscribe(
                      (data: any) => {
                        // // console.log(data);
                      },
                      (error: any) => {
                        // // console.log(error);
                      }
                    );
                }
              });
            })
            .catch((err: any) => {
              // // console.log(err);
            });
        });
      })
      .catch((err: any) => {
        // // console.log(err);
        if (err.response.data.message == 'The access token is invalid') {
        
          this.callendlyAccessTokenCall.callCalendlyToken();
          if (this.datatableCount > 0) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              dtInstance.clear();
              dtInstance.destroy();
            });
          }
            setTimeout(() => {
              this.getTherapists();
           }, 3000);
          
         }
      });
  }

  // listScheduledEventsUser(therapist: any): void {
  //   // // console.log(therapist);
  //   let access_token: any = '';

  //   access_token = sessionStorage.getItem('accessToken');
  //   // // console.log(access_token);
  //   const headers = {
  //     'Content-Type': 'application/json',
  //     Authorization: 'Bearer ' + access_token,
  //   };

  //   axios
  //     .get(
  //       `https://api.calendly.com/scheduled_events?organization=https%3A%2F%2Fapi.calendly.com%2Forganizations%2F8d8b3c54-9831-463e-b28e-02ec1d3b168f&count=20`,
  //       {
  //         headers: headers,
  //       }
  //     )
  //     .then((data: any) => {
  //       //this.get_and_update_session(data, therapist);

  //       let url: any = '';
  //       let rescheduling_url: any = '';
  //       let cancel_url: any = '';
  //       let status: any = '';
  //       let timezone: any = '';

  //       this.sessionDetails = data.data;

  //       data.data.collection.map((item: any) => {
  //         // // console.log(item.uri);
  //         let event_uri: any = item.uri.split('/');
  //         // // console.log(event_uri[4]);
  //         let uri: any = event_uri[4];
  //         // // console.log('perfect', item);

  //         axios
  //           .get(
  //             `https://api.calendly.com/scheduled_events/${uri}/invitees?count=50`,
  //             {
  //               headers: headers,
  //             }
  //           )
  //           .then((res: any) => {
  //             // // console.log(res.data.collection[0]);

  //             therapist.map((ther_item: any) => {
  //               if (res.data.collection[0].email === ther_item.Email) {
  //                 url = res.data.collection[0].email;
  //                 // // console.log('therapist_id', ther_item._id);
  //                 rescheduling_url = res.data.collection[0].reschedule_url;
  //                 cancel_url = res.data.collection[0].cancel_url;
  //                 status = res.data.collection[0].status;
  //                 timezone = res.data.collection[0].timezone;

  //                 this.http
  //                   .get(`session/getSessionByUser/${ther_item._id}`)
  //                   .subscribe(
  //                     (data: any) => {
  //                       // // console.log(data);

  //                       if (data.length > 0) {
  //                         this.http
  //                           .post(`session/update/${data[0]._id}`, {
  //                             UserId: ther_item._id,
  //                             UserName: ther_item.UserName,
  //                             start_time: item.start_time,
  //                             end_time: item.end_time,
  //                             Status: item.status,
  //                             TherapistName: '',
  //                             TherapistId: '',
  //                           })
  //                           .subscribe(
  //                             (data: any) => {
  //                               // // console.log(data);
  //                             },
  //                             (error: any) => {
  //                               // // console.log(error);
  //                             }
  //                           );
  //                       } else {
  //                         this.http
  //                           .post(`session/create`, {
  //                             start_time: item.start_time,
  //                             end_time: item.end_time,
  //                             UserId: ther_item._id,
  //                             UserName: ther_item.UserName,
  //                             Status: 'active',
  //                           })
  //                           .subscribe(
  //                             (data: any) => {
  //                               // // console.log(data);
  //                             },
  //                             (error: any) => {
  //                               // // console.log(error);
  //                             }
  //                           );
  //                       }
  //                     },
  //                     (error: any) => {
  //                       // // console.log(error);
  //                     }
  //                   );

  //                 // // console.log(url, rescheduling_url);

  //                 this.http
  //                   .post(`user/updateSchedulingLink/${ther_item._id}`, {
  //                     ReSchedulingUrl: rescheduling_url,
  //                     CancelUrl: cancel_url,
  //                     Status: status,
  //                     TimeZone: timezone,
  //                   })
  //                   .subscribe(
  //                     (data: any) => {
  //                       // // console.log(data);
  //                     },
  //                     (error: any) => {
  //                       // // console.log(error);
  //                     }
  //                   );
  //               }
  //             });
  //           })
  //           .catch((err: any) => {
  //             // // console.log(err);
  //           });
  //       });
  //     })
  //     .catch((err: any) => {
  //       // // console.log(err);
  //     });
  // }

  public addTherapist(path: any) {
    console.log(path);
    
    this.router.navigate([path]);
    sessionStorage.setItem('path',path);

    this.http
      .post(`zoom/add-token?grant_type=account_credentials&account_id=nRg_2fvqTLqsFfGjDmcZmw`, {
        
      })
      .subscribe((res: any) => {
        
        // console.log(res)
        sessionStorage.setItem('ZoomToken',res.AccessToken);
      },
        (error: any) => {
          // // console.log(error);
        }
      );
  }

  public goToViewTherapist(path: any,id: any) {
    sessionStorage.setItem('path',path+'/'+id);
  }

  deleteTherapist(id: any) : void {
    Swal.fire({
      title: 'Are you sure?',
      //icon: 'warning',
      // showDenyButton: true,
      width: '60vh',
      showCancelButton: true,
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        this.SpinnerService.show();
        this.http.delete(`therapist/delete/${id}`).subscribe(
          (res: any) => {
           // console.log(res);
            this.SpinnerService.hide();
            // this.ngOnInit();
            window.location.reload();
          },
          (error: any) => {
            //console.log(error);
            Swal.fire('error', error?.statusText, 'error');
            this.SpinnerService.hide();
          }
        );
      }
    });
  }

  public activeInactive(status: any,id: string): void {
    // console.log(status);
     let popUpBtnText: any;
 
     if (status == true) {
       popUpBtnText = 'Yes, Inactive!'
       this.apiBody = {
         "userId": id,
         "isActive": false
       }
     } else {
       popUpBtnText = 'Yes, Activate!'
       this.apiBody = {
         "userId": id,
         "isActive": true
       }
     }
     
     let currentUser: any = sessionStorage.getItem('currentUser');
     let token: any = JSON.parse(currentUser).token;
     //console.log(token);
 
 
     let headers = new HttpHeaders({
       'Content-Type': 'application/json',
       Authorization: 'Bearer ' + token,
     });
     let options = { headers: headers };
 
     Swal.fire({
       title: 'Are you sure?',
       //icon: 'warning',
       width: '60vh',
       showCancelButton: true,
       confirmButtonColor: '#3085d6',
       cancelButtonColor: '#d33',
       confirmButtonText: popUpBtnText
 
     }).then((result) => {
       if (result.isConfirmed) {
         this.SpinnerService.show();
         //console.log('Inactive',this.apiBody);
         this.http.post('therapist/activeInactiveTherapist',this.apiBody, options).subscribe(
           (res: any) => {
           //console.log(res);
            window.location.reload();
          // if (this.datatableCount > 0) {
          //   this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          //     dtInstance.clear();
          //     dtInstance.destroy();
          //   });
          // }
          //   setTimeout(() => {
          //     this.getTherapists();
          //  }, 3000);

           this.SpinnerService.hide();
         }, (err)=> {
           this.SpinnerService.hide();
           alert('Some errror occured!')
         });
 
       }
     })
   }

   public allSessions(name: any,therapistId: any) {
    this.router.navigate(['/dashboard/therapist-session-view']);
    sessionStorage.setItem('fullName',name);
    sessionStorage.setItem('therapistId',therapistId);
   }
 
}
