import { HttpService } from '../../shared/service/http.service';
import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { countries } from 'src/app/shared/modules/country-data-store';

@Component({
  selector: 'app-admin-therapist-view',
  templateUrl: './admin-therapist-view.component.html',
  styleUrls: ['./admin-therapist-view.component.css'],
})
export class AdminTherapistViewComponent implements OnInit {
  constructor(
    private http: HttpService,
    private router: Router,
    private route: ActivatedRoute,
    private spinnerService: NgxSpinnerService
  ) {}
  therapistDetails: any;
  therapistId: any = '';
  Phone: any ;
  Email: any;
  userName: any;
  role: any;
  region: any;

  userImage: any;
  baseUrl: any = environment.imageUrl;
  avaliabilityRegion: any = [];
  therapistImage: any;
  specificToCountry: boolean ;

  ngOnInit(): void {
    this.spinnerService.show();
    this.getTherapist();
  }

  public getTherapist() {

    let currentUser: any = sessionStorage.getItem('currentUser');
    let token: any = JSON.parse(currentUser).token;
    this.route.params.subscribe((params: any) => {
      //console.log(params);
      this.therapistId = params.id;
    });
    //console.log(token);
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    });
    let options = { headers: headers };
    this.http
      .get('therapist/getTherapistById/' + this.therapistId, options)
      .subscribe((res: any) => {
        //console.log(res);
        this.therapistDetails = res;
       // console.log(this.therapistDetails);
        this.therapistImage = this.therapistDetails?.profileImageUrl;
        this.Email = this.therapistDetails.Email;
        this.Phone = this.therapistDetails.Phone;
        this.role = this.therapistDetails.Role;
        let index = countries.findIndex(x => x.code === this.therapistDetails.Region);
        this.region = countries[index].name;
        this.userName = this.therapistDetails.UserName;
        this.specificToCountry = this.therapistDetails.SpecificToCountry;
        
        let i;
        if (this.specificToCountry == true){
          for(i = 0; i < this.therapistDetails.AvailabilityRegions.length ; i++) {
            this.avaliabilityRegion.push(this.therapistDetails.AvailabilityRegions[i].name);
            if ( i != this.therapistDetails.AvailabilityRegions.length - 1) {
              this.avaliabilityRegion.push(',');
            }
          }
          //console.log(this.avaliabilityRegion);
        } else if(this.specificToCountry == false) {
          for(i = 0; i < this.therapistDetails.AvailabilityRegions.length ; i++) {
            this.avaliabilityRegion.push(this.therapistDetails.AvailabilityRegions[i].State_name);
            if ( i != this.therapistDetails.AvailabilityRegions.length - 1) {
              this.avaliabilityRegion.push(',');
            }
           
          }
        }
        
        this.hideLoader()
      });

    let userId: any = JSON.parse(currentUser).userId;
  }

  public hideLoader(){
    this.spinnerService.hide();
  }

  public back(){
    this.router.navigate(['dashboard/therapist']);
    sessionStorage.setItem('path','dashboard/therapist')
  }
}
