import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ChartData, ChartOptions } from 'chart.js';
import { DateRange } from 'igniteui-angular';
import { BaseChartDirective } from 'ng2-charts';
import { NgxSpinnerService } from 'ngx-spinner';
import { Date_Filters } from 'src/app/shared/models/model';
import {HttpHeaders} from '@angular/common/http';
import axios from 'axios';
import { HttpService } from 'src/app/shared/service/http.service';


@Component({
  selector: 'app-therapist-revenue',
  templateUrl: './therapist-revenue.component.html',
  styleUrls: ['./therapist-revenue.component.css']
})
export class TherapistRevenueComponent implements OnInit {

  public startDate: Date;
  public endDate: Date;
  public filterStartDate: String;
  public filterEndDate: String;
  yearNumber: any;
  monthNumber: any;
  currentUser: any = sessionStorage.getItem('currentUser');
  token: any = JSON.parse(this.currentUser).token;

  @ViewChild( BaseChartDirective ) chart: BaseChartDirective;
  selectedRegion: any;
  lineChartData : any = [1000, 1200, 1050, 2000, 500];

  legendLable: any = ['Jan', 'Feb', 'Mar', 'Apr', 'May','Jun'];
  lineData: any = [];
  thrapistTotalRevenue: any ;
  role: any = sessionStorage.getItem('role');
  therapistId: any;

  chartOptions: ChartOptions = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'Revenue',
      },
    },
  };

  salesData: ChartData<'line'> = {
    labels: ['Week-1', 'Week-2', 'Week-3', 'Week-4'],
    datasets: [
      { label: 'Revenue Generated',
       data: [1000, 4300, 1500, 2000],
       borderColor: '#FFC527',
       backgroundColor: ["#ffc527"],
       pointBackgroundColor: ["#ffc527"],
       tension: 0.5 }
    ],
  };


  dateFlters: Date_Filters[] = [
    { name: 'This Month', value: 'this month' },
    { name: 'Last Month', value: 'last month' },
    { name: 'Custom Date', value: 'custom Date' },
  ];
  selectedFilters: any = 'this month';
  
  constructor(
    
    private router: Router,
    private spinner: NgxSpinnerService,
    private http: HttpService 

  ) { }

  ngOnInit(): void {

    this.monthNumber = String(new Date().getMonth() + 1);
    this.yearNumber = new Date().getFullYear().toString();

    console.log(this.monthNumber);

    this.getTherapistRevenue();

  }

   public getTherapistRevenue() {
    this.spinner.show();
    let currentUser: any = sessionStorage.getItem('currentUser');
    let token: any = JSON.parse(currentUser).token;

    if(this.role == 'Admin') {
      this.therapistId= sessionStorage.getItem('therapistId');
    } else {
      this.therapistId = JSON.parse(currentUser).userId;
    }
    
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    });
    let options = { headers: headers };
   
    this.http
      .get('therapist/getTherapistSessionRevenue/' + this.therapistId, options)
      .subscribe((res: any) => {
        console.log(res);
        this.thrapistTotalRevenue = res.currency +' '+ res.total;
        this.spinner.hide()
      });
  }

  public back() {
    this.router.navigate(['/dashboard/home']);
    sessionStorage.setItem('path','/dashboard/home');
  }

  public search(){
   
    if(this.selectedFilters == 'custom Date') {
      this.filterStartDate = this.convert(this.startDate);
      this.filterEndDate = this.convert(this.endDate);

      console.log(this.filterEndDate);

    } else if(this.selectedFilters == 'this month') {

      let monthNumber = Number(this.monthNumber);

      if(monthNumber < 10) {
          
          this.monthNumber = '0'+ this.monthNumber;

          this.filterStartDate = this.yearNumber+'-'+this.monthNumber+'-01';
          this.filterEndDate = this.yearNumber+'-'+this.monthNumber+'-30';
      } else {
        this.filterStartDate = this.yearNumber+'-'+this.monthNumber+'-01';
        this.filterEndDate = this.yearNumber+'-'+this.monthNumber+'-30';  
      }
      
    }  else if (this.selectedFilters == 'last month') {
      let monthNumber = (Number(this.monthNumber));


      if(this.monthNumber == '1') {
        this.monthNumber = '12';
        this.yearNumber = String(Number(this.yearNumber) - 1)
        
        this.filterStartDate = this.yearNumber+'-'+this.monthNumber+'01';
        this.filterEndDate = this.yearNumber+'-'+this.monthNumber+'30'; 
      } else {
        let monthNumber = (Number(this.monthNumber));


      if(this.monthNumber == '1') {
        this.monthNumber = '12';
        this.yearNumber = String(Number(this.yearNumber) - 1)
        
        this.filterStartDate = this.yearNumber+'-'+this.monthNumber+'01';
        this.filterEndDate = this.yearNumber+'-'+this.monthNumber+'30'; 
      } else {
        

        if(monthNumber > 1 &&  monthNumber < 11) {
          this.monthNumber = String(Number(this.monthNumber) - 1);
          this.monthNumber = '0'+ this.monthNumber;

          this.filterStartDate = this.yearNumber+'-'+this.monthNumber+'-01';
           this.filterEndDate = this.yearNumber+'-'+this.monthNumber+'-30'; 
        } else {
          this.filterStartDate = this.yearNumber+'-'+this.monthNumber+'-01';
          this.filterEndDate = this.yearNumber+'-'+this.monthNumber+'-30'; 
        }
      }
    }
    }

    this.spinner.show(); 
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.token,
    });
    let options = { headers: headers };

    if(this.selectedFilters == 'custom Date' || this.selectedFilters == 'this month' || this.selectedFilters == 'last month' || this.selectedFilters == 'this year') {

    this.http
      .get(`therapist/getTherapistSessionAllRevenue?name=Biswakesan Mohanty&toDate=${this.filterEndDate}&fromDate=${this.filterStartDate}`, options)
      .subscribe((res: any) => {
       
        this.legendLable.length = 0;
        this.lineChartData.length = 0;

        for(let i = 0;i<res.length;i++) {
          this.legendLable.push(res[i]._id.yearMonthDay);
          this.lineChartData.push(res[i].Total);
        }

        this.lineData = this.lineChartData;
        this.salesData = {
          labels: this.legendLable,
          datasets: [
            { label: 'Revenue Generated',
              data: this.lineChartData,
              borderColor: '#FFC527',
              backgroundColor: ["#ffc527"],
              pointBackgroundColor: ["#ffc527"],
              tension: 0.5 
            }
      ],};
        this.chart.ngOnChanges({});
        this.spinner.hide();
      })
      
    }
}

  public reset(){
    //window.location.reload();
  }

  convert(str: any) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
}

