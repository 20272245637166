<div>

    <div class="nav-header">
        <a routerLink="/dashboard/home" class="brand-logo">
          <img class="logo-abbr" src="../../assets/images/logo.png" alt="" />
        </a>
    
        <div class="nav-control">
          <div class="hamburger">
            <span class="line"></span><span class="line"></span
            ><span class="line"></span>
          </div>
        </div>
      </div>
    
      <div class="content-body">
        <div class="container-fluid">
            <!-- <div *ngIf="role == 'Therapist'" style="text-align: right; font-size: large;margin-top: 2%;margin-right: 2%;margin-bottom: 2%;"><b>Total Revenue : {{ thrapistTotalRevenue }}</b> </div> -->
          <!-- Add Order -->
    
          <!-- row -->
    
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body">

                    <button *ngIf="role == 'Admin'" class="btn btn-primary" style="margin: 6px" (click)="back()">
                        Back
                      </button>

                    <div style="text-align: right;">

                        <button class="btn btn-primary" style="margin: 6px" (click)="completedDataLoad()">
                          Completed
                        </button> 

                        <button class="btn btn-primary" style="margin: 6px" (click)="upComingDataLoad()">
                          UpComing
                        </button>
        
                        <button class="btn btn-primary" style="margin: 6px" (click)="canceledDataLoad()">
                          Canceled
                        </button> 
        
                        <button class="btn btn-primary"  (click)="freeDataLoad()">
                          15 Mins
                        </button>
                      </div>
        
                      <h4 style="text-align: center;bottom: 5px">{{sessionType}}</h4>
    
                  <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border dataTable hover" style="width: 100%;">
                    <thead>
                      <tr>
                        <th>Sl No.</th>
                        <th>Patient Name</th>
                        <th>User Name</th>
                        <th>Start Time</th>
                        <th>End Time</th>
                        <th>Status</th>
                        <th *ngIf="role == 'Therapist'">Session Cancel</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of sessions; let i = index">
                        <td>{{ i + 1 }}</td>
                        <td>
                          <a type="button" style="color: #000000;" target="_blank">{{item.InviteeName}}</a>
                        </td>
                        <td>
                            <a type="button" style="color: #000000;" target="_blank">{{item.EventTypeName}}</a>
                          </td>
                        <td>{{ item.start_time | date: "dd/MM/yyyy hh:mm a"}}</td>
                        <td>{{ item.end_time | date: "dd/MM/yyyy hh:mm a"}}</td>
                        <td>
                            <a *ngIf="sessionType == 'Sessions'" style="color: black;">{{ item.Status }}</a>
                            <a *ngIf="sessionType == 'UpComing Sessions'" style="color: orange;">UpComing</a>
                            <a *ngIf="sessionType == 'Completed Sessions'" style="color: green;">Completed</a>
                            <a *ngIf="sessionType == 'Canceled Sessions'" style="color: red;">Canceled</a>
                            <a *ngIf="sessionType == 'Free Sessions'" style="color: green;">Completed</a>
                          </td>
                        <td> 
                          <Button  class="btn btn-primary" style="margin: 6px; padding: 17px 28px;" *ngIf="role == 'Therapist'" [disabled]="item.Status == 'Canceled'|| item.Status == 'Completed'"
                          (click)=" sessionCancel(item.Uuid,'therapist',item._id,item.start_time)" >Cancel</Button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ngx-spinner color="#fff" type="ball-scale-multiple"></ngx-spinner>
  
  </div>