import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { CommonService } from '../shared/service/common.service';
import { HttpService } from '../shared/service/http.service';

@Component({
  selector: 'app-forgot-password-otp',
  templateUrl: './forgot-password-otp.component.html',
  styleUrls: ['./forgot-password-otp.component.css']
})
export class ForgotPasswordOtpComponent implements OnInit {
  userDetails: any = {};
  public loading: boolean = false;
  constructor(private http: HttpService, private router: Router, private commServ: CommonService) {}

  ngOnInit(): void {

    this.commServ.forgotPassEmail.subscribe((data)=> {
      this.userDetails.Email = data
    })
  }

  onSubmit(): void {
   // console.log(this.userDetails);
    this.loading = true;
    this.http.post('user/change-password', this.userDetails).subscribe(
      (res: any) => {
        //console.log(res);
        if (res) {
          this.loading = false;
          Swal.fire(
            'Success!',
            'Password reset link has been sent to the registered email address',
            'success'
          );

          this.router.navigate(['']);
        }
      },
      (error: any) => {
        this.loading = false;
        //console.log(error);
        Swal.fire('Error!', error?.statusText, 'error');
      }
    );
    // } else {
    //   Swal.fire('Error', 'Enter Valid Email', 'error');
    // }
  }
}

