<section>
  <div class="container">
    <div class="row">
      <div class="scss-card">
        <div class="main-container" *ngIf="isResponseSuccess">
          <div class="check-container">
            <div class="check-background">
              <svg viewBox="0 0 65 51" fill="none">
                <path
                  d="M7 25L27.3077 44L58.5 7"
                  stroke="white"
                  stroke-width="13"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div class="check-shadow"></div>
          </div>
        </div>
        <div class="scss-ctnt" *ngIf="isResponseSuccess; else resFailed">
          <h3>Thank You !</h3>
          <p *ngIf="paymentFor == 'session'; else subscription">
            Your session has been confirmed for
            <span>{{ eventDate | date: "dd MMM yyyy" }}</span> at
            <span>{{ eventTime }}</span> with
            <small>{{ sessionData.assignedTo }}</small>
          </p>
          <ng-template #subscription
            ><p>{{ subscriptionMessage }}</p></ng-template
          >
        </div>
        <ng-template #resFailed> <h3>{{responseMessage}}</h3> </ng-template>
      </div>
    </div>
  </div>
</section>
<ngx-spinner color="#fff" type="ball-scale-multiple"></ngx-spinner>
