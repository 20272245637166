import { environment } from '../../environments/environment';
import { HttpService } from '../../shared/service/http.service';
import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import Swal from 'sweetalert2';
import { countries } from '../../shared/modules/country-data-store';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-update-therapist-profile',
  templateUrl: './update-therapist-profile.component.html',
  styleUrls: ['./update-therapist-profile.component.css'],
})
export class UpdateTherapistProfileComponent implements OnInit {
  therapistDetails: any = {};
  selectedDay: any = '';
  slotDetails: any = {};
  emailPattern: any = '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$';
  phonePattern: any = '^((\\+91-?)|0)?[0-9]{10}$';
  name: any;
  img: any;

  slotArray: any = [];
  public loading: boolean = false;

  public countries: any = countries;
  fileName = '';
  _file: any;
  currentFile: any;
  imageUrl: any;
  userImage: any;
  baseUrl: any = environment.imageUrl;
  constructor(
    private http: HttpService,
     private router: Router,
     private spinner: NgxSpinnerService) {}


  selectChangeHandler(event: any) {
    //update the ui
    this.selectedDay = event.target.value;
    this.slotDetails.selectedDay = event.target.value;
  }

  ngOnInit(): void {
    this.spinner.show();
  
    let currentUser: any = sessionStorage.getItem('currentUser');
    let token: any = JSON.parse(currentUser).token;
    let userId: any = JSON.parse(currentUser).userId;
    

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    });
    let options = { headers: headers };

    this.http
      .get('therapist/getTherapistById/' + userId, options)
      .subscribe((res: any) => {
        this.therapistDetails = res;
        this.userImage = this.therapistDetails.profileImageUrl;
        this.spinner.hide();
      });
  }

  addSlot(): void {
  
    this.slotArray.push(this.slotDetails);

    //console.log(this.slotArray);

    this.slotDetails = {};
  }
  clearSlot() {
    this.therapistDetails.selectedDay = '';
    this.therapistDetails.availtimefrom = '';
    this.therapistDetails.availtimeto = '';
  }

  removeTimeSlot(i: any): void {
    //console.log(i);
    this.slotArray.splice(i, 1);
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];

    if (file) {
      this.fileName = file.name;

      const formData = new FormData();

      formData.append('file', file);
      this._file = formData;
    }
  }

  submit(): void {
    // multipart/form-data; boundary=<calculated when request is sent>

    let currentUser: any = sessionStorage.getItem('currentUser');
    let userId: any = JSON.parse(currentUser).userId;

    const upload$ = this.http.post(
      'therapist/updateImage/' + userId,
      this._file
    );

    upload$.subscribe(
      (res: any) => {
        if (res) {
          this.loading = false;
         // console.log(res);
          this.imageUrl = res.profileImageUrl;
          this.therapistDetails.profileImageUrl = res.profileImageUrl;
          sessionStorage.setItem('profile_image',environment.imageUrl+res.profileImageUrl);
          //sessionStorage.setItem('profile_image','https://apivoece.ajatus.in/image/'+res.profileImageUrl);
          window.location.reload();
        }
      },
      (error: any) => {
       // console.log(error);
        this.loading = false;
      }
    );
  }

  onSubmit(): void {
    this.loading = true;
    this.spinner.show();

    this.therapistDetails.slotDetails = this.slotArray;

    let currentUser: any = sessionStorage.getItem('currentUser');
    let token: any = JSON.parse(currentUser).token;
    let userId: any = JSON.parse(currentUser).userId;

    //event handler for the select element's change event

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    });
    let options = { headers: headers };
    this.http
      .post('therapist/update/' + userId, this.therapistDetails, options)
      .subscribe(
        (res: any) => {
          if (res) {
            this.loading = false;
            //console.log(res);
            Swal.fire('Done!', 'Profile updated successfully.', 'success');
            this.spinner.hide();
          }
        },
        (error: any) => {
          //console.log(error);
          this.loading = false;
          this.spinner.hide();
          Swal.fire('Error!', error?.statusText, 'error');
        }
      );
  }

  public back() {
    this.router.navigate(['dashboard/therapist-profile']);
    sessionStorage.setItem('path','dashboard/therapist-profile');
  }
}
