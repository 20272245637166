<div>

  <div class="content-body">
    <div class="container-fluid">
      <!-- Add Order -->

      <!-- row -->
      <div class="row">
        <div class="col-xl-12 col-lg-12">

          <div class="row" style="padding-bottom: 30px;">
            <div class="col-lg-5">
              <button type="submit" (click)="back()" class="btn btn-primary">
                Back
              </button>
            </div>
            <div class="col-lg-7" style="text-align: left;">
              <div class="card-title " >
                Update Therapist
              </div>
            </div>
          </div>

          <div class="card" >
            <div class="card-body" >
              <div class="basic-form">
                <form
                  (ngSubmit)="onSubmit()"
                  #addTherapistForm="ngForm"
                  novalidate
                >
                  <div class="form-row">
                    <!-- <div class="form-group col-md-4">
                      <label>User Name</label>
                      <input
                        class="form-control"
                        type="text"
                        name="username"
                        id="username"
                        required
                        #username="ngModel"
                        [(ngModel)]="therapistDetails.UserName"
                      />
                      <div
                        *ngIf="
                          username.invalid &&
                          (username.dirty || username.touched)
                        "
                        class="alert"
                      >
                        <div *ngIf="username.errors?.['required']">
                          Username is required.
                        </div>
                      </div>
                    </div> -->

                    <div class="form-group col-md-4">
                      <label>First Name</label>
                      <input
                        class="form-control"
                        type="text"
                        name="firstname"
                        id="firstname"
                        required
                        #firstname="ngModel"
                        [(ngModel)]="therapistDetails.FirstName"
                      />
                      <div
                        *ngIf="
                          firstname.invalid &&
                          (firstname.dirty || firstname.touched)
                        "
                        class="alert"
                      >
                        <div *ngIf="firstname.errors?.['required']">
                          firstname is required.
                        </div>
                      </div>
                    </div>

                    <div class="form-group col-md-4">
                      <label>Last Name</label>
                      <input
                        class="form-control"
                        type="text"
                        name="lastname"
                        id="lastname"
                        required
                        #lastname="ngModel"
                        [(ngModel)]="therapistDetails.LastName"
                      />
                      <div
                        *ngIf="
                          lastname.invalid &&
                          (lastname.dirty || lastname.touched)
                        "
                        class="alert"
                      >
                        <div *ngIf="lastname.errors?.['required']">
                          Last name is required.
                        </div>
                      </div>
                    </div>

                    <div class="form-group col-md-4">
                      <label>Email</label>
                      <input
                        class="form-control"
                        type="email"
                        name="email"
                        id="email"
                        required
                        #email="ngModel"
                        [pattern]="emailPattern"
                        [(ngModel)]="therapistDetails.Email"
                      />
                      <div
                        *ngIf="email.invalid && (email.dirty || email.touched)"
                        class="alert"
                      >
                        <div *ngIf="email.errors?.['required']">
                          Email is required.
                        </div>
                        <div *ngIf="email.errors?.['pattern']">
                          Invalid Pattern.
                        </div>
                      </div>
                    </div>

                    <div class="form-group col-md-4">
                      <label>Phone</label>
                      <input
                        class="form-control"
                        type="text"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                        name="phone"
                        id="phone"
                        required
                        [pattern]="phonePattern"
                        #phone="ngModel"
                        [(ngModel)]="therapistDetails.Phone"
                      />

                      <div *ngIf="phone.invalid && (phone.dirty || phone.touched)" class="alert" >
                        <div *ngIf="phone.errors?.['required']">
                          phone is required.
                        </div>
                        <div *ngIf="phone.errors?.['pattern']">
                          Invalid phone number.
                        </div>
                      </div>
                    </div>

                    

                    <div class="form-group col-md-4">
                      <label>Region</label>
                      <select [(ngModel)]="therapistDetails.Region" name="dropdownOption" id="dropdownOption" class="form-control"   >
                        <option *ngFor="let country of countries" [value]="country.code" > {{ country.name }} </option>
                      </select>
                    </div>


                    <div class="form-group col-md-4" *ngIf = "showAvaliableCountry">
                      <label> Licensed Country</label>
                      
                      <ng-multiselect-dropdown [disabled]="!therapistDetails.Region"  ngClass="form-control remove-border"   [placeholder]="'Select avaliable Country'" [settings]="dropdownSettingsCountry" [data]="dropdownListCountry" [(ngModel)]="therapistDetails.AvailabilityRegions" name="Country" (onSelect)="onItemSelectCountry($event)" (onDeSelect)="onItemSelectCountry($event)" > </ng-multiselect-dropdown>
                    </div>

                    <div *ngIf = "showAvaliableCountry" class="form-group col-md-4" style="padding-left: 1%;">
                      <label>License</label>
                      <input  required type="file" class="file-input" name="license" (change)="onFileSelected($event)" #fileUpload />
                    </div>

                    

                    <!-- <div class="form-group col-md-4">
                      <label>Phone</label>
                      <input
                        class="form-control"
                        type="text"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                        name="phone"
                        id="phone"
                        required
                        [pattern]="phonePattern"
                        #phone="ngModel"
                        [(ngModel)]="therapistDetails.Phone"
                      />

          
                    </div> -->

                    <!-- <div class="form-group col-md-4">
                      <li *ngIf="specificToCountry" class="list-group-item" style="display: inline;">
                        <strong>Available Country :</strong> <a *ngFor ="let item of avaliabilityRegion">{{ item }} </a> 
                      </li>
                      <li *ngIf="!specificToCountry"  class="list-group-item" style="display: inline;">
                        <strong>Available State : </strong> <a *ngFor ="let item of avaliabilityRegion">{{ item }} </a> 
                      </li>
                    </div> -->
                    

                    <!-- <div class="form-group col-md-4">
                      <label>Available Countries</label> <a class="form-control" type="text"> India , Srilanka </a>
                    </div>

                    <div class="form-group col-md-4">
                      <label>Available States</label> <a class="form-control" type="text"> Alaska , Newyork </a>
                    </div> -->

                  </div>

                  <form #addressForm="ngForm" *ngIf="statesLength" >
                    <div>

                      <div style="text-align: right;">
                        <a type="button" (click)="addAddress()" style="width: 6%;">Add</a>
                        <!-- <a type="button" (click)="logValue()" style="width: 6%;">Show</a> -->
                      </div>

                      <div  *ngFor="let address of addresses; let i = index;" > 
                        <!-- <a>State - {{ i + 1 }}</a> -->

                        <div class="form-row">

                          <div class="form-group col-md-4">
                            <label>State</label>
                            <select  #selectedStates name="dropdownOption[i]" id="dropdownOption[i]" class="form-control"   (change)="onSelected(selectedStates.value)">
                              <option *ngFor="let state of states" [value]="state.State_name"  > {{ state.State_name }} </option>
                            </select>
                          </div>

                          <div class="form-group col-md-4">
                            <label>License</label>
                            <input  required type="file" class="file-input"  name="license" (change)="onFileSelected($event)" (click) = "showConsole(i)"  #fileUpload />
                            <!-- <input required type="file" class="file-input" name="license" [(ngModel)] = "address.id" (change)="onFileSelected($event)" #fileUpload/> -->

                          </div>      
                          
                          <div class="form-group col-md-4" style="text-align: right">
                            <a type="buton" *ngIf="i != 0" (click)="removeAddress(i)" >Remove</a>
                          </div>
                          

                        </div>

                      </div>
                    </div>
                  </form>
                  <div style="text-align: center; padding-top: 3%;">
                    <button  type="submit" class="btn btn-primary">Update</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ngx-spinner color="#fff" type="ball-scale-multiple"></ngx-spinner> 
</div>
