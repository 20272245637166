<div>
    <div class="content-body" style="padding: 10px;">
        <!-- <div class="row mx-2 mt-2">
            <div class="form-group col-md-4">
                <label class="nav-text" style="font-family: Titillium Web ,sans-serif;">Date</label>
                <select placeholder="Today"
                  [(ngModel)]="selectedFilters" name="dropdownOption" id="dropdownOption" (change)="filtersUpdate()" class="form-control"  style="font-family: Titillium Web ,sans-serif;">
                  <option *ngFor="let filter of dateFlters" [value]="filter.value" [selected]="filter.value=='today'" style="font-family: Titillium Web ,sans-serif;" >
                    {{ filter.name}}
                  </option>
                </select>
            </div>
            <div class="form-group col-md-4">
                <label class="nav-text" style="font-family: Titillium Web ,sans-serif;">Users</label>
                <select
                  placeholder="All"
                  [(ngModel)]="userFilters" name="dropdownOption" id="dropdownOption" (change)="filtersUpdate()" class="form-control"  style="font-family: Titillium Web ,sans-serif;">
                  <option *ngFor="let filter of userData" [value]="filter._id" [selected]="filter._id=='all'"  style="font-family: Titillium Web ,sans-serif;" >
                    {{ filter.FirstName }} {{ filter.LastName }}
                  </option>
                </select>
            </div>
        </div> -->
        <div class="row mx-2 mt-2">
            <div class="col-lg-6 col-md-12" >
                <div class="card pb-3">
                    <h5 class="m-4">Active / Inactive Users</h5>
                    <div id="chartPie">
                        <apx-chart
                          [series]="chartOptionsPie.series!"
                          [chart]="chartOptionsPie.chart!"
                          [labels]="chartOptionsPie.labels!"
                          [responsive]="chartOptionsPie.responsive!"
                        ></apx-chart>
                      </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="card parameters">
                            <div class="card-body">
                                <h6>Avg. Volume</h6>
                                <h4 class="text text-center p-0 m-2"><b>{{ volumeAvg }} <sub>db</sub></b></h4>
                                <span class="avg-label">Optimum Range. 45-65</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="card parameters">
                            <div class="card-body">
                                <h6>Avg. Pitch</h6>
                                <h4 class="text text-center p-0 m-2"><b>{{ pitchAvg }} <sub>Hz</sub></b></h4>
                                <span class="avg-label">Optimum Range. 100-350</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="card parameters">
                            <div class="card-body">
                                <h6>Avg. Intensity</h6>
                                <h4 class="text text-center p-0 m-2"><b>{{ intensityAvg }} <sub>µW/m<sup>2</sup></sub></b></h4>
                                <span class="avg-label">Optimum Range. 15-85</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="card parameters">
                            <div class="card-body">
                                <h6>Avg. Speech Rate</h6>
                                <h4 class="text text-center p-0 m-2"><b>{{ speechRateAvg }} <sub>wpm</sub></b></h4>
                                <span class="avg-label">Optimum Range. 120-160</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mx-4 mt-2">
            <div class="card">
                <h5 class="mx-4 mt-4">Recent data's</h5>
                <span class="mx-4 text text-danger">** dotted line shaded region represents the optimum range for all the parameters</span>
                <div class="row mt-2">
                    <div *ngIf="renderVolumeGraph" class="col-md-6 parameters-graph">
                        <div id="chartBar">
                            <apx-chart
                              [toolbar]="chartOptionsBar.toolbar!"
                              [series]="chartOptionsBar.series!"
                              [chart]="chartOptionsBar.chart!"
                              [dataLabels]="chartOptionsBar.dataLabels!"
                              [plotOptions]="chartOptionsBar.plotOptions!"
                              [yaxis]="chartOptionsBar.yaxis!"
                              [xaxis]="chartOptionsBar.xaxis!"
                              [fill]="chartOptionsBar.fill!"
                              [title]="chartOptionsBar.title!"
                              [annotations]="chartOptionsBar.annotations!"
                            ></apx-chart>
                          </div>
                    </div>
                    <div *ngIf="renderOthers" class="col-md-6 parameters-graph">
                        <div id="chart">
                            <apx-chart
                            [toolbar]="chartOptionsLine.toolbar!"
                              [series]="chartOptionsLine.series!"
                              [chart]="chartOptionsLine.chart!"
                              [xaxis]="chartOptionsLine.xaxis!"
                              [yaxis]="chartOptionsLine.yaxis!"
                              [dataLabels]="chartOptionsLine.dataLabels!"
                              [grid]="chartOptionsLine.grid!"
                              [stroke]="chartOptionsLine.stroke!"
                              [title]="chartOptionsLine.title!"
                              [annotations]="chartOptionsLine.annotations!"
                            ></apx-chart>
                          </div>
                    </div>
                </div>
                <div class="row">
                    <div *ngIf="renderOthers" class="col-md-6 parameters-graph">
                        <div id="chartArea">
                            <apx-chart
                              [toolbar]="chartOptionsArea.toolbar!"
                              [series]="chartOptionsArea.series!"
                              [chart]="chartOptionsArea.chart!"
                              [xaxis]="chartOptionsArea.xaxis!"
                              [stroke]="chartOptionsArea.stroke!"
                              [dataLabels]="chartOptionsArea.dataLabels!"
                              [yaxis]="chartOptionsArea.yaxis!"
                              [labels]="chartOptionsArea.labels!"
                              [legend]="chartOptionsArea.legend!"
                              [title]="chartOptionsArea.title!"
                              [subtitle]="chartOptionsArea.subtitle!"
                              [annotations]="chartOptionsArea.annotations!"
                            ></apx-chart>
                          </div>
                    </div>
                    <div *ngIf="renderOthers" class="col-md-6 parameters-graph">
                        <apx-chart
                            [toolbar]="chartOptionsHorizontal.toolbar!"
                            [series]="chartOptionsHorizontal.series!"
                            [chart]="chartOptionsHorizontal.chart!"
                            [dataLabels]="chartOptionsHorizontal.dataLabels!"
                            [plotOptions]="chartOptionsHorizontal.plotOptions!"
                            [xaxis]="chartOptionsHorizontal.xaxis!"
                            [title]="chartOptionsHorizontal.title!"
                            [annotations]="chartOptionsHorizontal.annotations!"
                        ></apx-chart>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ngx-spinner color="#fff" type="ball-scale-multiple"></ngx-spinner>
</div>
