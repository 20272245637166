<div>

  <div class="content-body">
    <div class="container-fluid">
      <!-- Add Order -->

      <!-- row -->
      <div class="row">
        <div class="col-xl-12 col-lg-12">
        
          <div class="card">
            <div class="card-body">

              <div class="basic-form">
                <form
                  (ngSubmit)="onSubmit()"
                  #addTherapistForm="ngForm"
                  novalidate
                >
                <div class="row">
                  <div class="col-lg-5">
                    <button (click)="back()" class="btn btn-primary">
                      Back
                    </button>
                  </div>
                  <div class="col-lg-7" style="text-align: left;">
                    <div class="card-title " style="padding-bottom: 25px;">
                      Add Therapist
                    </div>
                  </div>
                </div>
                
                

                  <div class="form-row">
                    <!-- <div class="form-group col-md-4">
                      <label>User Name</label>
                      <input
                        class="form-control"
                        type="text"
                        placeholder="Enter User Name"
                        name="username"
                        id="username"
                        required
                        #username="ngModel"
                        [(ngModel)]="therapistDetails.UserName"
                      />
                      <div
                        *ngIf="
                          username.invalid &&
                          (username.dirty || username.touched)
                        "
                        class="alert"
                      >
                        <div *ngIf="username.errors?.['required']">
                          Username is required.
                        </div>
                      </div>
                    </div> -->
                    
                    <div class="form-group col-md-4">
                      <label>First Name</label>
                      <input
                        class="form-control"
                        type="text"
                        placeholder="Enter First Name"
                        name="firstname"
                        id="firstname"
                        required
                        #firstname="ngModel"
                        [(ngModel)]="therapistDetails.FirstName"

                      />
                      <div
                        *ngIf="
                          firstname.invalid &&
                          (firstname.dirty || firstname.touched)
                        "
                        class="alert"
                      >
                        <div *ngIf="firstname.errors?.['required']">
                          firstname is required.
                        </div>
                      </div>
                    </div>

                    <div class="form-group col-md-4">
                      <label>Last Name</label>
                      <input
                        class="form-control"
                        type="text"
                        placeholder="Enter last Name"
                        name="lastname"
                        id="lastname"
                        required
                        #lastname="ngModel"
                        [(ngModel)]="therapistDetails.LastName"
                        [disabled]="!therapistDetails.FirstName"
                      />
                      <div
                        *ngIf="
                          lastname.invalid &&
                          (lastname.dirty || lastname.touched)
                        "
                        class="alert"
                      >
                        <div *ngIf="lastname.errors?.['required']">
                          Last name is required.
                        </div>
                      </div>
                    </div>

                    <div class="form-group col-md-4">
                      <label>Email</label>
                      <input
                        class="form-control"
                        type="email"
                        placeholder="Enter Email"
                        name="email"
                        id="email"
                        required
                        [pattern]="emailPattern"
                        #email="ngModel"
                        [(ngModel)]="therapistDetails.Email"
                      />
                      <div
                        *ngIf="email.invalid && (email.dirty || email.touched)"
                        class="alert"
                      >
                        <div *ngIf="email.errors?.['required']">
                          Email is required.
                        </div>
                        <div *ngIf="email.errors?.['pattern']">
                          Invalid Pattern.
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-md-4">
                      <label>Password</label>
                      <input
                        class="form-control"
                        type="password"
                        placeholder="Enter Password"
                        name="Password"
                        id="Password"
                        required
                        #password="ngModel"
                        [(ngModel)]="therapistDetails.Password"
                      />
                      <div
                        *ngIf="
                          password.invalid &&
                          (password.dirty || password.touched)
                        "
                        class="alert"
                      >
                        <div *ngIf="password.errors?.['required']">
                          password is required.
                        </div>
                      </div>
                    </div>

                    <div class="form-group col-md-4">
                      <label>Phone</label>
                      <input
                        class="form-control"
                        type="text"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                        placeholder="Enter Phone Number"
                        name="phone"
                        id="phone"
                        required
                        [pattern]="phonePattern"
                        #phone="ngModel"
                        [(ngModel)]="therapistDetails.Phone"
                      />
                      <div
                        *ngIf="phone.invalid && (phone.dirty || phone.touched)"
                        class="alert"
                      >
                        <div *ngIf="phone.errors?.['required']">
                          Phone Number is required.
                        </div>

                        <div *ngIf="phone.errors?.['pattern']">
                          Enter Valid Phone Number.
                        </div>
                      </div>
                    </div>

                    <div *ngIf = "showAvaliableCountry" class="form-group col-md-4" style="padding-left: 1%;">
                      <label>License</label>
                      <input  required type="file" class="file-input" name="license" (change)="onFileSelected($event)" #fileUpload />
                    </div>

                    <div class="form-group col-md-4">
                      <label>Therapist's Country</label>
                      <select
                        [(ngModel)]="therapistDetails.Region"
                        name="dropdownOption"
                        id="dropdownOption"
                        class="form-control"
                        (change) = "onCountrySelected()"
                         required
                      >
                        <option
                          *ngFor="let country of countries"
                          [value]="country.code"
                        >
                          {{ country.code }} {{ country.name }}
                        </option>
                      </select>
                    </div>

                    <!-- <div class="form-group col-md-4" *ngIf="states.length != 0" >
                      <label>Licensed States</label>
                      <ng-multiselect-dropdown [disabled]="!therapistDetails.Region"  ngClass="form-control"  [placeholder]="'Select avaliable states'" [settings]="dropdownSettingsStates" [data]="dropdownListStates" [(ngModel)]="therapistDetails.AvailabilityRegions" name="State" (onSelect)="onItemSelectState($event)" (onDeSelect)="onItemSelectState($event)" > </ng-multiselect-dropdown>
                    </div> -->

                    <div class="form-group col-md-4" *ngIf = "showAvaliableCountry">
                      <label> Licensed Country</label>
                      
                      <ng-multiselect-dropdown [disabled]="!therapistDetails.Region"  ngClass="form-control remove-border"   [placeholder]="'Select avaliable Country'" [settings]="dropdownSettingsCountry" [data]="dropdownListCountry" [(ngModel)]="therapistDetails.AvailabilityRegions" name="Country" (onSelect)="onItemSelectCountry($event)" (onDeSelect)="onItemSelectCountry($event)" > </ng-multiselect-dropdown>
                    </div>

                    <div class="form-group col-md-4">
                      <label>Session Fee(per 30 mins)</label>
                      <!--Using ng-option and for loop-->
                      <!-- <div class="form-control"></div> -->
                      <ng-select [(ngModel)]="selectedCurrency" (change)="selectCurrency()">
                          <ng-option *ngFor="let currency of currenciesByRegion" [value]="{price_id:currency._id,price:currency.price}"> {{currency.Currency}}{{currency.Price}} {{currency.Name}}</ng-option>
                      </ng-select>
                    </div>

                  </div>

                  <form #addressForm="ngForm" *ngIf="states.length != 0" >
                    <div>

                      <div style="text-align: right;">
                        <a type="button" (click)="addAddress()" style="width: 6%;">Add</a>
                        <!-- <a type="button" (click)="logValue()" style="width: 6%;">Show</a> -->
                      </div>

                      <div  *ngFor="let address of addresses; let i = index;" > 
                        <!-- <a>State - {{ i + 1 }}</a> -->

                        <div class="form-row">

                          <div class="form-group col-md-4">
                            <label>State</label>
                            <select  #selectedStates name="dropdownOption[i]" id="dropdownOption[i]" class="form-control"   (change)="onSelected(selectedStates.value)">
                              <option *ngFor="let state of states" [value]="state.State_name"  > {{ state.State_name }} </option>
                            </select>
                          </div>

                          <div class="form-group col-md-4">
                            <label>License</label>
                            <input  required type="file" class="file-input"  name="license" (change)="onFileSelected($event)" (click) = "showConsole(i)"  #fileUpload />
                            <!-- <input required type="file" class="file-input" name="license" [(ngModel)] = "address.id" (change)="onFileSelected($event)" #fileUpload/> -->

                          </div>      
                          
                          <div class="form-group col-md-4" style="text-align: right">
                            <a type="buton" *ngIf="i != 0" (click)="removeAddress(i)" >Remove</a>
                          </div>
                          

                        </div>

                      </div>
                    </div>
                  </form>

                  <div class="form-group">
                    <label>Type</label>
                    <div class="d-flex">
                      <div class="form-check m-3">
                        <input class="form-check-input" type="checkbox" value="slp" id="flexCheckDefault" [checked]="therapistType=='slp'" (change)="updateTherapistType($event)">
                        <label class="form-check-label" for="flexCheckDefault">
                          Speech Language Pathologist(SLP)
                        </label>
                      </div>
                      <div class="form-check m-3">
                        <input class="form-check-input" type="checkbox" value="coach" id="flexCheckChecked" [checked]="therapistType=='coach'" (change)="updateTherapistType($event)">
                        <label class="form-check-label" for="flexCheckChecked">
                          Speech Coach
                        </label>
                      </div>
                    </div>
                        <div *ngIf="therapistType==undefined">
                          Therapist type is required
                        </div>
                          
                  </div>

                <!-- <form #addressForm="ngForm" class="container mt-3" (ngSubmit)="logValue()">
                    <div class="row justify-content-center">
                      <div class="col-6">
                          <button type="button" class="btn btn-primary mb-2" (click)="addStates()">Add States</button>
                          
                        <section class="container border mb-4 p-3" *ngFor="let address of selectedSatet; let i = index;">
                          <div class="row">
                            <div class="col-6">
                                <h4>Address {{i + 1}}</h4>
                            </div>
                            <div class="col-6 text-right">
                                <button type="button" class="btn btn-danger btn-sm" (click)="removeStates(i)">Remove</button>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-6">
                                <div class="form-group">
                                    <label>Address</label>
                                    <input type="text" class="form-control" [(ngModel)]="address.address" name="address_{{address.id}}" placeholder="St. Thomas Apartment"/>
                                  </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <label>Street</label>
                                    <input type="text" class="form-control" [(ngModel)]="address.street" name="street_{{address.id}}" placeholder="South Street"/>
                                  </div>
                            </div>
                          </div>
                          
                        </section>

                        <div class="text-right">
                          <input type="submit" class="btn btn-success" value="Submit"/>
                      </div>
                        
                      </div>
                    </div>
                  
                  </form> -->

                  <!-- form - {{ form.value | json }} -->

                  <div style="text-align: center;padding-top: 5%;">
                    <button  [disabled]="!disbaledButton " type="submit" class="btn btn-primary">
                      Save Therapist
                    </button>
                  </div>
                  
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ngx-spinner color="#fff" type="ball-scale-multiple"></ngx-spinner> 
</div>
