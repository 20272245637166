import { HttpService } from '../../shared/service/http.service';
import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import Swal from 'sweetalert2';
import { environment } from '../../environments/environment';
import { countries } from '../../shared/modules/country-data-store';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-admin-subscription',
  templateUrl: './admin-subscription.component.html',
  styleUrls: ['./admin-subscription.component.css'],
})
export class AdminSubscriptionComponent implements OnInit {
  userDetails: any = {};
  public loading: boolean = false;
  therapistDetails: any;
  public countries: any = countries;
  userImage: any;
  baseUrl: any = environment.imageUrl;
  subTypes = [{name: "Month", value: "Month"}, {name: "Annual", value: "Annual"}]
  promotionalTypes = [{name: "Yes", value: true}, {name: "No", value: false}]

  constructor(
    private http: HttpService, 
    private router: Router,
    private spinner: NgxSpinnerService) {}

  ngOnInit(): void {
    let currentUser: any = sessionStorage.getItem('currentUser');
    let token: any = JSON.parse(currentUser).token;
    let userId: any = JSON.parse(currentUser).userId;
    //console.log(token);

    this.http.get('user/getUserById/' + userId).subscribe((res: any) => {
      //console.log(res);
      this.therapistDetails = res;
      this.userImage = this.therapistDetails.profileImageUrl;
    });
  }

  onSubmit(): void {
    this.spinner.show();
    //console.log(this.userDetails);
    let currentUser: any = sessionStorage.getItem('currentUser');
    let token: any = JSON.parse(currentUser).token;
    //console.log(token);

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    });
    let options = { headers: headers };
    this.http.post('subscription/create', this.userDetails, options).subscribe(
      (res: any) => {
        this.loading = false;

        //console.log(res);
        Swal.fire('Success', 'Subscription Created Successfully', 'success');
        this.spinner.hide();
        this.router.navigate(['/dashboard/view-plan']);
        sessionStorage.setItem('path','/dashboard/view-plans');
      },

      (error: any) => {
        this.loading = false;
        this.spinner.hide();
       // console.log(error);
        Swal.fire('error', error?.statusText, 'error');
      }
    );
  }

  public back() {
    this.router.navigate(['dashboard/view-plans']);
    sessionStorage.setItem('path','dashboard/view-plans');
  }
}
