<div>
  
    <div class="content-body" style="padding: 2%;">

        <div class="row">
            <div class="col-lg-5">
              <button (click)="back()" class="btn btn-primary">
                Back
              </button>
            </div>
            <div class="col-lg-7" style="text-align: left;">
              <div class="card-title " style="padding-bottom: 25px;">
                Add Note
              </div>
            </div>
          </div>

        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <div class="card">

                <div class="card-body">
                  <angular-editor [(ngModel)]="htmlContent" [config]="editorConfig"></angular-editor>
                  
                  <div *ngIf = "!updateBtn" style="text-align: center;padding-top: 2%;">
                    <button (click)="save()" class="btn btn-primary">
                      save
                    </button>
                  </div>
                  <div *ngIf = "updateBtn" style="text-align: center;padding-top: 2%;">
                    <button (click)="update()" class="btn btn-primary">
                      Update
                    </button>
                  </div>
                  </div>
              </div>
            </div>
          </div>
    
          <!-- <div id="calendar" class="app-fullcalendar"></div> -->
        </div>
      </div>
    
      <ngx-spinner color="#fff" type="ball-scale-multiple"></ngx-spinner>  
    
    </div>
    