import { HttpService } from '../../shared/service/http.service';
import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HttpHeaders } from '@angular/common/http';
import Swal from 'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';
import { countries } from '../../shared/modules/country-data-store';
import { environment } from '../../environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-update-therapist',
  templateUrl: './update-therapist.component.html',
  styleUrls: ['./update-therapist.component.css'],
})
export class UpdateTherapistComponent implements OnInit {
  emailPattern: any = '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$';
  phonePattern: any = '^((\\+91-?)|0)?[0-9]{10}$';

  therapistDetails: any = {};
  userId: any = '';
  public countries: any = countries;
  public loading: boolean = false;
  userImage: any;
  baseUrl: any = environment.imageUrl;
  therapistImage: any;
  origin: any;
  isDisabled: any = true
  states: any[];
  showAvaliableCountry: boolean=false;
  dropdownListStates: any;
  dropdownSettingsCountry = {};
  dropdownListCountry: Array<any> = [];
  fileName = '';
  _file: any;
  file_name: any;
  diableLicense: boolean;
  specificToCountry: boolean;
  disbaledButton: boolean = false;
  statesLength: boolean= false ;


  public addresses: any[] = [{
    State_name: '',
    LicenseFile: ''
  }];
  constructor(
    private http: HttpService,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit(): void {
    // var currentUser = JSON.parse(localStorage.getItem('currentUser'));
    // var token = currentUser.token; // token

    this.dropdownListCountry = countries;
    let currentUser: any = sessionStorage.getItem('currentUser');
    let token: any = JSON.parse(currentUser).token;

    this.route.params.subscribe((params: any) => {
      this.userId = params.id;
    });
    

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    });
    let options = { headers: headers };

    this.spinner.show()

    this.http
      .get('therapist/getTherapistById/' + this.userId, options)
      .subscribe((res: any) => {
        //console.log(res);
        this.therapistDetails = res;
        this.origin = this.therapistDetails.Region;
        // console.log(this.therapistDetails);
        // console.log(this.origin);
        this.onCountrySelected()
        this.spinner.hide();
      });

      this.dropdownSettingsCountry =  {
        "singleSelection": false,
        "defaultOpen": false,
        "idField": "code",
        "textField": "name",
        "selectAllText": "",
        "unSelectAllText": "",
        "clearSearchFilter": true,
        "enableCheckAll": true,
        "itemsShowLimit": 100,
        "limitSelection": 100,
        "searchPlaceholderText": 'Search country',
        "allowSearchFilter": true
      }

    let user_Id: any = JSON.parse(currentUser).userId;
  }
  

  addAddress() {
    
    this.addresses.push({
      State_name: '',
      LicenseFile: ''
    });
  }

  showConsole(i: any){
    sessionStorage.setItem('index',i);
  }

  removeAddress(i: number) {
    this.addresses.splice(i, 1);
  }

  onSelected(value: String):void {
		// this.selectedTeam = this.teams.nativeElement.value;
    let index: any = sessionStorage.getItem('index');
    console.log(index);
    // console.log(this.selectedStates.nativeElement.value);
    console.log(value);
    this.addresses[index].State_name = value;

    if ( this.addresses[0].state != '' && this.addresses[0].license != '') {
      this.disbaledButton = true;
    } else {
      this.disbaledButton = false;
    }
	}

  onItemSelectCountry(item: any) {
    console.log(this.therapistDetails);
    console.log(this.therapistDetails.AvailabilityRegions.length);

    if(this.therapistDetails.AvailabilityRegions.length > 0) {
      this.disbaledButton = true
    } else {
      this.disbaledButton = false
    }
  }


  onSubmit(): void {
    //console.log(this.therapistDetails);
    let currentUser: any = sessionStorage.getItem('currentUser');
    let token: any = JSON.parse(currentUser).token;
    //console.log(token);
    this.spinner.show()

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    });
    let options = { headers: headers };
    this.http
      .post('therapist/update/' + this.userId, this.therapistDetails, options)
      .subscribe(
        (res: any) => {
          //console.log(89);
          if (res) {
            //console.log(res);
            Swal.fire('success', 'Therapist Updated successfully', 'success');
          }
          this.spinner.hide();
          this.router.navigate(['dashboard/therapist']);
          sessionStorage.setItem('path','/dashboard/therapist');
        },
        (error: any) => {
          //console.log(error);

          Swal.fire('error', error?.statusText, 'error');
        }
      );
  }

  public back() {
    this.router.navigate(['dashboard/therapist']);
    sessionStorage.setItem('path','dashboard/therapist');
  }

  onCountrySelected():void{
    // console.log(this.therapistDetails.Region)
    this.spinner.show();
    this.therapistDetails.AvailabilityRegions = null;
    this.states = [];
     
    
    if(this.therapistDetails.Region !=0){
      this.http
      .get('states/getStates/'+this.therapistDetails.Region)
      .subscribe(
        (res:any) =>{

          this.states=res;
          if (this.states.length != 0){
            this.statesLength=true
          }
          console.log(this.states);
          if (res.length ==0){
            this.showAvaliableCountry = true;
            this.specificToCountry = true;
          }
          else
          {
            this.showAvaliableCountry = false;
            this.dropdownListStates = res;
            this.specificToCountry = false;
          }
          this.spinner.hide();
        },
        (error:any) =>{
          this.loading = false;
          //console.log(error);
          this.spinner.hide()
        })
    }

  }
  onFileSelected(event: any) {
    const file: File = event.target.files[0];

    if (file) {
      this.spinner.show();
      this.fileName = file.name;

      const formData = new FormData();

      formData.append('file', file);
      this._file = formData;
      //this.therapistDetails.license = this._file;

      const upload$ = this.http.post('therapist/uploadTherapistLicense', this._file);

    upload$.subscribe(
      (res: any) => {
        
        console.log(res.message);
        this.file_name = res.message;
        this.diableLicense = true;
        this.spinner.hide();

        if(this.specificToCountry == false) {
          let index: any = sessionStorage.getItem('index');
          console.log(this.addresses[index].LicenseFile = res.message);
  
          if ( this.addresses[0].state != '' && this.addresses[0].LicenseFile != '') {
            this.disbaledButton = true;
          } else {
            this.disbaledButton = false;
          }
        }
        
      },
      (err: any) => {
        Swal.fire('error', 'File upload failed', 'error');
        this.spinner.hide();
      }
    );

    }
  }
}
