// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  
  //development
  // apiEndPoint: 'http://localhost:5001/api/',

  // Live
  apiEndPoint: 'https://api.voece.com/api/',
  imageUrl: 'https://api.voece.com/image/',

   //'https://apivoece.ajatus.in/api/',
  // apiEndPoint: 'https://apivoece.ajatus.in/api/',
  // apiEndPoint: 'http://localhost:5000/api/',
  // imageUrl: 'http://localhost:5000/image/',
  //  imageUrl: 'https://apivoece.ajatus.in/image/',

};
