<div>

  <div class="content-body">
    <div class="container-fluid">
      <!-- Add Order -->

      <!-- row -->
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">

              <button
                    type="button" style="float: right"
                    class="btn btn-primary btn-sm mr-1"
                    (click)="goToCreatePlans()"
                  >
                   Add Subscription Plans
                </button>

              <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border dataTable hover">
                <thead>
                  <tr>
                    <th>Sl No</th>
                    <th>Plan Name</th>
                    <th>Price</th>
                    <th>Stripe prod. ID</th>
                    <th>Region</th>
                    <th>Promotional</th>
                    <th>Description</th>
                    <th>Remove</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of planDetails; let i = index">
                    <td>{{ i + 1 }}</td>
                    <td>{{ item.Name }}</td>
                    <td>{{ item.CurrencySymbol }}{{ item.Price }}</td>
                    <td>{{ item.ProductID }}</td>
                    <td>{{ item.Region }}</td>
                    <td *ngIf="item.IsPromotional">Yes</td>
                    <td *ngIf="!item.IsPromotional">No</td>
                    <td>{{ item.Description }}</td>
                    <!-- <td>
                      <a class="sessionmodallink"
                        >Update <i class="fa fa-pencil"></i
                      ></a>
                    </td> -->

                    <td>
                      <div class="d-flex">
                        <a
                          (click)="deleteItem(item._id)"
                          class="btn btn-danger shadow btn-xs sharp"
                          ><i class="fa fa-trash"></i
                        ></a>
                      </div>
                    </td>

                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ngx-spinner color="#fff" type="ball-scale-multiple"></ngx-spinner>
</div>
