<div>
    <!-- <div class="nav-header">
      <a routerLink="/dashboard/home" class="brand-logo">
        <img class="logo-abbr" src="../../assets/images/logo.png" alt="" />
      </a>
  
      <div class="nav-control">
        <div class="hamburger">
          <span class="line"></span><span class="line"></span
          ><span class="line"></span>
        </div>
      </div>
    </div> -->
  
    <div class="content-body">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="d-flex justify-content-center align-items-center" style="margin-bottom: 4%;">
                  
                    <button
                    style="position: absolute; top:30px; left: 30px;"
                      type="button"
                      (click)="back()"
                      class="btn btn-primary btn-sm mr-1"
                      >
                      Back
                    </button>
                    <h5>Add Corporates</h5>
                    
                </div>
                <form [formGroup]="corporateDetailsForm" (ngSubmit)="onSubmit()"
                >
                <div class="row">
                    <div class="col">
                      <div class="form-row">
                        <!-- <div class="form-group col-md-4">
                          <label>User Name</label>
                          <input
                            class="form-control"
                            type="text"
                            placeholder="Enter User Name"
                            name="username"
                            id="username"
                            required
                            #username="ngModel"
                            [(ngModel)]="therapistDetails.UserName"
                          />
                          <div
                            *ngIf="
                              username.invalid &&
                              (username.dirty || username.touched)
                            "
                            class="alert"
                          >
                            <div *ngIf="username.errors?.['required']">
                              Username is required.
                            </div>
                          </div>
                        </div> -->

                        <div class="form-group col-md-4">
                          <label>First Name</label>
                          <input
                            class="form-control"
                            type="text"
                            placeholder="Enter First Name"
                            id="firstname"
                            formControlName="FirstName"
                            
                          />
                          <div *ngIf="formControls['FirstName'].touched && formControls['FirstName'].hasError('required')">
                            <span>Company name is required.</span>
                          </div>
                          
                          <!-- <div
                          *ngIf="
                          companyname.invalid &&
                          (companyname.dirty || companyname.touched)
                        "
                            class="alert"
                          >
                            <div *ngIf="companyname.errors?.['required']">
                              Company name is required.
                            </div> -->
                          
                        </div>
    
                        <div class="form-group col-md-4">
                          <label>Last Name</label>
                          <input
                            class="form-control"
                            type="text"
                            placeholder="Enter last name"
                            id="lastname"
                            formControlName="LastName"
  
                          />

                          <div *ngIf="formControls['LastName'].touched && formControls['LastName'].hasError('required')">
                            <span>Point of contact is required.</span>
                          </div>
                        </div>
                        
                        <div class="form-group col-md-4">
                          <label>Company Name</label>
                          <input
                            class="form-control"
                            type="text"
                            placeholder="Enter Company Name"
                            id="companyname"
                            formControlName="Companyname"
                            
                          />
                          <div *ngIf="formControls['Companyname'].touched && formControls['Companyname'].hasError('required')">
                            <span>Company name is required.</span>
                          </div>
                          
                          <!-- <div
                          *ngIf="
                          companyname.invalid &&
                          (companyname.dirty || companyname.touched)
                        "
                            class="alert"
                          >
                            <div *ngIf="companyname.errors?.['required']">
                              Company name is required.
                            </div> -->
                          
                        </div>
    
                        <div class="form-group col-md-4">
                          <label>Point of Contact</label>
                          <input
                            class="form-control"
                            type="text"
                            placeholder="Enter point of contact"
                            id="pointofnumber"
                            formControlName="Pointofnumber"
  
                          />

                          <div *ngIf="formControls['Pointofnumber'].touched && formControls['Pointofnumber'].hasError('required')">
                            <span>Point of contact is required.</span>
                          </div>
                        </div>
                          <!-- <div
                          *ngIf="pointofnumber.invalid && (pointofnumber.dirty || pointofnumber.touched)"
                            class="alert"
                          >
                            <div *ngIf="pointofnumber.errors?.['required']">
                              Point of contact is required.
                            </div>
                            <div *ngIf="phone.errors?.['pattern']">
                              Enter Valid point of contact.
                            </div>
                          </div>
                        </div> -->
    
                        <div class="form-group col-md-4">
                          <label>Email</label>
                          <input class="form-control" type="text" id="Email" formControlName="Email" />
                          <div *ngIf="formControls['Email'].touched && formControls['Email'].hasError('required')">
                            <span>Email is required</span>
                          </div>
                          <div *ngIf="formControls['Email'].touched && formControls['Email'].hasError('pattern')">
                            <span>Invalid email format</span>
                          </div>
                          <!-- <div *ngIf="
                          email.invalid &&
                          (email.dirty || email.touched)
                        "
                            class="alert"
                          >
                            <div *ngIf="email.errors?.['required']">
                              Email is required.
                            </div>
                            <div *ngIf="email.errors?.['pattern']">
                              Invalid Pattern.
                            </div>
                          </div> -->
                        </div>
                        <div class="form-group col-md-4">
                          <label>Phone</label>
                          <input
                            class="form-control"
                            type="number"
                            placeholder="Enter phone"
                            id="phone"
                            formControlName="Phone" 
                          />
                          <div *ngIf="formControls['Phone'].touched && formControls['Phone'].hasError('required')">
                            <span>Phone is required</span>
                          </div>
                          <div *ngIf="formControls['Phone'].touched && formControls['Phone'].hasError('pattern')">
                            <span>Invalid Phone format</span>
                          </div>
                          <!-- <div
                          *ngIf="phone.invalid && (phone.dirty || phone.touched)"
                          class="alert"
                          >
                          <div *ngIf="phone.errors?.['required']">
                            Phone Number is required.
                          </div>
  
                          <div *ngIf="phone.errors?.['pattern']">
                            Enter Valid Phone Number.
                          </div>
                          </div> -->
                        </div>
    

                        <div class="form-group col-md-4">
                          <label>Website</label>
                          <input
                            class="form-control"
                            type="text"
                            placeholder="Enter website Number"
                            id="website"
                            formControlName="Website" 
                            
                          />
                          <div *ngIf="formControls['Website'].touched && formControls['Website'].hasError('required')">
                            <span>Website is required</span>
                          </div>
                          <!-- <div *ngIf="formControls['Website'].touched && formControls['Website'].hasError('Website')">
                            <span>Invalid Website format</span>
                          </div> -->
                          <!-- <div *ngIf="
                          website.invalid &&
                          (website.dirty || website.touched)
                        "
                            class="alert"
                          >
                            <div *ngIf="website.errors?.['required']">
                              website is required.
                            </div> -->
    
                            <!-- <div *ngIf="website.errors?.['pattern']">
                              Enter Valid website Number.
                            </div> -->
                          <!-- </div> -->
                        </div>

                        <div class="form-group col-md-4">
                          <label>No. of Licence</label>
                          <input
                            class="form-control"
                            type="number"
                            placeholder="Enter no of licence Number"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            id="nooflicence"
                            
                            formControlName="Nooflicence" 
                            
                          />
                          <div *ngIf="formControls['Nooflicence'].touched && formControls['Nooflicence'].hasError('required')">
                            <span>Nooflicence is required</span>
                          </div>
                          <div *ngIf="formControls['Nooflicence'].touched && formControls['Nooflicence'].hasError('pattern')">
                            <span>Invalid Nooflicence format</span>
                          </div>
                          <!-- <div *ngIf="
                          nooflicence.invalid &&
                          (nooflicence.dirty || nooflicence.touched)
                        "
                            class="alert"
                          >
                            <div *ngIf="nooflicence.errors?.['required']">
                              No. of licence Number is required.
                            </div>
    
                            <div *ngIf="nooflicenceptr.errors?.['pattern']">
                              Enter Valid no. of licence Number.
                            </div>
                          </div> -->
                        </div>


                        <div class="form-group col-md-4">
                          <label>Address</label>
                          <input
                            class="form-control"
                            type="text"
                            placeholder="Enter Address Number"
                            id="address"
                            formControlName="Address"
                            
                          />
                          <div *ngIf="formControls['Address'].touched && formControls['Address'].hasError('required')">
                            <span>Address is required</span>
                          </div>
                          <!-- <div *ngIf="formControls['Address'].touched && formControls['Address'].hasError('Address')">
                            <span>Invalid Address format</span>
                          </div> -->
                          <!-- <div *ngIf="
                          address.invalid &&
                          (address.dirty || address.touched)
                        "
                            class="alert"
                          >
                            <div *ngIf="address.errors?.['required']">
                              address is required.
                            </div> -->
    
                            <!-- <div >
                              Enter Valid address Number.
                            </div> -->
                          <!-- </div> -->
                        </div>



                        <div class="form-group col-md-4">
                          <label>Country</label>
                          <input
                            class="form-control"
                            type="text"
                            placeholder="Enter country Number"
                            id="country"
                            formControlName="Country"
                            
                            
                          />
                          <div *ngIf="formControls['Country'].touched && formControls['Country'].hasError('required')">
                            <span>Country is required</span>
                          </div>
                          <!-- <div *ngIf="formControls['Address'].touched && formControls['Address'].hasError('Address')">
                            <span>Invalid Address format</span>
                          </div> -->
                          <!-- <div *ngIf="
                          country.invalid &&
                          (country.dirty || country.touched)
                        "
                            class="alert"
                          >
                            <div *ngIf="nooflicence.errors?.['required']">
                              country is required.
                            </div> -->
    
                            <!-- <div >
                              Enter Valid country Number.
                            </div> -->
                          <!-- </div> -->
                        </div>



                        <div class="form-group col-md-4">
                          <label>State</label>
                          <input
                            class="form-control"
                            type="text"
                            placeholder="Enter state Number"
                            id="state"
                            formControlName="State"
                            
                          />
                          <div *ngIf="formControls['State'].touched && formControls['State'].hasError('required')">
                            <span>State is required</span>
                          </div>
                          <!-- <div *ngIf="formControls['Address'].touched && formControls['Address'].hasError('Address')">
                            <span>Invalid Address format</span>
                          </div> -->
                          <!-- <div *ngIf="
                          state.invalid &&
                          (state.dirty || state.touched)
                        "
                            class="alert"
                          >
                            <div *ngIf="state.errors?.['required']">
                              state is required.
                            </div> -->
    
                            <!-- <div >
                              Enter Valid state Number.
                            </div> -->
                          <!-- </div> -->
                        </div>




                        <div class="form-group col-md-4">
                          <label>Zip code</label>
                          <input
                            class="form-control"
                            type="number"
                            placeholder="Enter zip code Number"
                            id="zipcode"
                            formControlName="Zipcode"
                            

                            
                          />
                          <div *ngIf="formControls['Zipcode'].touched && formControls['Zipcode'].hasError('required')">
                            <span>Zipcode is required</span>
                          </div>
                          <div *ngIf="formControls['Zipcode'].touched && formControls['Zipcode'].hasError('pattern')">
                            <span>Invalid Zipcode format</span>
                          </div>
                          <!-- <div *ngIf="
                          zipcode.invalid &&
                          (zipcode.dirty || zipcode.touched)
                        "
                            class="alert"
                          >
                            <div *ngIf="zipcode.errors?.['required']">
                              zip code is required.
                            </div>
    
                            <div *ngIf="zipcode.errors?.['pattern']">
                              Enter Valid zip code Number.
                            </div>
                          </div> -->
                        </div>



                        <!-- <div class="form-group col-md-4">
                          <label>Email Domain</label>
                          


                          <div class="input-container">
                            <input class="form-control" [(ngModel)]="userInput" placeholder="Domain" />
                            <a style="margin-left: 10px;"
                              type="button"
                              (click)="addTextToList()"
                              class="btn btn-primary shadow btn-xs sharp mr-1"
                              ><i class="fa fa-plus"></i
                            ></a>
                            
                          </div>
                          
                          <div *ngFor="let text of textList; let i = index" class="text-entry">
                            <div>{{ text }} <a style="margin-left: 10px;"
                              type="button"
                              (click)="removeText(i)"
                              class="btn btn-primary shadow btn-xs sharp mr-1"
                              ><i class="fa fa-trash"></i
                            ></a></div>
                          </div>
                          
                          
                          
    
                            <div *ngIf="phone.errors?.['pattern']">
                              Enter Valid zip code Number.
                            </div>
                          
                        </div> -->



                        <div class="form-group col-md-4">
                          <label>Email Domain</label>
                          <input
                            class="form-control"
                            type="emailDomain"
                            placeholder="Enter Email Domain"
                            id="emailDomain"
                            formControlName="emailDomain"
                            
                          />
                          <div *ngIf="formControls['emailDomain'].touched && formControls['emailDomain'].hasError('required')">
                            <span>emailDomain is required</span>
                          </div>
                          <div *ngIf="formControls['emailDomain'].touched && formControls['emailDomain'].hasError('pattern')">
                            <span>Invalid emailDomain format</span>
                          </div>
                          <!-- <div *ngIf="
                          emailDomain.invalid &&
                          (emailDomain.dirty || emailDomain.touched)
                        "
                            class="alert"
                          >
                            <div *ngIf="emailDomain.errors?.['required']">
                              Email Domain is required.
                            </div>
                            <div *ngIf="emailDomain.errors?.['pattern']">
                              Invalid Pattern.
                            </div>
                          </div> -->
                        </div>
    

                        
    
                      </div>
                    </div>
                </div>


                <div style="text-align: center; padding-top: 5%;">
                  <button [disabled]="corporateDetailsForm.invalid" type="submit" class="btn btn-primary">
                    Save Corporate
                  </button>
                </div>
              </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ngx-spinner color="#fff" type="ball-scale-multiple"></ngx-spinner>
  </div>