<div>

    <div class="content-body">
      <div class="container-fluid">
        <!-- Add Order -->
  
        <!-- row -->
        <div class="row">
          <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <button
                    type="button" style="float: right"
                    class="btn btn-primary btn-sm mr-1"
                    (click)="goToAddSessionPlan()"
                    >
                        Add Session Plans
                    </button>
                    <!-- [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" -->
                    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border dataTable hover" id="datatable">
                        <thead>
                          <tr>
                            <th>Sl No</th>
                            <th>Plan Name</th>
                            <th>Price</th>
                            <th>Price ID</th>
                            <th>Region</th>
                            <th>Description</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let item of sessionPlanDetails; let i = index">
                            <td>{{ i + 1 }}</td>
                            <td>{{ item.Name }}</td>
                            <td>{{ item.CurrencySymbol }}{{ item.Price }}</td>
                            <td>{{ item.Price_Id }}</td>
                            <td>{{ item.Country }}</td>
                            <td>{{ item.Description }}</td>
                            <td>
                              <div class="d-flex">
                                <a class="btn btn-danger shadow btn-xs sharp"><i class="fa fa-trash"></i></a>
                              </div>
                            </td>
                          </tr>                        
                        </tbody>
                    </table>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ngx-spinner color="#fff" type="ball-scale-multiple"></ngx-spinner>
</div>
  