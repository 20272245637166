import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { HttpService } from 'src/app/shared/service/http.service';
import Swal from 'sweetalert2';
import { userSessionData } from '../../shared/modules/user-data-strore';

@Component({
  selector: 'app-therpist-user-session-details',
  templateUrl: './therpist-user-session-details.component.html',
  styleUrls: ['./therpist-user-session-details.component.css']
})
export class TherpistUserSessionDetailsComponent implements OnInit {
  
constructor(
  private http: HttpService, 
  private router: Router,
  private spinner: NgxSpinnerService) {}

userSessionsData: any = [];
dtOptions: DataTables.Settings = {};
dtTrigger: Subject<any> = new Subject<any>();
userImage: any;
// baseUrl: any = environment.imageUrl;

ngOnInit(): void {
  this.dtOptions = {
    pagingType: 'full_numbers',
    pageLength: 10,
    processing: true,
  };
  this.getAllTherapistUserSessions();
  //this.getAllMessages();
}

ngOnDestroy(): void {
  this.dtTrigger.unsubscribe();
}

getAllTherapistUserSessions(): void {
  this.spinner.show();
  let currentUser: any = sessionStorage.getItem('currentUser');
  let token: any = JSON.parse(currentUser).token;
  let therapistNAme = sessionStorage.getItem('fullName');
  let userName = sessionStorage.getItem('setUserItem');
  // console.log(token);

  let headers = new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + token,
  });
  let options = { headers: headers };
  this.http.get(`therapist/findSessionsOfUsers?UserMail=${userName}&Therapist=${therapistNAme}`, options).subscribe((res: any) => {
    this.userSessionsData = res;
    this.dtTrigger.next('')
    this.spinner.hide();

  });

  let user_Id: any = JSON.parse(currentUser).userId;
}

  public showNote(sessionId: any) {

    // console.log(sessionId);
    // let currentUser: any = sessionStorage.getItem('currentUser');
    // let token: any = JSON.parse(currentUser).token;

    this.spinner.show(); 
    let currentUser: any = sessionStorage.getItem('currentUser');
    let token: any = JSON.parse(currentUser).token;

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    });
    let options = { headers: headers };
    this.http
      .get(`user/getUserSessionNote/${sessionId}`, options)
      .subscribe((res: any) => {

      let note = '';
      let button = '';
      if(res.success) {
        note = res.note.Message;
        button = 'Edit';
      } else if (!res.success) {
        note = res.message;
        button = 'Add'
      }

    Swal.fire({
    title: 'Note',
    html: note,
    //showDenyButton: true,
    showCancelButton: true,
    cancelButtonText: 'Close',
    confirmButtonText: button,
    confirmButtonColor: '#3085d6',
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {

      if(button == 'Edit') {
        sessionStorage.setItem('sessionNote',note);
        sessionStorage.setItem('noteId',res.note._id);
        sessionStorage.setItem('calendlySessionId',sessionId);
      } else if(button == 'Add') {
        sessionStorage.removeItem('sessionNote');
        sessionStorage.removeItem('noteId');
        sessionStorage.setItem('calendlySessionId',sessionId);
      }
      //console.log('close');
      this.router.navigate(['dashboard/therapist-user-add-note']);
      sessionStorage.setItem('path','dashboard/therapist-user-add-note');
    }
  });
        
        this.spinner.hide();
      });

  }

public back(){
      this.router.navigate(['dashboard/therapist-user']);
      sessionStorage.setItem('path','dashboard/therapist-user')
    }
  
    public goToUser(path: any) {
      this.router.navigate([path]);
      sessionStorage.setItem('path', path);
    }
    
    // public addNote(sessionId: any) {
    //   sessionStorage.setItem("calendlySessionId",sessionId);
    //   this.router.navigate(['dashboard/therapist-user-add-note']);
    //   sessionStorage.setItem('path','dashboard/therapist-user-add-note');
    // }

}