import { HttpService } from '../../shared/service/http.service';
import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import Swal from 'sweetalert2';
import { environment } from '../../environments/environment';
import { Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-admin-plans-view',
  templateUrl: './admin-plans-view.component.html',
  styleUrls: ['./admin-plans-view.component.css'],
})
export class AdminPlansViewComponent implements OnInit {
  constructor(
    private http: HttpService, 
    private router: Router,
    private spinner: NgxSpinnerService) {}
  planDetails: any = [];
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  userDetails: any = {};
  userImage: any;
  baseUrl: any = environment.imageUrl;

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
    };
    this.spinner.show();
    let currentUser: any = sessionStorage.getItem('currentUser');
    let token: any = JSON.parse(currentUser).token;
    //console.log(token);

    let userId: any = JSON.parse(currentUser).userId;

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    });
    let options = { headers: headers };
    this.http.get('subscription/findAll', options).subscribe((res: any) => {
      //console.log(res);
      this.planDetails = res.reverse();
      this.dtTrigger.next('')
      this.spinner.hide()
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  deleteItem(id: any): void {
    Swal.fire({
      title: 'Are you sure?',
      //icon: 'warning',
      // showDenyButton: true,
      width: '60vh',
      showCancelButton: true,
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinner.show();
        this.http.delete(`subscription/delete/${id}`).subscribe(
          (res: any) => {
           // console.log(res);
            Swal.fire('success', 'Plan deleted successfully', 'success');
            // this.router.navigate(['/view-plans']);
            this.spinner.hide();
            this.ngOnInit();
          },
          (error: any) => {
            //console.log(error);
            Swal.fire('error', error?.statusText, 'error');
            this.spinner.hide();
          }
        );
      }
    });
  }

  public goToCreatePlans() {
    this.router.navigate(['dashboard/create-subscription']);
    sessionStorage.setItem('path','dashboard/create-subscription');
  }
}
