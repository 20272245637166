    <section>
      <div class="auto-container">
          <div class="row">
              <div class="scss-card">
                  <div class="crd-lgo-sec"><img src="../../assets/images/logo.png" alt=""></div>
                  <div class="main-container">
                      <div class="check-container">
                          <div class="check-background">
                              <img src="../../assets/images/cross.svg">
                          </div>
                          <div class="check-shadow"></div>
                      </div>
                  </div>
                  <div class="scss-ctnt">
                      <h3>Oops !</h3>
                      <p>Email-id is not registered.</p>
                  </div>
              </div>
          </div>
      </div>
      </section>