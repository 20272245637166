<div>

  <div class="content-body">
    <div class="container-fluid">
      <!-- Add Order -->

      <!-- row -->
      <div class="row">
        <div class="col-xl-4 col-lg-6 col-sm-6">
          <div class="card overflow-hidden">
            <div class="card-body">
              <div class="text-center">
                <div class="profile-photo">
                  <div *ngIf="userImage; else elseBlock">
                    <img
                      [src]="baseUrl + userImage"
                      width="100px"
                      height="100px"
                      style="border-radius: 50%;"
                      alt="user"
                    />
                   
                      <!-- <div class="container text-right"> -->
                        
                    <!-- </div> -->
                    
                  </div>
                  <ng-template #elseBlock>
                    <img
                      src="../../assets/images/avatar/avatar.png"
                      width="100"
                      class="img-fluid rounded-circle"
                      alt="user"
                    />
                  </ng-template>
                </div>

               <a style="color: #FF4847;" type="button"
               data-toggle="modal"
               data-target="#editmodal"
              
               (click)="setRoute('dashboard/update-admin-profile')">
                <i aria-hidden="true" class="fa fa-pencil-square"  aria-hidden="true" style="font-size: 25px;padding-top: 10px;padding-bottom: 10px"></i>
               </a>

                  
                <h4 class="mb-1" style="font-size: 1.8vw;">{{ name }}</h4>
                <p class="text-muted">{{ role }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-8">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Profile Details</h4>
            </div>
            <div class="card-body">
              <div class="basic-list-group">
                <ul class="list-group list-group-flush">
                  <li class="list-group-item">
                    <strong>Email :</strong> {{ mail }}
                  </li>
                  <li class="list-group-item">
                    <strong>Phone :</strong> {{ phone }}
                  </li>
                  <li class="list-group-item">
                    <strong>Region :</strong> {{ region }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="editmodal">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Profile Edit</h5>
          <button type="button" class="close" data-dismiss="modal">
            <span>&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="form-row">
              <div class="form-group col-md-12">
                <label>Email</label>
                <input
                  type="email"
                  class="form-control"
                  value="developer@gmail.com"
                />
              </div>
              <div class="form-group col-md-12">
                <label>Phone</label>
                <input type="number" class="form-control" value="1001521258" />
              </div>
              <div class="form-group col-md-12">
                <label>Address</label>
                <textarea class="form-control" rows="4" id="comment">
2129 Ridgecrest Rd SE,APT-10 Grand Rapids MI -49546,USA</textarea
                >
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-danger light"
            data-dismiss="modal"
          >
            Close
          </button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="changemodal">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Change Password</h5>
          <button type="button" class="close" data-dismiss="modal">
            <span>&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="form-row">
              <div class="form-group col-md-12">
                <label>Old Password</label>
                <input type="password" class="form-control" />
              </div>
              <div class="form-group col-md-12">
                <label>New Password</label>
                <input type="password" class="form-control" />
              </div>
              <div class="form-group col-md-12">
                <label>Confirm Password</label>
                <input type="password" class="form-control" />
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-danger light"
            data-dismiss="modal"
          >
            Close
          </button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div>
      </div>
    </div>
  </div>
  <ngx-spinner color="#fff" type="ball-scale-multiple"></ngx-spinner>
</div>
