import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleChartInterface, GoogleChartType } from 'ng2-google-charts';
import { userDeciblses } from '../../shared/modules/user-data-strore';

@Component({
  selector: 'app-user-decibles',
  templateUrl: './user-decibles.component.html',
  styleUrls: ['./user-decibles.component.css']
})
export class UserDeciblesComponent implements OnInit {

  public userNames: any = userDeciblses;
  selectedUserName: any;
  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  pieChart: GoogleChartInterface = {
    chartType: GoogleChartType.PieChart,
    dataTable: [
      ['Student', 'Marks'],
      ['12-6am', 80],
      ['6am-12', 90],
      ['12-6pm', 88],
      ['6pm-12', 95],
    ],
    options: { 
      'height':400,
      // is3D: true,
      // pieHole:0.4,
      allowHtml: true,
      allowCollapse: true,
      colors: ["#ff6d4d", "#ffc527", "#ff6d4d", "#ffc527"],
      legend: { position: 'top', alignment: 'center' }
     },
  };

  public back() {
    this.router.navigate(['dashboard/home']);
    sessionStorage.setItem('path','/dashboard/home');
  }
}
