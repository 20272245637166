import { HttpService } from '../../shared/service/http.service';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import Swal from 'sweetalert2';
import { environment } from '../../environments/environment';
import axios from 'axios';
import { countries } from '../../shared/modules/country-data-store';
import { Subject, debounceTime, distinctUntilChanged, filter, fromEvent, tap } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css'],
})
export class UserComponent implements OnInit {

  @Input() totalItems = 0;
  @Input() itemsPerPage = 10;
  @Input() currentPage = 1;
  @Output() pageChange = new EventEmitter<number>();
  @ViewChild('searchQuery') input: ElementRef;
  searchQuery: String = ""

  constructor(
    private http: HttpService, 
    private router: Router,
    private spinner: NgxSpinnerService) {}

    userDetails: any = [];
    // dtOptions: DataTables.Settings = {};
    dtTrigger: Subject<any> = new Subject<any>();
    userImage: any;
    baseUrl: any = environment.imageUrl;
    therapistImage: any;
    status: any;
    apiBody: any = {};

  ngOnInit(): void {
    // this.dtOptions = {
    //   pagingType: 'full_numbers',
    //   pageLength: 10,
    //   processing: true,
    // };
    this.getUsers(this.currentPage,this.itemsPerPage);
  }

  ngAfterViewInit(): void{
    fromEvent(this.input.nativeElement,'keyup')
          .pipe(
              filter(Boolean),
              debounceTime(500),
              distinctUntilChanged(),
              tap((text) => {
                this.searchQuery = this.input.nativeElement.value
                console.log(this.searchQuery)
                this.getUsers(1,10)
              })
          )
          .subscribe();
  }

  ngOnDestroy(): void {
    // this.dtTrigger.unsubscribe();
  }
  dateConversion(d:any):String{
    if(d != undefined){
      var lrd_arr = d.split("T")[0].split("-")
      var lrt = d.split("T")[1].split(".")[0]
      return lrd_arr[2]+"-"+lrd_arr[1]+"-"+lrd_arr[0]+"\n"+lrt
    }else{
      return "no data received yet";
    }
    
  }

  

  getUsers(curr:any,item:any): void {
    this.spinner.show();
    let currentUser: any = sessionStorage.getItem('currentUser');
    let token: any = JSON.parse(currentUser).token;
    // console.log(token);

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    });
    let options = { headers: headers };
    this.http.get('user/getAllUsers?page='+curr+'&limit='+item+'&search='+this.searchQuery, options).subscribe((res: any) => {
      // console.log(res);
      this.userDetails = res.notes.reverse();
      this.totalItems = res.total
      // this.status = this.userDetails.Status;
      // this.dtTrigger.next('')
      this.spinner.hide();

    });

    let user_Id: any = JSON.parse(currentUser).userId;
  }

  deleteUser(userId: any): void {
    // console.log(userId);
    let currentUser: any = sessionStorage.getItem('currentUser');
    let token: any = JSON.parse(currentUser).token;
    // console.log(token);

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    });
    let options = { headers: headers };

    Swal.fire({
      title: 'Are you sure?',
      //icon: 'warning',
      width: '60vh',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, remove it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.http.get('user/delete/' + userId, options).subscribe((res: any) => {
          //console.log(res);
          window.location.reload();
        }, (err)=> {
          alert('Some errror occured!')
        });
      }
    })
  }

  public activeInactive(status: any,id: string): void {
   // console.log(status);
    let popUpBtnText: any;

    if (status == true) {
      popUpBtnText = 'Yes, Inactive!'
      this.apiBody = {
        "userId": id,
        "isActive": false
      }
    } else {
      popUpBtnText = 'Yes, Activate!'
      this.apiBody = {
        "userId": id,
        "isActive": true
      }
    }
    
    let currentUser: any = sessionStorage.getItem('currentUser');
    let token: any = JSON.parse(currentUser).token;
    //console.log(token);


    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    });
    let options = { headers: headers };

    Swal.fire({
      title: 'Are you sure?',
      //icon: 'warning',
      width: '60vh',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: popUpBtnText

    }).then((result) => {
      if (result.isConfirmed) {
        this.spinner.show();
        //console.log('Inactive',this.apiBody);
        this.http.post('user/activeInactiveUser',this.apiBody, options).subscribe(
          (res: any) => {
          //console.log(res);
          window.location.reload();
          this.spinner.hide();
        }, (err)=> {
          this.spinner.hide();
          alert('Some errror occured!')
        });

      }
    })
  }

  public addUser(path: string) {
    this.router.navigate([path]);
    sessionStorage.setItem('path',path);
  }

  public viewUser(path: any,id: any) {
    sessionStorage.setItem('path',path+'/'+id);
  }

  get totalPages(): number {
    return Math.ceil(this.totalItems / this.itemsPerPage);
  }

  get displayedPages(): number[] {
    const totalDisplayedPages = 5; // You can customize the number of displayed pages
    const startPage = Math.max(1, this.currentPage - Math.floor(totalDisplayedPages / 2));
    const endPage = Math.min(this.totalPages, startPage + totalDisplayedPages - 1);

    return Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);
  }

  get visiblePages(): number[] {
    const maxVisiblePages = 3;
    const startPage = Math.max(1, this.currentPage - Math.floor(maxVisiblePages / 2));
    const endPage = Math.min(this.totalPages, startPage + maxVisiblePages - 1);
    return Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);
  }

  changePage(pageNumber: number): void {
    if (pageNumber >= 1 && pageNumber <= this.totalPages) {
      console.log(pageNumber);
      this.userDetails = []
      this.getUsers(pageNumber,this.itemsPerPage)
      this.currentPage = pageNumber
      this.pageChange.emit(pageNumber);
    }
  }  

}

// user/activeInactiveUser
