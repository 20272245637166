import { Component, OnInit } from '@angular/core';
import { HttpService } from '../shared/service/http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../shared/service/authentication.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-corporate-login',
  templateUrl: './corporate-login.component.html',
  styleUrls: ['./corporate-login.component.css']
})
export class CorporateLoginComponent implements OnInit {
  userDetails: any = {};
  public loading: boolean = false;
  emailPattern: any = '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$';
  calendlyCode: any;
  role = sessionStorage.getItem('role');
  client_id: any = 'p8yQgis3aF8Z_M3zvDwRdTim8Ad3ms3sfFJ0T4XLncY&client_secret=xl5doUfUAbvlkZDA7a1cgH4IniR8YRiurenlo0SzT94';
  redirect_uri: any = 'https://dashboard.voece.com/';
  constructor(
    private http: HttpService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private auth: AuthenticationService
  ) { 
    if (this.auth.loggedIn) {
      if (sessionStorage.getItem('currentUser') == null) {
        this.router.navigate(['']);
      }
      else if (this.role == 'Corporate-Admin') {
        //corporate dashboard
        this.router.navigate(['/dashboard/corporate-dashboard']);
      } else {
        this.router.navigate(['/dashboard/therapist-session-view']);
      }
    } else {
      
    }
  }

  ngOnInit(): void {
  }

  onSubmit(): void {
   
    if ((this.userDetails.email, this.userDetails.password)) {
      this.loading = true;
      this.http.post('corporates/corporateLogin', this.userDetails).subscribe(
        (res: any) => {
          this.loading = false
          console.log("resources",res);
          let decodeObj = JSON.parse(atob(res.token.split('.')[1]))
          console.log(decodeObj);
          
          if(res.userData.isFirstTime == "true"){
            console.log("this");
            
            //navigate to change password
            this.router.navigate(["/change-password-request"],{queryParams:{token: res.token}})
          }else{
            console.log("that");
            
            //navigate to dashboard
            sessionStorage.setItem("role","CorporateAdmin")
            sessionStorage.setItem("token",res.token)
            sessionStorage.setItem("userId",decodeObj.id)
            sessionStorage.setItem("_id",res.userData._id)
            sessionStorage.setItem("companyName",res.userData.companyName)
            sessionStorage.setItem("email",res.userData.email)
            sessionStorage.setItem("isFirstTime",res.userData.isFirstTime)
            sessionStorage.setItem("phone",res.userData.phone)
            sessionStorage.setItem("website",res.userData.website)
            sessionStorage.setItem("CorporateCode", res.userData.CorporateCode)
            sessionStorage.setItem('isLoggedIn','true');
            sessionStorage.setItem("currentUser",JSON.stringify(res.userData))
            sessionStorage.setItem('profile_image','https://cdn-icons-png.flaticon.com/128/5541/5541165.png');
            this.router.navigate(["/dashboard/corporate-dashboard"]);

          }
          
        },
        (err:any) => {
          this.loading = false
          console.log("error",err.error.message);
          if(err.error.message != undefined){
            Swal.fire(err.error.message)
          }else{
            Swal.fire('Something went wrong');
          }
        }
      );
    } else {
      Swal.fire('Error', 'Enter Email and Password', 'error');
    }
  }

}
