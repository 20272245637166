import { HttpService } from '../../shared/service/http.service';
import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import Swal from 'sweetalert2';
import { environment } from '../../environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { countries } from 'src/app/shared/modules/country-data-store';

@Component({
  selector: 'app-update-user',
  templateUrl: './update-user.component.html',
  styleUrls: ['./update-user.component.css'],
})
export class UpdateUserComponent implements OnInit {
  userDetails: any = {};
  currentDetails: any = {};
  userId: any = '';
  public loading: boolean = false;
  public countries: any = countries;

  userImage: any;
  baseUrl: any = environment.imageUrl;
  therapistImage: any;

  emailPattern: any = '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$';
  phonePattern: any = '^((\\+91-?)|0)?[0-9]{10}$';
  hide: any = false;

  constructor(
    private http: HttpService,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit(): void {
  
    let currentUser: any = sessionStorage.getItem('currentUser');
    let token: any = JSON.parse(currentUser).token;
    this.spinner.show();

    this.route.params.subscribe((params: any) => {
      this.userId = params.id;
    });

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    });
    let options = { headers: headers };

    this.http
      .get('user/getUserById/' + this.userId, options)
      .subscribe((res: any) => {
        this.userDetails = res;
        this.spinner.hide();
      });

    let user_Id: any = JSON.parse(currentUser).userId;

    this.http.get('user/getUserById/' + user_Id).subscribe((res: any) => {
      this.userImage = res.profileImageUrl;
    });
  }

  onSubmit(): void {
    this.spinner.show();
    let currentUser: any = sessionStorage.getItem('currentUser');
    let token: any = JSON.parse(currentUser).token;
    //console.log(token);
    this.loading = true;

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    });
    let options = { headers: headers };
    if (this.userDetails.FirstName) {
      this.http
        .post('user/update/' + this.userId, this.userDetails, options)
        .subscribe(
          (res: any) => {
            if (res) {
             // console.log(res);
              this.loading = false;
              Swal.fire('Success!', 'User Updated successfully', 'success');
              this.userDetails = {};
              this.spinner.hide();
              this.router.navigate(['dashboard/user']);
            }
          },
          (error: any) => {
            this.loading = false;
            //console.log(error);
            this.spinner.hide();
            Swal.fire('Error!', error?.message, 'error');
          }
        );
    } else {
      this.spinner.hide();
      Swal.fire('Error!', 'Please enter all fields');
    }
  }

  public back(path: any) {
    this.router.navigate([path]);
    sessionStorage.setItem('path',path);
  }

}
