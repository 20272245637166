import { environment } from '../../environments/environment';
import { HttpService } from '../../shared/service/http.service';
import { Component, ElementRef, Input, OnInit, ViewChild, inject } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import axios from 'axios';
import { countries } from '../../shared/modules/country-data-store';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { state } from '@angular/animations';
import { CalendlyAccesTokenService } from 'src/app/shared/service/calendly-acces-token.service';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatChipInputEvent } from '@angular/material/chips';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Subject } from 'rxjs';



@Component({
  selector: 'app-corporates',
  templateUrl: './corporates.component.html',
  styleUrls: ['./corporates.component.css']
})
export class CorporatesComponent implements OnInit {

  corporatedata: any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();


  constructor(
    private router:Router, private http: HttpService,
    private fb: FormBuilder,
    private SpinnerService: NgxSpinnerService,
    private callendlyAccessTokenCall: CalendlyAccesTokenService,) { }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
    };

    this.getAllCorporate();
  }

  getAllCorporate() {
    let currentUser: any = sessionStorage.getItem('currentUser');
    let token: any = JSON.parse(currentUser).token;
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    });
    let options = { headers: headers };
    this.http
      .get(`corporates/getCorporateDetails/`, options)
      .subscribe(
        (res: any) => {
          if (res) {
        this.dtTrigger.next('')
            this.corporatedata = res
          }
        })
  }

  deleteCorporate(id: any) : void {
    Swal.fire({
      title: 'Are you sure?',
      //icon: 'warning',
      // showDenyButton: true,
      width: '60vh',
      showCancelButton: true,
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        this.SpinnerService.show();
        this.http.delete(`corporates/deleteCorporate/${id}`).subscribe(
          (res: any) => {
           // console.log(res);
            this.SpinnerService.hide();
            // this.ngOnInit();
            window.location.reload();
          },
          (error: any) => {
            //console.log(error);
            Swal.fire('error', error?.statusText, 'error');
            this.SpinnerService.hide();
          }
        );
      }
    });
  }

  addCorporates(path: any){
    console.log(path);
    
    this.router.navigate([path]);
    sessionStorage.setItem('path',path);
  }


  public activeInactive(id: string, status: any): void {
    // console.log(status);
     let popUpBtnText: any;
     
     let currentUser: any = sessionStorage.getItem('currentUser');
     let token: any = JSON.parse(currentUser).token;
     //console.log(token);
     if (status == true) {
      popUpBtnText = 'Yes, Inactive!'
    } else {
      popUpBtnText = 'Yes, Activate!'
    }
 
     let headers = new HttpHeaders({
       'Content-Type': 'application/json',
       Authorization: 'Bearer ' + token,
     });
     let options = { headers: headers };
 
     Swal.fire({
       title: 'Are you sure?',
       //icon: 'warning',
       width: '60vh',
       showCancelButton: true,
       confirmButtonColor: '#3085d6',
       cancelButtonColor: '#d33',
       confirmButtonText: popUpBtnText
 
     }).then((result) => {
       if (result.isConfirmed) {
         this.SpinnerService.show();
         //console.log('Inactive',this.apiBody);
         this.http.get(`corporates/updateCorporateStatus?id=${id}`, options).subscribe(
           (res: any) => {
           //console.log(res);
            window.location.reload();
          // if (this.datatableCount > 0) {
          //   this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          //     dtInstance.clear();
          //     dtInstance.destroy();
          //   });
          // }
          //   setTimeout(() => {
          //     this.getTherapists();
          //  }, 3000);

           this.SpinnerService.hide();
         }, (err)=> {
           this.SpinnerService.hide();
           alert('Some errror occured!')
         });
 
       }
     })
   }


  public goToViewCorporate(path: any,id: any) {
    sessionStorage.setItem('path',path+'/'+id);
    sessionStorage.setItem('updatepathid', id);
  }

}
