import { HttpHeaders } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpService } from 'src/app/shared/service/http.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-therpist-add-note',
  templateUrl: './therpist-add-note.component.html',
  styleUrls: ['./therpist-add-note.component.css']
})
export class TherpistAddNoteComponent implements OnInit, OnDestroy {

  htmlContent = sessionStorage.getItem('sessionNote');
  updateBtn: Boolean = false;

  editorConfig: AngularEditorConfig = {
      editable: true,
      spellcheck: true,
      height: '15rem',
      minHeight: '5rem',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: false,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
};

  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    private http: HttpService
  ) { }
  ngOnInit(): void {
    if(sessionStorage.getItem('sessionNote') == '' || sessionStorage.getItem('sessionNote') == null || sessionStorage.getItem('sessionNote') == undefined) {
      this.updateBtn = false;
    } else if(sessionStorage.getItem('sessionNote') != '') {
      this.updateBtn = true
    }
  }

  ngOnDestroy(): void {
  }

  public back() {
    this.router.navigate(['dashboard/therapist-user-session-details']);
    sessionStorage.setItem('path','/therapist-user');
  }

  public save() {
    this.spinner.show();
    //console.log(this.htmlContent);
    let currentUser: any = sessionStorage.getItem('currentUser');
    let token: any = JSON.parse(currentUser).token;
    let therapistId = JSON.parse(currentUser).userId;
    let sessionId = sessionStorage.getItem('calendlySessionId');
    let userMail = sessionStorage.getItem('setUserItem');

    let saveNote = {}

    saveNote = {
      "SessionId": sessionId,
      "Message": this.htmlContent,
      "UserMail": userMail,
      "TherapistID": therapistId
    }

    if(this.htmlContent == '' || this.htmlContent == null || this.htmlContent == undefined) {
      Swal.fire('Error!','Note can not be blank', 'error');
      this.spinner.hide();
    } else {
    
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    });
    let options = { headers: headers };
    this.http
      .post('user/addSessionNote', saveNote, options)
      .subscribe(
        (res: any) => {
          //console.log(res);
          //this.addTherapistToOrg();
          Swal.fire(
          'Success',
          'Note added sucessfully.',
          'success'
        );
        this.router.navigate(['dashboard/therapist-user-session-details']);
        this.spinner.hide()
        },
        (error: any) => {
          console.log(error);
          Swal.fire('Error!', error?.error.message, 'error');
          this.spinner.hide()
        }
      );
    }
  }

  public update() {
    this.spinner.show();
    console.log(this.htmlContent);
    let currentUser: any = sessionStorage.getItem('currentUser');
    let token: any = JSON.parse(currentUser).token;
    let therapistId = JSON.parse(currentUser).userId;
    let sessionId = sessionStorage.getItem('calendlySessionId');
    let userMail = sessionStorage.getItem('setUserItem');
    let noteId = sessionStorage.getItem('noteId')

    let updateNote = {}

    updateNote = {
      "Message": this.htmlContent,
      "UserMail": userMail,
      "TherapistID": therapistId
    }

    if(this.htmlContent == '' || this.htmlContent == null || this.htmlContent == undefined) {
      Swal.fire('Error!','Note can not be blank', 'error');
      this.spinner.hide();
    } else {
      let headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      });
      let options = { headers: headers };
      this.http
        .post(`user/updateSessionNote/${noteId}`, updateNote, options)
        .subscribe(
          (res: any) => {
            //console.log(res);
            //this.addTherapistToOrg();
            Swal.fire(
            'Success',
            'Note updated sucessfully.',
            'success'
          );
          this.router.navigate(['dashboard/therapist-user-session-details']);
          this.spinner.hide()
          },
          (error: any) => {
            console.log(error);
            Swal.fire('Error!', error?.error.message, 'error');
            this.spinner.hide()
          }
        );
    }
  }

}
