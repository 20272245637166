<div >
    <div class="nav-header">
      <a  *ngIf = "userRole == 'Admin'" (click)="changeDashboardTitleName('Dashboard','/dashboard/home')" class="brand-logo">
        <img class="logo-abbr" src="../../assets/images/logo.png" alt="" />
      </a>

      <a  *ngIf = "userRole == 'Therapist'" (click)="changeDashboardTitleName('Dashboard','dashboard/therapist-session-view')" class="brand-logo">
        <img class="logo-abbr" src="../../assets/images/logo.png" alt="" />
      </a>

      <a  *ngIf = "userRole == 'CorporateAdmin'" (click)="changeDashboardTitleName('Dashboard','dashboard/corporate-dashboard')" class="brand-logo">
        <img class="logo-abbr" src="../../assets/images/logo.png" alt="" />
      </a>

      <div class="nav-control">
        <div class="hamburger">
          <span class="line"></span><span class="line"></span
          ><span class="line"></span>
        </div>
      </div>
    </div>

      <div class="deznav">
        <div class="deznav-scroll">
          <ul class="metismenu" id="menu">

            <li *ngIf = "userRole == 'Admin'"> 
              <div (click)="changeDashboardTitleName('Dashboard','/dashboard/home')">
                <a type = "button" class="ai-icon" aria-expanded="false" [ngStyle]="{'color':titleName === 'Dashboard' ? '#F36E43' : '' }" >
                  <i class="flaticon-381-networking"></i> &nbsp;
                  <span class="nav-text" >Dashboard</span>
                </a>
              </div>
            </li>

            <li *ngIf = "userRole == 'Admin'" (click)="changeDashboardTitleName('Therapist','/dashboard/therapist')">
              <a type = "button" class="ai-icon" aria-expanded="false" [ngStyle]="{'color':titleName === 'Therapist' ? '#F36E43' : '' }" >
                <i class="flaticon-381-notepad"></i> &nbsp;
                <span class="nav-text" >Therapist</span>
              </a>
            </li>

            <li *ngIf = "userRole == 'Admin'" (click)="changeDashboardTitleName('User','/dashboard/user')">
              <a  type = "button" class="ai-icon" aria-expanded="false" [ngStyle]="{'color':titleName === 'User' ? '#F36E43' : '' }" >
                <i class="fa fa-users"></i> &nbsp;
                <span class="nav-text" >User</span>
              </a>
            </li>
            <li *ngIf = "userRole == 'Admin'" (click)="changeDashboardTitleName('Corporates','/dashboard/corporates')">
              <a  type = "button" class="ai-icon" aria-expanded="false" [ngStyle]="{'color':titleName === 'Corporates' ? '#F36E43' : '' }" >
                <i class="fa fa-briefcase" aria-hidden="true"></i> &nbsp;
                <span class="nav-text" >Corporates</span>
              </a>
            </li>
            <li *ngIf = "userRole == 'Admin'" (click)="changeDashboardTitleName('Subscription','/dashboard/view-plans')">
              <a type = "button" class="ai-icon" aria-expanded="false" [ngStyle]="{'color':titleName === 'Subscription' ? '#F36E43' : '' }"  >
                <i class="fa fa-credit-card"></i> &nbsp;
                <span class="nav-text" >Subscription</span>
              </a>
            </li>
            <li *ngIf = "userRole == 'Admin'" (click)="changeDashboardTitleName('Session-Plans','/dashboard/view-session-plans')">
              <a type = "button" class="ai-icon" aria-expanded="false" [ngStyle]="{'color':titleName === 'Session-Plans' ? '#F36E43' : '' }"  >
                <i class="fa fa-id-card-o" aria-hidden="true"></i> &nbsp;
                <span class="nav-text" >Session Plans</span>
              </a>
            </li>
            <li *ngIf = "userRole == 'Admin'" (click)="changeDashboardTitleName('All Messages','/dashboard/find-all-message')">
              <a type = "button" class="ai-icon" aria-expanded="false" [ngStyle]="{'color':titleName === 'All Messages' ? '#F36E43' : '' }"  >
                <i class="fa fa-comments" aria-hidden="true"></i> &nbsp;
                <span class="nav-text" >All Messages</span>
              </a>
            </li>
            <!-- <li *ngIf = "userRole == 'Admin'" (click)="changeDashboardTitleName('File Upload','/dashboard/upload-file')">
              <a type = "button" [ngStyle]="{'color':titleName === 'File Upload' ? '#F36E43' : '' }"
                class="ai-icon" aria-expanded="false" >
                <i class="fa fa-upload"></i> &nbsp;
                <span class="nav-text" >File Upload</span>
              </a>
            </li> -->

            <li *ngIf = "userRole == 'Therapist'">
              <a type = "button" [ngStyle]="{'color':titleName === 'Dashboard' ? '#F36E43' : '' }"
                (click)="changeDashboardTitleName('Dashboard','dashboard/therapist-session-view')"
                class="ai-icon"
                aria-expanded="false">
                <i class="flaticon-381-networking"></i> &nbsp;
                <span class="nav-text">Dashboard</span>
              </a>
            </li>

            <li *ngIf = "userRole == 'Therapist'">
              <a type = "button" [ngStyle]="{'color':titleName === 'Revenue' ? '#F36E43' : '' }"
                (click)="changeDashboardTitleName('Revenue','dashboard/therapist-revenue')"
                class="ai-icon"
                aria-expanded="false">
                <i class="fa fa-credit-card"></i> &nbsp;
                <span class="nav-text">Revenue</span>
              </a>
            </li>

            <li  *ngIf = "userRole == 'Therapist'">
              <a type = "button" [ngStyle]="{'color':titleName === 'User' ? '#F36E43' : '' }"
                class="ai-icon"
                aria-expanded="false"
                (click)="changeDashboardTitleName('User','dashboard/therapist-user')"
              >
                <i class="fa fa-users"></i> &nbsp;
                <span class="nav-text">User</span>
              </a>
            </li>
            <li *ngIf = "userRole == 'CorporateAdmin'">
              <a type = "button" [ngStyle]="{'color':titleName === 'Dashboard' ? '#F36E43' : '' }"
                class="ai-icon"
                aria-expanded="false"
                (click)="changeDashboardTitleName('Dashboard','dashboard/corporate-dashboard')"
              >
                <i class="flaticon-381-networking"></i> &nbsp;
                <span class="nav-text">Dashboard</span>
              </a>
            </li>
            <li *ngIf = "userRole == 'CorporateAdmin'">
              <a type = "button" [ngStyle]="{'color':titleName === 'Users' ? '#F36E43' : '' }"
                class="ai-icon"
                aria-expanded="false"
                (click)="changeDashboardTitleName('Users','dashboard/corporate-users')"
              >
                <i class="fa fa-users"></i> &nbsp;
                <span class="nav-text">Users</span>
              </a>
            </li>
            
          </ul>
        </div>
      </div>
    </div>

<!-- Header part -->
<div >
<div class="header">
  <div class="header-content">
    <nav class="navbar navbar-expand">
      <div class="collapse navbar-collapse justify-content-between">
        <div class="header-left">
          <div class="dashboard_bar">{{titleName}}</div>
        </div>
        <ul class="navbar-nav header-right">

          

          <li class="nav-item dropdown header-profile">
            <a class="nav-link" href="javascript:void(0)" role="button" data-toggle="dropdown" >
            
            </a>
            <!-- <div class="dropdown-menu dropdown-menu-right"> -->
            <a *ngIf = "userRole == 'Admin'" class="dropdown-item ai-icon" (click)="changeDashboardTitleName('Profile','dashboard/admin-profile')">
              
              <a class="nav-link"
              href="javascript:void(0)"
              role="button"
              data-toggle="dropdown" >
              <div *ngIf="image; else elseBlock">
                <img
                  [src]="image"
                  style="border-radius: 50%;height: 40px;width: 40px;"
                  class="img-fluid rounded-circle"
                  alt="user"
                />
                <!-- <span class="ml-2" style="font-size: 16px;">Profile </span> -->
              </div>
              <ng-template #elseBlock>
                <img
                  src="../../assets/images/avatar/avatar.png"
                  style="border-radius: 50%;height: 40px;width: 50px;"
                  class="img-fluid rounded-circle"
                  alt="user"
                />
                <span class="ml-2" style="font-size: 16px;">Profile </span>
              </ng-template>
            </a>

              <!-- </svg> -->
              <!-- <div (click)="changeDashboardTitleName('Profile','/profile')"></div> -->
              <!-- <span class="ml-2" (click)="changeDashboardTitleName('Profile','/admin-profile')">Profile </span> -->
            </a>

            <a *ngIf = "userRole == 'Therapist'" class="dropdown-item ai-icon" (click)="changeDashboardTitleName('Profile','dashboard/therapist-profile')">
              
              <a class="nav-link"
              href="javascript:void(0)"
              role="button"
              data-toggle="dropdown" >
              <div *ngIf="image; else elseBlock">
                <img
                  [src]="image"
                  style="border-radius: 50%;height: 40px;width: 40px;"
                  class="img-fluid rounded-circle"
                  alt="user"
                />
                <span class="ml-2" style="font-size: 16px;">Profile </span>
              </div>
              <ng-template #elseBlock>
                <img
                  src="../../assets/images/avatar/avatar.png"
                  style="border-radius: 50%;height: 40px;width: 50px;"
                  class="img-fluid rounded-circle"
                  alt="user"
                />
                <span class="ml-2" style="font-size: 16px;">Profile </span>
              </ng-template>
            </a>

              <!-- </svg> -->
              <!-- <div (click)="changeDashboardTitleName('Profile','/profile')"></div> -->
              <!-- <span class="ml-2" (click)="changeDashboardTitleName('Profile','/admin-profile')">Profile </span> -->
            </a>

            <a *ngIf = "userRole == 'CorporateAdmin'" class="dropdown-item ai-icon" (click)="copyMessage(corporateCode)">CODE: {{corporateCode}}</a>
            
            <a type = "button" class="dropdown-item ai-icon" (click)="logout()">
              <svg
                id="icon-logout"
                xmlns="http://www.w3.org/2000/svg"
                class="text-danger"
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                <polyline points="16 17 21 12 16 7"></polyline>
                <line x1="21" y1="12" x2="9" y2="12"></line>
              </svg>
              <span class="ml-2">Logout </span>
            </a>
            <!-- </div> -->
          </li>
        </ul>
      </div>
    </nav>
  </div>
</div>
</div>

<router-outlet></router-outlet>

<!-- footer part -->
<div class="footer">
<div class="copyright">
  <p>
    © 2022 <strong>VOECE</strong>. All Rights Reserved. Developed by
    <a href="https://www.ajatus.in" target="_blank">Ajatus Software </a>
  </p>
</div>
</div>