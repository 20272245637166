import { HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ChartData, ChartOptions } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { NgxSpinnerService } from 'ngx-spinner';
import { monthNames } from 'src/app/shared/modules/user-data-strore';
import { CalendlyAccesTokenService } from 'src/app/shared/service/calendly-acces-token.service';
import { HttpService } from 'src/app/shared/service/http.service';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import * as XLSX from 'xlsx';  
import * as FileSaver from 'file-saver';
import { month_User } from 'src/app/shared/models/model';
import { countries } from '../../shared/modules/country-data-store';
import { Date_Filters } from '../../shared/models/model';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-new-user-detail',
  templateUrl: './new-user-detail.component.html',
  styleUrls: ['./new-user-detail.component.css']
})
export class NewUserDetailComponent implements OnInit {

  @ViewChild('content') content: ElementRef;  
  @ViewChild( BaseChartDirective ) chart: BaseChartDirective;

  public startDate: Date;
  public endDate: Date;
  public filterStartDate: String;
  public filterEndDate: String;
  monthUser : any = [];
  filterResponse : any = [];
  totalFilteredUser :any = [];
  counts : any = {};
  lowerDates: any = [];
  today = new Date("01 Jan 2014");
  monthNumber: String = "";
  yearNumber: String = ""
  



  dataNewUser: ChartData<'line'> = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May'],
    datasets: [
      { label: 'New User Added',
       data: [1200, 1500, 1020, 1600, 900],
       borderColor: '#ff6d4d',
       backgroundColor: ["#ff6d4d"],
       pointBackgroundColor: ["#ff6d4d"],
        tension: 1
       }
    ],
  };

  chartOptions: ChartOptions = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'New User Details',
      },
    },
  };
  
  currentUser: any = sessionStorage.getItem('currentUser');
  token: any = JSON.parse(this.currentUser).token;
  public months: any = monthNames;
  newUserArray: any = [];

  selectedFilters: any;
  // public months: any = monthNames;
  public daterange: any = {};
  weeks: any;
  newUserList: month_User[] = [];
  newUsers: [];
  response: any;

  constructor(
    private http: HttpService, 
    private router: Router,
    private spinner: NgxSpinnerService,
    private calendlyAccessToken: CalendlyAccesTokenService,
  ) { }

  public searchByFilters(){
    //console.log(this.selectedFilters);
    this.lowerDates = [];
    this.totalFilteredUser = [];
  console.log(this.selectedFilters);
  
  

    if (this.selectedFilters == 'customDate') {
      this.filterStartDate = this.convert(this.startDate);
      this.filterEndDate = this.convert(this.endDate);
 
      let difference =  this.calculateDiff(this.startDate,this.endDate) - 1;
    }
    else if(this.selectedFilters == 'this month') {
      this.filterStartDate = this.yearNumber+'-'+String(Number(this.monthNumber)+1)+'-01';
      this.filterEndDate = this.yearNumber+'-'+String(Number(this.monthNumber)+1)+'-30'; 
    }

      // if(this.selectedFilters == 'this month') 
      // {
      //   this.filterEndDate = this.convert(this.today);
      //   this.filterStartDate = this.EDate(this.today);
      // } 
      else if(this.selectedFilters == 'last month'){
      let monthNumber = (Number(this.monthNumber));

      if(this.monthNumber == '1') {
        this.monthNumber = '12';
        this.yearNumber = String(Number(this.yearNumber) - 1)
        
        this.filterStartDate = this.yearNumber+'-'+this.monthNumber+'01';
        this.filterEndDate = this.yearNumber+'-'+this.monthNumber+'30'; 
      } else {
        

        if(monthNumber > 1 &&  monthNumber < 11) {
          this.monthNumber = String(Number(this.monthNumber) );
          this.monthNumber = ('0'+ this.monthNumber).slice(-2);

          this.filterStartDate = this.yearNumber+'-'+this.monthNumber+'-01';
           this.filterEndDate = this.yearNumber+'-'+this.monthNumber+'-30'; 
        } else {
          this.filterStartDate = this.yearNumber+'-'+this.monthNumber+'-01';
          this.filterEndDate = this.yearNumber+'-'+this.monthNumber+'-30'; 
        }
      }
    }


    

    // if (this.selectedFilters == 'last month') {
    //   this.filterStartDate = this.FirstDayPreviousMonth(this.today);
    //   this.filterEndDate = this.LastDayPreviousMonth(this.today);
    // }

    this.spinner.show();

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.token,
    });
    let options = { headers: headers };
    console.log(this.filterStartDate);
    console.log(this.filterEndDate);
    // console.log(this.monthNumber);
    // console.log(this.yearNumber);
    
    
    
    
    
    this.http
      .get(`admin/getNewUserFilter?fromDate=${this.filterStartDate}&toDate=${this.filterEndDate}`, options)
      .subscribe((res: any) => {

      //  let count = res.length;
      //  this.response = res

      //  for(let i: number = 0;i<count;i++) {
      //   let date = res[i].createdAt.split("T")[0];
      //   this.filterResponse.push(date);
      //  }

      //  this.lowerDates = this.filterResponse.filter(this.onlyUnique);
      

      //   for(let i=0;i<this.lowerDates.length;i++) {
      //     this.totalFilteredUser.push(this.getOccurrence(this.filterResponse, this.lowerDates[i]));
      //   }

      this.totalFilteredUser.length = 0;
      this.lowerDates.length = 0;
      
      for(let i = 0;i<res.length;i++) {
        this.totalFilteredUser.push(res[i].Total);
        this.lowerDates.push(res[i]._id.yearMonthDay);
      }
  
       let monthUserArray = this.totalFilteredUser;

        this.dataNewUser = {
          labels: this.lowerDates,
          datasets: [
            { label: 'New User Added',
             data: monthUserArray,
             borderColor: '#ff6d4d',
             backgroundColor: ["#ff6d4d"],
             pointBackgroundColor: ["#ff6d4d"],
              tension: 1
             }
          ],
        };
        this.chart.ngOnChanges({});
   
        this.spinner.hide();
        
      });    
  }


  ngOnInit(): void {
    this.newUserData()
    this.monthNumber = new Date().getMonth().toString();
    this.yearNumber = new Date().getFullYear().toString();
    

  }

   public newUserData() {

    this.monthUser = [];

    this.spinner.show(); 
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.token,
    });
    let options = { headers: headers };

    this.http
      .get('admin/getNewUserByMonth', options)
      .subscribe((res: any) => {

        
        

        this.newUsers = res;
        

        for (let i = 0;i< res.length;i++) {

          if(this.selectedFilters == 'This Month' || this.selectedFilters == 'Last Month') {
            this.monthUser.push(this.weeks[res[i]._id.month - 1].monthName+'-'+res[i]._id.year);
          }
          else{
          this.newUserArray.push(res[i].Total);
          this.monthUser.push(this.months[res[i]._id.month - 1].monthName+'-'+res[i]._id.year);}
        }

        let monthUserArray = this.newUserArray;

        this.dataNewUser = {
          labels: this.monthUser,
          datasets: [
            { label: 'New User Added',
             data: monthUserArray,
             borderColor: '#ff6d4d',
             backgroundColor: ["#ff6d4d"],
             pointBackgroundColor: ["#ff6d4d"],
              tension: 1
             }
          ],
        };
        this.chart.ngOnChanges({});
       
        this.spinner.hide();

        //console.log(this.newUsers);

        for(let i = 0;i < res.length;i++) {
          this.newUserList.push({sl_No: i+1,month: this.months[res[i]._id.month  - 1].monthName,user: res[i].Total})
        }
        
      });

      


  }
  public back() {
    this.router.navigate(['/dashboard/home']);
    sessionStorage.setItem('path','/dashboard/home');
  }

  public reset(){
    //window.location.reload();
  }

  public search(){
    //console.log(this.lineChartData);
  }

  public openPDF() {
    this.spinner.show(); 
    let DATA: any = document.getElementById('newuser');
    var pdfBtn: any = document.getElementById("pdfbtn");
    pdfBtn.style.display = "none";
    html2canvas(DATA).then((canvas) => {
      let fileWidth = 210;
      let fileHeight = (canvas.height * fileWidth) / canvas.width ;
      const FILEURI = canvas.toDataURL('image/png');
      let PDF = new jsPDF('p', 'mm', 'a4');
      let position = 0;
      PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight);
      this.spinner.hide(); 
      PDF.save('Voece_new_user.pdf');
    });
    pdfBtn.style.display = "block";
  }

    exportExcel() {
    this.spinner.show(); 
    const worksheet = XLSX.utils.json_to_sheet(this.newUserList);
    const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.spinner.hide(); 
    this.saveAsExcelFile(excelBuffer, "Voece");
  }
  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_new_user_number_list' + new Date().getTime() + EXCEL_EXTENSION);
  };

  selectedRegion: any;
  lineChartData : any = [1000, 1200, 1050, 2000, 500];
  public countries: any = countries;

  dateFlters: Date_Filters[] = [
    
    { name: 'This Month', value: 'this month' },
    { name: 'Last Month', value: 'last month' }, 
    { name: 'This Year', value: 'this year' },
    { name: 'Custom Date', value: 'customDate' },
  ];

   convert(str: any) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
   EDate(str:any){
    let date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2)
      // day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, '01'].join("-");
   }

  //   FirstDayPreviousMonth(str:any) {
  //   let date = new Date(str);
  //   let prevMonth =  ("0" + (date.getMonth() )).slice(-2);
  //   let firstDay = '01';
  
  //   return [date.getFullYear(), prevMonth, firstDay].join("-");
  // }
  // LastDayPreviousMonth(str:any){
  //   let date = new Date(str);
  //   let prevMonth =  ("0" + (date.getMonth() )).slice(-2);
  //   let LastDay = '30';
  //   return [date.getFullYear(), prevMonth, LastDay].join("-");
  // }

  
 
  calculateDiff(startDate: Date,endDate: Date){

    let days = Math.floor((endDate.getTime() - startDate.getTime()) / 1000 / 60 / 60 / 24);
    return days;
  }

   onlyUnique(value: any, index : any, self : any) {
    return self.indexOf(value) === index;
  }

   getOccurrence(array: any, value: any) {
    return array.filter((v: any) => (v === value)).length;
}

}
