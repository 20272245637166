<!DOCTYPE html>
<html lang="en" class="h-100">
  <body class="h-100">
    <div class="authincation h-100 login-bg-sec">
      <div class="container h-100">
        <div class="row justify-content-center h-100 align-items-center">
          <div class="col-md-8">
            <div class="authincation-content">
              <div class="row no-gutters row-log">
                <div class="col-xl-12">
                  <div class="auth-form">
                    <div class="text-center mb-3">
                      <a><img
                          src="../../assets/images/logo.png"
                          alt=""
                          class="img-fluid"
                      /></a>
                    </div>
                    <p>
                        For security purposes, we require all new users to change their password upon their first login. 
                        This ensures the safety of your account and personal information.
                    </p>
                    <form
                      (ngSubmit)="onSubmit()"
                      #registerForm="ngForm"
                      novalidate
                    >
                      <div class="container form-group">
                        <label for="password"><b>Password</b></label>
                        <input
                          class="form-control"
                          type="password"
                          placeholder="Password"
                          name="password"
                          id="password"
                          required
                          #password="ngModel"
                          [(ngModel)]="passwordDetails.password"
                          (change) = "removeError()"
                          (blur) = "validate()"
                        />
                        <div
                          *ngIf="
                            (password.dirty || password.touched)
                          "
                          class="alert"
                        >
                          <div *ngIf="password.errors?.['required']">
                            <a style="color: #9e2c28, ">Password is required.</a>
                            
                          </div>
                        </div>

                        <label for="confirm-password"><b>Confirm Password</b></label>
                        <input
                          class="form-control"
                          type="password"
                          placeholder="Confirm Password"
                          name="confirm-password"
                          id="confirm-password"
                          required
                          #cpassword="ngModel"
                          [(ngModel)]="passwordDetails.cpassword"
                          (change) = "removeError()"
                          (blur) = "validate()"
                        />

                        <div *ngIf= "cpassword.invalid && (cpassword.dirty || cpassword.touched)" class="alert">
                          <div *ngIf="cpassword.errors?.['required']" >
                            <a style="color: #9e2c28, ">Confirm Password is required.</a>
                          </div>
                        </div>
                        <div *ngIf = "isPasswordError">
                            <p style="color: #9e2c28" class="text text-center">Password didn't match</p>
                        </div>
                        <!-- <hr /> -->

                        <!-- <label for="code"><b>OAuth Code</b></label>
                        <input
                          class="form-control"
                          type="text"
                          placeholder="Enter Code"
                          #code="ngModel"
                          [(ngModel)]="userDetails.Code"
                        /> -->

                        <!-- <hr /> -->

                        <div style="padding-top: 25px;"></div>
                        <button type="submit"
                          class="btn bg-white text-primary btn-block" > Change password
                          <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                        </button>
                      </div>

                      <div
                        class="form-row d-flex justify-content-between mt-4 mb-2"
                      >
                        <div class="form-group">
                          <div class="custom-control custom-checkbox ml-1">
                            <!-- <input
                              type="checkbox"
                              class="custom-control-input"
                              id="basic_checkbox_1"
                            />
                            <label
                              class="custom-control-label"
                              for="basic_checkbox_1"
                              >Remember</label
                            > -->
                          </div>
                        </div>
                        
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--**********************************
        Scripts
    ***********************************-->
    <!-- Required vendors -->
    <script src="./vendor/global/global.min.js"></script>
    <script src="./js/custom.min.js"></script>
    <script src="./js/deznav-init.js"></script>
  </body>
</html>
