import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ChartData, ChartOptions } from 'chart.js';
import { DateRange } from 'igniteui-angular';
import { BaseChartDirective } from 'ng2-charts';
import { NgxSpinnerService } from 'ngx-spinner';
import { countries } from '../../shared/modules/country-data-store';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import * as XLSX from 'xlsx';  
import * as FileSaver from 'file-saver';
import { monthNames } from 'src/app/shared/modules/user-data-strore';
import { Date_Filters } from '../../shared/models/model';
import { HttpService } from 'src/app/shared/service/http.service';
import { HttpHeaders } from '@angular/common/http';


@Component({
  selector: 'app-show-revenue',
  templateUrl: './show-revenue.component.html',
  styleUrls: ['./show-revenue.component.css']
})
export class ShowRevenueComponent implements OnInit {

  currentUser: any = sessionStorage.getItem('currentUser');
  token: any = JSON.parse(this.currentUser).token;
  public startDate: Date;
  public endDate: Date;
  monthUser : any = [];

  @ViewChild( BaseChartDirective ) chart: BaseChartDirective;
  selectedRegion: any;
  lineChartData : any = [];
  public countries: any = countries;

  legendLable: any = ['Jan', 'Feb', 'Mar', 'Apr', 'May','Jun'];
  lineData: any = [];

  public filterStartDate: String;
  public filterEndDate: String;
  monthNumber: String = "";
  yearNumber: String = ""

  chartOptions: ChartOptions = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'Revenue',
      },
    },
  };

  salesData: ChartData<'line'> = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May'],
    datasets: [
      { label: 'Revenue Generated',
       data: [1000, 4300, 1500, 2000, 5000],
       borderColor: '#FFC527',
       backgroundColor: ["#ffc527"],
       pointBackgroundColor: ["#ffc527"],
       tension: 0.5 }
    ],
  };

  exportList = [
    // { id: 1, Month: 'April', Revenue: '$ 48'},
    // { id: 2, Month: 'May', Revenue: '$ 52' },
    // { id: 3, Month: 'June', Revenue: '$ 34' },
    // { id: 4, Month: 'July', Revenue: '$ 29'},
    // { id: 5, Month: 'August', Revenue: '$ 89'},
    // { id: 6, Month: 'September', Revenue: '$ 72'},
  ]
  newUsers = [];
  weeks: any;
  newUserArray: any = [];
  // dataNewUser: ChartData<'line'> = {
  //   labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May'],
  //   datasets: [
  //     { label: 'New User Added',
  //      data: [1200, 1500, 1020, 1600, 900],
  //      borderColor: '#ff6d4d',
  //      backgroundColor: ["#ff6d4d"],
  //      pointBackgroundColor: ["#ff6d4d"],
  //       tension: 1
  //      }
  //   ],
  // };
  newUserList: any = [];

  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private http: HttpService

  ) { }

  ngOnInit(): void {
    this.monthNumber = new Date().getMonth().toString();
    this.yearNumber = new Date().getFullYear().toString();
    this.newUserData()
    
    // this.getMonthlyData();
  }

  public newUserData() {

    this.monthUser = [];

    this.spinner.show(); 
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.token,
    });
    let options = { headers: headers };

    this.http
      .get('therapist/getTherapistSessionAllRevenueByMonth', options)
      .subscribe((res: any) => {

        
        console.log(res)

        this.newUsers = res;
        

        for (let i = 0;i< res.length;i++) {

          if(this.selectedFilters == 'This Month' || this.selectedFilters == 'Last Month') {
            this.monthUser.push(this.weeks[res[i]._id.month - 1].monthName+'-'+res[i]._id.year);
          }
          else{
          this.newUserArray.push(res[i]._id.price);
          this.monthUser.push(this.months[res[i]._id.month - 1].monthName+'-'+res[i]._id.year);}
        }

        let monthUserArray = this.newUserArray;

        console.log(this.monthUser);
        

        this.salesData = {
          labels: this.monthUser,
          datasets: [
            { 
             label: 'Revenue Generated',
              data: monthUserArray,
              borderColor: '#FFC527',
              backgroundColor: ["#ffc527"],
              pointBackgroundColor: ["#ffc527"],
              tension: 1
             }
          ],
        };
        this.chart.ngOnChanges({});
       
        this.spinner.hide();

        //console.log(this.newUsers);

        for(let i = 0;i < res.length;i++) {
          this.newUserList.push({sl_No: i+1,month: this.months[res[i]._id.month  - 1].monthName, price: res[i]._id.price, user: res[i].Total})
        }
        
      });

      


  }

  calculateDiff(startDate: Date,endDate: Date){

    let days = Math.floor((endDate.getTime() - startDate.getTime()) / 1000 / 60 / 60 / 24);
    return days;
  }

  // getMonthlyData() {
  //   let startDate = this.yearNumber+'-'+String(Number(this.monthNumber)+1)+'-01';
  //   let endDate = this.yearNumber+'-'+String(Number(this.monthNumber)+1)+'-30';
    
  //   this.spinner.show(); 
  //   let headers = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     Authorization: 'Bearer ' + this.token,
  //   });
  //   let options = { headers: headers };

  //   this.http
  //     .get(`therapist/getTherapistSessionAllRevenueByMonth?name=Biswakesan Mohanty&toDate=${startDate}&fromDate=${endDate}`, options)
  //     .subscribe((res: any) => {
  //       console.log(res);
  //       this.legendLable.length = 0;
  //       this.lineChartData.length = 0;

  //       for(let i = 0;i<res.length;i++) {
  //         this.legendLable.push(res[i]._id.);
  //         this.lineChartData.push(res[i].Total);
  //       }

  //       this.lineData = this.legendLable;
  //       this.salesData = {
  //         labels: this.legendLable,
  //         datasets: [
  //           { label: 'Revenue Generated',
  //             data: this.lineData,
  //             borderColor: '#FFC527',
  //             backgroundColor: ["#ffc527"],
  //             pointBackgroundColor: ["#ffc527"],
  //             tension: 0.5 
  //           }
  //     ],
  //   };
  //       this.chart.ngOnChanges({});
        
  //     })
  //   this.spinner.hide();

  // }


  public back() {
    this.router.navigate(['/dashboard/home']);
    sessionStorage.setItem('path','/dashboard/home');
  }

  public reset(){
    //window.location.reload();
  }

  public openPDF() {
    this.spinner.show(); 
    let DATA: any = document.getElementById('revenue');
    html2canvas(DATA).then((canvas) => {
      let fileWidth = 210;
      let fileHeight = (canvas.height * fileWidth) / canvas.width ;
      const FILEURI = canvas.toDataURL('image/png');
      let PDF = new jsPDF('p', 'mm', 'a4');
      let position = 0;
      PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight);
      this.spinner.hide(); 
      PDF.save('Voece_revenue.pdf');
    });
  }

    exportExcel() {
    this.spinner.show(); 
    const worksheet = XLSX.utils.json_to_sheet(this.newUserList);
    const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.spinner.hide(); 
    this.saveAsExcelFile(excelBuffer, "Voece");
  }
  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_revenue_' + new Date().getTime() + EXCEL_EXTENSION);
  }
  
  selectedFilters: any;
  public months: any = monthNames;
  public daterange: any = {};

  dateFlters: Date_Filters[] = [
    
    { name: 'This Month', value: 'this month' },
    { name: 'Last Month', value: 'last month' },
    { name: 'This Year', value: 'this year' },
    { name: 'Custom Date', value: 'customDate' },
  ];

  public searchByFilters(){
   
    if (this.selectedFilters == 'customDate') {
      this.filterStartDate = this.convert(this.startDate);
      this.filterEndDate = this.convert(this.endDate);
 
      let difference =  this.calculateDiff(this.startDate,this.endDate) - 1;
    }
    else if(this.selectedFilters == 'this month') {
      let monthNumber = (Number(this.monthNumber));

      if(this.monthNumber == '1') {
        this.monthNumber = '12';
        this.yearNumber = String(Number(this.yearNumber) - 1)
        
        this.filterStartDate = this.yearNumber+'-'+this.monthNumber+'01';
        this.filterEndDate = this.yearNumber+'-'+this.monthNumber+'30'; 
      } else {
        

        if(monthNumber > 1 &&  monthNumber < 11) {
          this.monthNumber = String(Number(this.monthNumber) + 1 );
          this.monthNumber = ('0'+ this.monthNumber).slice(-2);

          this.filterStartDate = this.yearNumber+'-'+this.monthNumber+'-01';
           this.filterEndDate = this.yearNumber+'-'+this.monthNumber+'-30'; 
        } else {
          this.filterStartDate = this.yearNumber+'-'+this.monthNumber+'-01';
          this.filterEndDate = this.yearNumber+'-'+this.monthNumber+'-30'; 
        }
      }
      // this.filterStartDate = this.yearNumber+'-'+String(Number(this.monthNumber)+1)+'-01';
      // this.filterEndDate = this.yearNumber+'-'+String(Number(this.monthNumber)+1)+'-30'; 
    }

      // if(this.selectedFilters == 'this month') 
      // {
      //   this.filterEndDate = this.convert(this.today);
      //   this.filterStartDate = this.EDate(this.today);
      // } 
      else if(this.selectedFilters == 'last month'){
      let monthNumber = (Number(this.monthNumber));

      if(this.monthNumber == '1') {
        this.monthNumber = '12';
        this.yearNumber = String(Number(this.yearNumber) - 1)
        
        this.filterStartDate = this.yearNumber+'-'+this.monthNumber+'01';
        this.filterEndDate = this.yearNumber+'-'+this.monthNumber+'30'; 
      } else {
        

        if(monthNumber > 1 &&  monthNumber < 11) {
          this.monthNumber = String(Number(this.monthNumber) );
          this.monthNumber = ('0'+ this.monthNumber).slice(-2);

          this.filterStartDate = this.yearNumber+'-'+this.monthNumber+'-01';
           this.filterEndDate = this.yearNumber+'-'+this.monthNumber+'-30'; 
        } else {
          this.filterStartDate = this.yearNumber+'-'+this.monthNumber+'-01';
          this.filterEndDate = this.yearNumber+'-'+this.monthNumber+'-30'; 
        }
      }
    } else if (this.selectedFilters == 'this year') {
      this.filterStartDate = this.yearNumber+'-0'+String(Number(0)+1)+'-01';
      this.filterEndDate = this.yearNumber+'-'+String(Number(11)+1)+'-31'; 
    }

    console.log(this.filterEndDate);
    console.log(this.filterStartDate);
    

    this.spinner.show(); 
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.token,
    });
    let options = { headers: headers };

    if(this.selectedFilters == 'customDate' || this.selectedFilters == 'this month' || this.selectedFilters == 'last month' || this.selectedFilters == 'this year') {

    this.http
      .get(`therapist/getTherapistSessionAllRevenue?name=Biswakesan Mohanty&toDate=${this.filterEndDate}&fromDate=${this.filterStartDate}`, options)
      .subscribe((res: any) => {
       
        this.legendLable.length = 0;
        this.lineChartData.length = 0;

        for(let i = 0;i<res.length;i++) {
          this.legendLable.push(res[i]._id.yearMonthDay);
          this.lineChartData.push(res[i]._id.price);
        }

        this.lineData = this.lineChartData;
        this.salesData = {
          labels: this.legendLable,
          datasets: [
            { label: 'Revenue Generated',
              data: this.lineChartData,
              borderColor: '#FFC527',
              backgroundColor: ["#ffc527"],
              pointBackgroundColor: ["#ffc527"],
              tension: 0.5 
            }
      ],
    };
        this.chart.ngOnChanges({});
        
      })
    this.spinner.hide();
  }
}
  convert(str: any) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

 

  
   
}

