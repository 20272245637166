<div>
    <!-- <div class="nav-header">
      <a routerLink="/dashboard/home" class="brand-logo">
        <img class="logo-abbr" src="../../assets/images/logo.png" alt="" />
      </a>
  
      <div class="nav-control">
        <div class="hamburger">
          <span class="line"></span><span class="line"></span
          ><span class="line"></span>
        </div>
      </div>
    </div> -->
  
    <div class="content-body">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="d-flex justify-content-center align-items-center" style="margin-bottom: 4%;">
                  
                    <button
                    style="position: absolute; top:30px; left: 30px;"
                      type="button"
                      (click)="back()"
                      class="btn btn-primary btn-sm mr-1"
                      >
                      Back
                    </button>
                    <h5>Update Corporates</h5>
                    
                </div>
                <form
                  (ngSubmit)="onSubmit()"
                  #addCorporateForm="ngForm"
                  novalidate
                >
                <div class="row">
                    <div class="col">
                      <div class="form-row">
                        <!-- <div class="form-group col-md-4">
                          <label>User Name</label>
                          <input
                            class="form-control"
                            type="text"
                            placeholder="Enter User Name"
                            name="username"
                            id="username"
                            required
                            #username="ngModel"
                            [(ngModel)]="therapistDetails.UserName"
                          />
                          <div
                            *ngIf="
                              username.invalid &&
                              (username.dirty || username.touched)
                            "
                            class="alert"
                          >
                            <div *ngIf="username.errors?.['required']">
                              Username is required.
                            </div>
                          </div>
                        </div> -->
                        
                        <div class="form-group col-md-4">
                          <label>Company Name</label>
                          <input
                            class="form-control"
                            type="text"
                            placeholder="Enter Company Name"
                            name="companyname"
                            id="companyname"
                            required
                            #companyname="ngModel"
                            [(ngModel)]="corporateDetails.companyName"
                            
                          />
                          <div
                          *ngIf="
                          companyname.invalid &&
                          (companyname.dirty || companyname.touched)
                        "
                            class="alert"
                          >
                            <div *ngIf="companyname.errors?.['required']">
                              Company name is required.
                            </div>
                          </div>
                        </div>
    
                        <div class="form-group col-md-4">
                          <label>Point of Contact</label>
                          <input
                            class="form-control"
                            type="text"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            placeholder="Enter point of contact"
                            name="pointofnumber"
                            id="pointofnumber"
                            required
                            #pointofnumber="ngModel"
                            [(ngModel)]="corporateDetails.pointOfContact"
  
                          />
                          <div
                          *ngIf="pointofnumber.invalid && (pointofnumber.dirty || pointofnumber.touched)"
                            class="alert"
                          >
                            <div *ngIf="pointofnumber.errors?.['required']">
                              Point of contact is required.
                            </div>
                            <div *ngIf="phone.errors?.['pattern']">
                              Enter Valid point of contact.
                            </div>
                          </div>
                        </div>
    
                        <div class="form-group col-md-4">
                          <label>Email</label>
                          <input
                            class="form-control"
                            type="email"
                            placeholder="Enter Email"
                            name="email"
                            id="email"
                            required
                        [pattern]="emailPattern"
                            #email="ngModel"
                            [(ngModel)]="corporateDetails.email"
                            
                          />
                          <div *ngIf="
                          email.invalid &&
                          (email.dirty || email.touched)
                        "
                            class="alert"
                          >
                            <div *ngIf="email.errors?.['required']">
                              Email is required.
                            </div>
                            <div *ngIf="email.errors?.['pattern']">
                              Invalid Pattern.
                            </div>
                          </div>
                        </div>
                        <div class="form-group col-md-4">
                          <label>Phone</label>
                          <input
                            class="form-control"
                            type="phone"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            placeholder="Enter phone"
                            name="phone"
                            id="phone"
                            required
                            [pattern]="phonePattern"
                            #phone="ngModel"
                            [(ngModel)]="corporateDetails.phone"
  
                          />
                          <div
                          *ngIf="phone.invalid && (phone.dirty || phone.touched)"
                          class="alert"
                          >
                          <div *ngIf="phone.errors?.['required']">
                            Phone Number is required.
                          </div>
  
                          <div *ngIf="phone.errors?.['pattern']">
                            Enter Valid Phone Number.
                          </div>
                          </div>
                        </div>
    

                        <div class="form-group col-md-4">
                          <label>Website</label>
                          <input
                            class="form-control"
                            type="text"
                            placeholder="Enter website Number"
                            name="website"
                            id="website"
                            required
                            #website="ngModel"
                            [(ngModel)]="corporateDetails.website"
                            
                          />
                          <div *ngIf="
                          website.invalid &&
                          (website.dirty || website.touched)
                        "
                            class="alert"
                          >
                            <div *ngIf="website.errors?.['required']">
                              website is required.
                            </div>
    
                            <!-- <div *ngIf="website.errors?.['pattern']">
                              Enter Valid website Number.
                            </div> -->
                          </div>
                        </div>

                        <div class="form-group col-md-4">
                          <label>No. of Licence</label>
                          <input
                            class="form-control"
                            type="text"
                            placeholder="Enter no of licence Number"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            name="nooflicence"
                            id="nooflicence"
                            required
                            #nooflicence="ngModel"
                            [(ngModel)]="corporateDetails.noOfLicense"
                            
                          />
                          <div *ngIf="
                          nooflicence.invalid &&
                          (nooflicence.dirty || nooflicence.touched)
                        "
                            class="alert"
                          >
                            <div *ngIf="nooflicence.errors?.['required']">
                              nooflicence Number is required.
                            </div>
    
                            <div *ngIf="phone.errors?.['pattern']">
                              Enter Valid nooflicence Number.
                            </div>
                          </div>
                        </div>


                        <div class="form-group col-md-4">
                          <label>Address</label>
                          <input
                            class="form-control"
                            type="text"
                            placeholder="Enter Address Number"
                            name="address"
                            id="address"
                            required
                            #address="ngModel"
                            [(ngModel)]="corporateDetails.address"
                            
                          />
                          <div *ngIf="
                          address.invalid &&
                          (address.dirty || address.touched)
                        "
                            class="alert"
                          >
                            <div *ngIf="address.errors?.['required']">
                              address is required.
                            </div>
    
                            <!-- <div >
                              Enter Valid address Number.
                            </div> -->
                          </div>
                        </div>



                        <div class="form-group col-md-4">
                          <label>Country</label>
                          <input
                            class="form-control"
                            type="text"
                            placeholder="Enter country Number"
                            name="country"
                            id="country"
                            required
                            #country="ngModel"
                            [(ngModel)]="corporateDetails.country"
                            
                          />
                          <div *ngIf="
                          country.invalid &&
                          (country.dirty || country.touched)
                        "
                            class="alert"
                          >
                            <div *ngIf="nooflicence.errors?.['required']">
                              country is required.
                            </div>
    
                            <!-- <div >
                              Enter Valid country Number.
                            </div> -->
                          </div>
                        </div>



                        <div class="form-group col-md-4">
                          <label>State</label>
                          <input
                            class="form-control"
                            type="text"
                            placeholder="Enter state Number"
                            name="state"
                            id="state"
                            required
                            #state="ngModel"
                            [(ngModel)]="corporateDetails.state"
                            
                          />
                          <div *ngIf="
                          state.invalid &&
                          (state.dirty || state.touched)
                        "
                            class="alert"
                          >
                            <div *ngIf="state.errors?.['required']">
                              state is required.
                            </div>
    
                            <!-- <div >
                              Enter Valid state Number.
                            </div> -->
                          </div>
                        </div>




                        <div class="form-group col-md-4">
                          <label>Zip code</label>
                          <input
                            class="form-control"
                            type="text"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            placeholder="Enter zip code Number"
                            name="zipcode"
                            id="zipcode"
                            required
                            #zipcode="ngModel"
                            [(ngModel)]="corporateDetails.zipcode"

                            
                          />
                          <div *ngIf="
                          zipcode.invalid &&
                          (zipcode.dirty || zipcode.touched)
                        "
                            class="alert"
                          >
                            <div *ngIf="zipcode.errors?.['required']">
                              zip code is required.
                            </div>
    
                            <div *ngIf="phone.errors?.['pattern']">
                              Enter Valid zip code Number.
                            </div>
                          </div>
                        </div>



                        <!-- <div class="form-group col-md-4">
                          <label>Email Domain</label>
                          


                          <div class="input-container">
                            <input class="form-control" [(ngModel)]="userInput" placeholder="Domain" />
                            <a style="margin-left: 10px;"
                              type="button"
                              (click)="addTextToList()"
                              class="btn btn-primary shadow btn-xs sharp mr-1"
                              ><i class="fa fa-plus"></i
                            ></a>
                            
                          </div>
                          
                          <div *ngFor="let text of textList; let i = index" class="text-entry">
                            <div>{{ text }} <a style="margin-left: 10px;"
                              type="button"
                              (click)="removeText(i)"
                              class="btn btn-primary shadow btn-xs sharp mr-1"
                              ><i class="fa fa-trash"></i
                            ></a></div>
                          </div>
                          
                          
                          
    
                            <div *ngIf="phone.errors?.['pattern']">
                              Enter Valid zip code Number.
                            </div>
                          
                        </div> -->



                        <div class="form-group col-md-4">
                          <label>Email Domain</label>
                          <input
                            class="form-control"
                            type="emailDomain"
                            placeholder="Enter Email Domain"
                            name="emailDomain"
                            id="emailDomain"
                            required
                        [pattern]="emailDomPattern"
                            #emailDomain="ngModel"
                            [(ngModel)]="corporateDetails.emailDomain"
                            
                          />
                          <div *ngIf="
                          emailDomain.invalid &&
                          (emailDomain.dirty || emailDomain.touched)
                        "
                            class="alert"
                          >
                            <div *ngIf="emailDomain.errors?.['required']">
                              Email Domain is required.
                            </div>
                            <div *ngIf="emailDomain.errors?.['pattern']">
                              Invalid Pattern.
                            </div>
                          </div>
                        </div>
    

                        
    
                      </div>
                    </div>
                </div>


                <div style="text-align: center;padding-top: 5%;">
                  <button type="submit" class="btn btn-primary">
                    Update Corporate
                  </button>
                </div>
              </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ngx-spinner color="#fff" type="ball-scale-multiple"></ngx-spinner>
  </div>