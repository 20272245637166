import { HttpService } from './../shared/service/http.service';
import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import axios from 'axios';

import { AuthenticationService } from '../shared/service/authentication.service';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  userDetails: any = {};
  public loading: boolean = false;
  emailPattern: any = '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$';
  calendlyCode: any;
  role = sessionStorage.getItem('role');
  client_id: any = 'p8yQgis3aF8Z_M3zvDwRdTim8Ad3ms3sfFJ0T4XLncY&client_secret=xl5doUfUAbvlkZDA7a1cgH4IniR8YRiurenlo0SzT94';
  redirect_uri: any = 'https://dashboard.voece.com/';
  constructor(
    private http: HttpService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private auth: AuthenticationService
  ) {
    if (this.auth.loggedIn) {
      if (sessionStorage.getItem('currentUser') == null) {
        this.router.navigate(['']);
      }
      else if (this.role == 'Admin') {
        this.router.navigate(['/dashboard/home']);
      } else if (this.role == 'Therapist') {
        this.router.navigate(['/dashboard/therapist-session-view']);
      }
    } else {
      
    }
  }

  ngOnInit(): void {
    if(sessionStorage.getItem('isLoggedIn') == "true" && sessionStorage.getItem('role') == "Admin") {
      this.router.navigate(['/dashboard/home']);
    } else if (sessionStorage.getItem('isLoggedIn') == "true" && sessionStorage.getItem('role') == "Therapist") {
      this.router.navigate(['dashboard/therapist-session-view']);
    } else if(sessionStorage.getItem('isLoggedIn') == "true" && sessionStorage.getItem('role') == "CorporateAdmin"){
      this.router.navigate(['/dashboard/corporate-dashboard']);
    }
  }

  onSubmit(): void {
   
    if ((this.userDetails.Email, this.userDetails.Password)) {
      this.loading = true;
      this.http.post('user/dashboard-login', this.userDetails).subscribe(
        (res: any) => {
          
          //console.log(this.userDetails.Code);
         // console.log(res);
          sessionStorage.setItem('role',res.role);
          sessionStorage.setItem('currentUser', JSON.stringify(res));          
          //sessionStorage.setItem('profile_image','https://api.voece.com/image/'+res.profileImageUrl);
         
          if(res.profileImageUrl == null || res.profileImageUrl == undefined || res.profileImageUrl == '') {
            sessionStorage.setItem('profile_image','https://cdn-icons-png.flaticon.com/128/5541/5541165.png');
          } else {
            sessionStorage.setItem('profile_image',environment.imageUrl+res.profileImageUrl);
          }
          sessionStorage.setItem('accessToken', res.calendlyAccessToken);
          sessionStorage.setItem('orgId', res.organizationId);
          sessionStorage.setItem('orgUrl', res.organizationUrl);
          sessionStorage.setItem('ownerUrl',res.ownerUrl);
          sessionStorage.setItem('currentUser',JSON.stringify(res));
          sessionStorage.setItem('isLoggedIn','true');
          sessionStorage.setItem('fullName',res.fullName)
          
          if(res.role == 'Admin') {
            this.router.navigate(['/dashboard/home']);
            sessionStorage.setItem('path','/dashboard/home');
            sessionStorage.setItem('dashboardName','Dashboard');
          } else if (res.role == 'Therapist') {
            //console.log('enter therapist');
           // this.router.navigate(['dashboard/therapist-dashboard']);
            this.router.navigate(['dashboard/therapist-session-view']);
            sessionStorage.setItem('path','dashboard/therapist-session-view');
            sessionStorage.setItem('dashboardName','Dashboard');
          } else if (res.role == 'CorporateAdmin'){
            this.loading = false
            console.log("resources",res);
            let decodeObj = JSON.parse(atob(res.token.split('.')[1]))
            console.log(decodeObj);
            
            if(res.userData.isFirstTime == "true"){
              console.log("this");
              
              //navigate to change password
              this.router.navigate(["/change-password-request"],{queryParams:{token: res.token}})
            }else{
              console.log("that");
              
              //navigate to dashboard
              sessionStorage.setItem("role","CorporateAdmin")
              sessionStorage.setItem("token",res.token)
              sessionStorage.setItem("userId",decodeObj.id)
              sessionStorage.setItem("_id",res.userData._id)
              sessionStorage.setItem("companyName",res.userData.companyName)
              sessionStorage.setItem("email",res.userData.email)
              sessionStorage.setItem("isFirstTime",res.userData.isFirstTime)
              sessionStorage.setItem("phone",res.userData.phone)
              sessionStorage.setItem("website",res.userData.website)
              sessionStorage.setItem("CorporateCode", res.userData.CorporateCode)
              sessionStorage.setItem('isLoggedIn','true');
              sessionStorage.setItem("currentUser",JSON.stringify(res.userData))
              sessionStorage.setItem('profile_image','https://cdn-icons-png.flaticon.com/128/5541/5541165.png');
              this.router.navigate(["/dashboard/corporate-dashboard"]);

            }
          }
          
          this.loading = false;
    
        },
        (error: any) => {
          this.loading = false;
          console.log(error.error?.message);
          Swal.fire('Error!', error.error?.message, 'error');
        }
      );
    } else {
      Swal.fire('Error', 'Enter Email and Password', 'error');
    }
  }

  calendlyDataGet(){

  }

  setCalendlyAccessToken(): void {}
}
