<div>
    <div class="nav-header">
      <a routerLink="/dashboard/therapist-session-view" class="brand-logo">
        <img class="logo-abbr" src="../../assets/images/logo.png" alt="" />
      </a>
  
      <div class="nav-control">
        <div class="hamburger">
          <span class="line"></span><span class="line"></span
          ><span class="line"></span>
        </div>
      </div>
    </div>
  
  
    <div class="content-body">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <div class="card">

                <div class="card-body">
                    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border dataTable hover" style="width: 100%;">
                      <thead style="text-align: center;">
                        <tr>
                          <th style="color: black">Sl No</th>
                          <th style="color: black">Name</th>
                          <th style="color: black">Email</th>
                          <th style="color: black">Total Session</th>
                          <!-- <th style="color: black">Upcoming Session</th> -->
                          <th style="color: black">Details</th>
                      </tr>
                      </thead>
                      <tbody style="text-align: center;">
                        <tr *ngFor="let item of listOfUser; let i = index">
                          <td>{{ i + 1 }}</td>
                          <td>{{ item.InviteeName}}</td>
                          <td>{{ item._id }}</td>
                          <td>{{ item.count }}</td>
                          <td type="button" style="color: #F4614D;" (click)="showSessions(item._id)"><i class="fa fa-eye" aria-hidden="true"></i></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
              </div>
            </div>
          </div>
    
          <!-- <div id="calendar" class="app-fullcalendar"></div> -->
        </div>
      </div>
    
      <ngx-spinner color="#fff" type="ball-scale-multiple"></ngx-spinner>  
    
    </div>