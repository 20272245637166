<div>
    <div class="content-body" >
        <div class="row">
          <div class="col-lg-5" style="padding-top: 2%;padding-left: 5%">
            <button  class="btn btn-primary" (click)="back()">
              Back
            </button>
          </div>
          <div class="col-lg-7" style="text-align: left;padding-top: 2%;">
            <div class="card-title ">
              User Decibles
            </div>
          </div>
        </div>
        <div class="container-fluid">

          <div class="row">
            <div class="form-group col-md-12">
              <div class="row">
                <div class="form-group col-md-3">
                  <label>Select User</label>
                  <select
                    [(ngModel)]="selectedUserName" name="dropdownOption" id="dropdownOption" class="form-control" >
                    <option *ngFor="let user of userNames" [value]="user.value"  >
                      {{ user.name }}
                    </option>
                  </select>
                </div>
                <br />
                <div class="form-group col-md-4" style="margin-top: 4%">
                  <button type="button"  class="btn btn-primary btn-sm mr-1" >
                    Search
                  </button>

                  <button
                    type="button"
                    class="btn btn-primary btn-sm mr-1"
                  >
                    reset
                  </button>
                </div>
              </div>
            </div>
            <br />
          </div>

          <!-- Add Order -->
          <!-- row -->
          <div class="row">
            <div class="col-xl-12 col-lg-12">
              <div class="card">
                <div class="card-body" style="padding-top: 10px;">
                    
                    <google-chart [data]="pieChart"> </google-chart>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    
      <ngx-spinner color="#fff" type="ball-scale-multiple"></ngx-spinner>
    </div>

