import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-master',
  templateUrl: './master.component.html',
  styleUrls: ['./master.componnt.css'],
})
export class MasterComponent implements OnInit {

  title = 'VOECE';
  titleName: any = sessionStorage.getItem('dashboardName');
  pathName: any = '';
  userActivity: any;
  userInactive: Subject<any> = new Subject();
  corporateCode: any = ''
  
  image: any;
  private activatedRoute: ActivatedRoute;
  calendlyCode: any;
  userRole: any;

  constructor(
    private router: Router,
    
    ) {}

  ngOnInit(): void {

    this.userRole = sessionStorage.getItem('role');
    this.corporateCode = sessionStorage.getItem("CorporateCode")
    this.image = sessionStorage.getItem('profile_image');

    // if(this.userRole == "CorporateAdmin"){
    //   this.changeDashboardTitleName("Dashboard","dashboard/corporate-dashboard");
    // }

    //to check user inactivity and logout
    this.setTimeout();
        this.userInactive.subscribe(() => {
        console.log('logout');
        window.sessionStorage.clear();
        sessionStorage.clear();
        this.router.navigate(['']);
        });
  }


  //for routing
  public changeDashboardTitleName(dashboardName: any,path: any) {
    this.titleName = dashboardName;
    this.pathName = path;

    sessionStorage.setItem('dashboardName',this.titleName)
    sessionStorage.setItem('path',this.pathName)
    this.router.navigate([this.pathName]);

  }

  copyMessage(val: string){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    alert("Corporate code copied")
  }

  //for logout
  logout(): void {
    Swal.fire({
      heightAuto: false,
      title: 'Are you sure?',
      //icon: 'warning',
      width: '60vh',
      // showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Yes',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        //window.location.reload();
        //console.log('logout');
        // window.localStorage.clear();
        // if(sessionStorage.getItem("role")=="CorporateAdmin"){
        //   sessionStorage.clear();
        //   this.router.navigate(['/corporate-login']);
        // }else{
          sessionStorage.clear();
          this.router.navigate(['']);
        // }
      }
    });
  }


  //for inactive user logout after 10 mins
  keyPress(event: KeyboardEvent): void {
      clearTimeout(this.userActivity);
      this.setTimeout();
  }
  setTimeout(): void {
      this.userActivity = setTimeout(() => this.userInactive.next(undefined), 600000);
  }

  @HostListener('window:mousemove') refreshUserState() {
      clearTimeout(this.userActivity);
      this.setTimeout();
  }

  ngOnDestroy() { 
    sessionStorage.clear();
}
}
