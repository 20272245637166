import { AuthenticationService } from './authentication.service';
import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private _router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (sessionStorage.getItem('currentUser')) {
      return true;
    }
   // this._router.navigate(['']);
   this._router.navigate(['/'], { queryParams: { returnUrl: state.url } });
    return false;
  }

  checkUserLogin(route: ActivatedRouteSnapshot, url: any): boolean {
    if (sessionStorage.getItem('currentUser')) {
      let currentUser: any = sessionStorage.getItem('currentUser');
      let role: any = JSON.parse(currentUser).role;

      if (role === 'Admin') {
        this._router.navigate(['/dashboard/home']);
        return false;
      } else if( role == 'Therapist') {
        this._router.navigate(['/dashboard/therapist-session-view']);
        return false;
      } else if(role == "CorporateAdmin"){
        //navigate to corporate dashboard
        this._router.navigate(["/dashboard/corporate-dashboard"])
        return false
      }
      return true;
    }

   // this._router.navigate(['/']);
    return false;
  }

  isAdmin(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (sessionStorage.getItem('currentUser')) {
      let user: any = sessionStorage.getItem('currentUser');
      return JSON.parse(user).isAdmin;
    }
    this._router.navigate(['']);
    return false;
  }
}
